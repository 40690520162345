import { SETTINGS_HEADER_TABLE } from '@/constants/headersTable/settingsHeaderTable'

export const LOCAL_FILTER_SHORT_NAME = {
    [SETTINGS_HEADER_TABLE.trafficSourceParameters]: 't_s_p',
    [SETTINGS_HEADER_TABLE.vendorInvitations]: 'v_i',
    [SETTINGS_HEADER_TABLE.buyerInvitations]: 'b_i',
    [SETTINGS_HEADER_TABLE.destinationGroupParameters]: 'd_g_p',
    [SETTINGS_HEADER_TABLE.availableDestinationGroup]: 'a_d_g',
    [SETTINGS_HEADER_TABLE.campaignAvailableDestination]: 'c_a_d',
    [SETTINGS_HEADER_TABLE.campaignAvailableGroupDestination]: 'c_a_g_d',
    [SETTINGS_HEADER_TABLE.campaignAvailableTrackingNumber]: 'c_a_t_n',
    [SETTINGS_HEADER_TABLE.campaignTrackingNumber]: 'c_t_n',
    [SETTINGS_HEADER_TABLE.forwardCallsTo]: 'f_c_t',
    [SETTINGS_HEADER_TABLE.callFlowAvailableDestinations]: 'c_f_a_d',
    [SETTINGS_HEADER_TABLE.callFlowAvailableGroups]: 'c_f_a-g',
    [SETTINGS_HEADER_TABLE.campaignAccess]: 'c_a_u',
    [SETTINGS_HEADER_TABLE.campaignAddUsers]: 'c_a_n_u',
    [SETTINGS_HEADER_TABLE.detailNumberPoolTrafficSources]: 'd_n_p_t_s',
    [SETTINGS_HEADER_TABLE.campaignAvailableNumberPools]: 'c_a_n_p',
    [SETTINGS_HEADER_TABLE.campaignEnrichmentUrls]: 'c_f_e_r',
}
