<template>
    <div
        class="py-1.5 pr-5 min-mb:pr-18 max-mob:pr-0 gap-x-4 w-full flex items-center justify-end max-mob:justify-center absolute right-0 bg-white-300 dark:bg-black-370 transition-all duration-500"
        :class="showMobileBC ? 'top-app-bar-with-bc' : 'mt-0.5 max-mob:mt-0 max-mob:top-app-bar'"
    >
        <div class="text-xs text-gray-370 dark:text-white">
            Trial version is available for {{ trialEndDate }}
        </div>

        <PrimaryBtn
            class="w-21.25"
            height="h-7"
            borderOutlinedStyle="border-purple"
            textOutlinedStyle="text-purple"
            outlined
            @click="goToVerification"
        >
            Full Access
        </PrimaryBtn>
    </div>
</template>

<script setup>
import {
    computed, onMounted, onUnmounted, ref, watch,
} from 'vue'
import { useRouter } from 'vue-router'

import PrimaryBtn from '@/components/buttons/PrimaryBtn.vue'

import { getDateDistanceToNow } from '@/helpers/date-helper'

import { useAuthorizationStore } from '@/store/authorizationStore'

import { ROUTES } from '@/constants/routes'

defineProps({
    showMobileBC: {
        type: Boolean,
        default: false,
    },
})

const authorizationStore = useAuthorizationStore()

const router = useRouter()

let timeInterval = null

const trialEndDate = ref(null)

const isFirstLoad = ref(true)

const durationTimeout = computed(() => (isFirstLoad.value ? (60 - new Date().getSeconds()) * 1000 : 60000))

const account = computed(() => authorizationStore.account || {})

onMounted(() => {
    setTimeInterval()
})

function getDate() {
    const { trial_days } = account.value
    const dateFormat = new Date(trial_days)

    const date = getDateDistanceToNow(dateFormat)
    const { days, hours, minutes } = date

    if (days >= 1) {
        const newDays = days + 1
        trialEndDate.value = `${newDays} days`
        return
    }

    if (!hours && !minutes) {
        clearInterval(timeInterval)
        authorizationStore.updateAccount({ trial_ended: true })
        goToVerification()
        trialEndDate.value = '0 hours 0 minutes'
        return
    }

    const hoursPayload = `${hours < 10 ? `0${hours}` : hours}`
    const minutesPayload = `${minutes < 10 ? `0${minutes}` : minutes}`

    trialEndDate.value = `${hoursPayload}:${minutesPayload}`
}

function setTimeInterval() {
    clearInterval(timeInterval)

    getDate()

    timeInterval = setInterval(() => {
        getDate()

        if (isFirstLoad.value) {
            isFirstLoad.value = false
        }
    }, durationTimeout.value)
}

function goToVerification() {
    router.push({ name: ROUTES.verification, params: { workspace: authorizationStore.getWorkspaceSlug } })
}

watch(isFirstLoad, setTimeInterval)

onUnmounted(() => {
    clearInterval(timeInterval)
})
</script>
