import { useDetailCallFlowsStore } from '@/store/call-tracking/callFlow/detailCallFlowsStore'

import { getRandomUUID } from '@/helpers/app-helper'

import { PARAMETERS_TYPES } from '@/constants/parametersTypes'
import { CALL_FILTERS_PARAMETERS } from '@/constants/callFilters'

function parseCallFilter({ groups = [], allFilters = [] }, isCampaign = false) {
    if (!groups.length) {
        return [defaultGroup()]
    }

    return groups.map((group) => ({
        id: group.id,
        conditions: group.conditions.map((condition) => parseCondition(condition, allFilters, isCampaign)),
    }))
}

function defaultGroup() {
    return {
        id: getRandomUUID(),
        conditions: [
            defaultCondition(),
        ],
    }
}

function defaultCondition() {
    return {
        id: getRandomUUID(),
        formData: {},
    }
}

function getOperators(parameterId, foundParameter, condition) {
    switch (parameterId) {
    case CALL_FILTERS_PARAMETERS.TRAFFIC_SOURCE:
        return getTSOperators(foundParameter, condition.parameter)

    case CALL_FILTERS_PARAMETERS.CALL_FLOW_PARAMETERS:
        return getCallFlowParameters(foundParameter, condition.parameter)

    case CALL_FILTERS_PARAMETERS.CUSTOM_PARAMETERS:
        return getCustomParameterOperators(foundParameter, condition.pretty_parameter)

    default:
        return getParameterOperators(foundParameter, condition.parameter)
    }
}

function getOperatorsAndValues(foundParameter, condition) {
    switch (foundParameter.label) {
    case PARAMETERS_TYPES.trafficSourceParameters:
        return getTSOperators(foundParameter, condition.parameter)

    case PARAMETERS_TYPES.customParameters:
        return getParameterOperators(foundParameter, condition.custom_parameter_id)

    default:
        return getParameterOperators(foundParameter, condition.parameter)
    }
}

function getCallsFiltersParametersId(condition) {
    if (
        (condition.condition_type === PARAMETERS_TYPES.trafficSourceParameters)
        || (condition.type && condition.type.display_name === PARAMETERS_TYPES.trafficSourceParameters)
        || condition.traffic_source_param_id
    ) {
        return CALL_FILTERS_PARAMETERS.TRAFFIC_SOURCE
    }

    if (
        condition.condition_type === PARAMETERS_TYPES.callerInputParameters
        || (condition.type && condition.type.display_name === PARAMETERS_TYPES.callerInputParameters)
    ) {
        return CALL_FILTERS_PARAMETERS.CALL_FLOW_PARAMETERS
    }

    if (
        condition.condition_type === PARAMETERS_TYPES.customParameters
        || (condition.type && condition.type.display_name === PARAMETERS_TYPES.customParameters)
    ) {
        return CALL_FILTERS_PARAMETERS.CUSTOM_PARAMETERS
    }

    return CALL_FILTERS_PARAMETERS.DEFAULT
}

function getCurrentOperator(operators, name) {
    return operators.find((operator) => operator.name === name)
}

function checkIsValueRequired(currentOperator) {
    return currentOperator && currentOperator.hasOwnProperty('required_value') ? currentOperator.required_value : true
}

function getTSOperators(foundTS, paramId) {
    let parameter = null

    foundTS.items.forEach((ts) => {
        const foundParam = ts.items.find((param) => param.url_param === paramId || param.name === paramId)

        if (foundParam) {
            parameter = foundParam
        }
    })

    return {
        operators: parameter ? parameter.operators : [],
        allowedValues: parameter && parameter.hasOwnProperty('allowedValues') ? parameter.allowedValues : null,
    }
}

function getCallFlowParameters(foundCallFlowParameter, paramId) {
    let parameter = null

    foundCallFlowParameter.items.forEach((callFlowParameter) => {
        const foundCallFlowParameter = callFlowParameter.items.find((parameter) => parameter.id === paramId || parameter.name === paramId)

        if (foundCallFlowParameter) {
            parameter = foundCallFlowParameter
        }
    })

    return {
        operators: parameter ? parameter.operators : [],
        allowedValues: parameter && parameter.hasOwnProperty('allowedValues') ? parameter.allowedValues : null,
    }
}

function getParameterOperators(foundParameter, paramId) {
    const parameter = foundParameter.hasOwnProperty('items') && foundParameter.items.find((param) => param.id === paramId)

    return {
        operators: parameter ? parameter.operators : [],
        allowedValues: parameter && parameter.hasOwnProperty('allowedValues') ? parameter.allowedValues : null,
    }
}

function getCustomParameterOperators(foundParameter, paramName) {
    const parameter = foundParameter.hasOwnProperty('items') && foundParameter.items.find((param) => param.name === paramName)

    return {
        operators: parameter ? parameter.operators : [],
        allowedValues: parameter && parameter.hasOwnProperty('allowedValues') ? parameter.allowedValues : null,
    }
}

function parseCondition(condition, allFilters, isCampaign = false) {
    const detailCallFlowStore = useDetailCallFlowsStore()

    if (!condition || !Object.keys(condition).length) {
        return defaultCondition()
    }

    const foundParameter = condition.hasOwnProperty('condition_type_id') ? allFilters.find((parameter) => parameter.id === condition.condition_type_id) : {}

    const parameterId = getCallsFiltersParametersId(condition)

    const { operators, allowedValues } = isCampaign ? getOperatorsAndValues(foundParameter, condition) : getOperators(parameterId, foundParameter, condition)

    const currentOperator = getCurrentOperator(operators, condition.pretty_operator)

    const parseConditionData = {
        ...condition,
        name: condition.pretty_parameter,
        formData: {
            condition_type: foundParameter.label,
            operator: condition.pretty_operator,
            value: checkIsValueRequired(currentOperator) ? condition.value : null,
            traffic_source_param_id: condition.traffic_source_param_id || null,
        },
        operators,
        currentOperator,
    }

    if (parameterId === CALL_FILTERS_PARAMETERS.TRAFFIC_SOURCE) {
        parseConditionData.formData.parameter_id = condition.traffic_source_param_id
    }

    if (PARAMETERS_TYPES.trafficSourceParameters === foundParameter.label) {
        parseConditionData.formData.parameter_id = condition.traffic_source_param_id || condition.parameter_id
    } else if (PARAMETERS_TYPES.callerInputParameters === foundParameter.label) {
        const block = detailCallFlowStore.blocks.find((block) => block.slug === condition.parameter_id)

        parseConditionData.formData.pretty_parameter = block.config.parameter_name
        parseConditionData.pretty_parameter = block.config.parameter_name
    } else if (PARAMETERS_TYPES.customParameters === foundParameter.label) {
        parseConditionData.formData.parameter_id = condition.custom_parameter_id
    } else {
        parseConditionData.formData.parameter = condition.pretty_parameter
    }

    if (Array.isArray(allowedValues)) {
        parseConditionData.allowedValues = allowedValues
    }

    return parseConditionData
}

export {
    parseCallFilter,
    defaultGroup,
    defaultCondition,
}
