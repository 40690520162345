import IncomingCallIcon from '@/components/icons/callFlow/IncomingCallIcon.vue'
import HangUpIcon from '@/components/icons/callFlow/HangUpIcon.vue'
import BusinessHoursIcon from '@/components/icons/callFlow/BusinessHoursIcon.vue'
import MenuIcon from '@/components/icons/callFlow/MenuIcon.vue'
import VoicemailIcon from '@/components/icons/callFlow/VoicemailIcon.vue'
import PlaybackAudioIcon from '@/components/icons/callFlow/PlaybackAudioIcon.vue'
import CallForwardIcon from '@/components/icons/callFlow/CallForwardIcon.vue'
import CallerInputIcon from '@/components/icons/callFlow/CallerInputIcon.vue'
import PathsIcon from '@/components/icons/callFlow/PathsIcon.vue'

import { createScheduleObject } from '@/helpers/schedule-helper'

import {
    CALL_FLOW_BUSINESS_HOURS_SCHEDULE,
    CALL_FLOW_BUSINESS_HOURS_SIMPLE_SCHEDULE,
} from '@/constants/call-tracking/call-flow/callFlowBusinessHours'
import { SEND_DUPLICATE_OPTIONS } from '@/constants/sendDuplicateOptions'

const INCOMING_CALL_COMPONENT = {
    id: 1,
    name: 'Incoming Call',
    config: {
        valid: true,
    },
    paths: [],
    background: '#1CBF5D',
    defaultHeight: 42,
    icon: () => IncomingCallIcon,
}

const CALL_FLOW_COMPONENTS = {
    playbackAudio: {
        id: 2,
        name: 'Playback Audio',
        outputs: {
            prompt_completed: null,
        },
        config: {
            valid: false,
            audio_url: null,
            description: null,
            language: null,
            voice: null,
        },
        paths: [],
        background: '#568FE5',
        defaultHeight: 42,
        info: 'Plays an audio file for the caller before proceeding with further actions',
        icon: () => PlaybackAudioIcon,
    },
    menu: {
        id: 3,
        name: 'Menu',
        outputs: {
            key_1: null,
            no_input: null,
            wrong_input: null,
        },
        config: {
            valid: false,
            interrupt_audio: false,
            timeout: 15,
            retries: 1,
            audio_url: null,
            description: null,
            language: null,
            voice: null,
        },
        paths: [],
        background: '#A290E7',
        defaultHeight: 162,
        info: 'An interactive voice response menu allowing the caller to select further actions by pressing keys',
        icon: () => MenuIcon,
    },
    voicemail: {
        id: 4,
        name: 'Voicemail',
        outputs: {
            recording_completed: null,
        },
        config: {
            valid: false,
            audio_url: null,
            description: null,
            language: null,
            voice: null,
            beep: false,
            timeout: 15,
            end_digit: '*',
            max_length: 15,
            transcription: false,
            voicemail_url: null,
            voicemail_email: null,
        },
        paths: [],
        background: '#FF922E',
        defaultHeight: 42,
        info: 'Records a voicemail message from the caller',
        icon: () => VoicemailIcon,
    },
    callForward: {
        id: 5,
        name: 'Call Forward',
        outputs: {
            busy: null,
            failed: null,
            no_answer: null,
        },
        config: {
            valid: false,
            duplicate_route_id: SEND_DUPLICATE_OPTIONS.normally.id,
            queue_enabled: false,
            duplicates_strict: false,
            queue_time_limit: 5,
            queue_audio: null,
            whisper_message_enabled: false,
            whisper_message_type_id: null,
            whisper_message_url: null,
            whisper_message_text: null,
            whisper_message_language: null,
            whisper_message_voice: null,
        },
        paths: [],
        background: '#FFBF1D',
        defaultHeight: 162,
        info: 'Redirects the call to another phone number or department',
        icon: () => CallForwardIcon,
    },
    hangUp: {
        id: 6,
        name: 'Hang Up',
        outputs: {},
        config: {
            valid: true,
        },
        paths: [],
        background: '#F64C54',
        defaultHeight: 42,
        info: 'Ends the call and disconnects the caller',
        icon: () => HangUpIcon,
    },
    businessHours: {
        id: 7,
        name: 'Business Hours',
        outputs: {
            open: null,
            closed: null,
        },
        config: {
            schedule: JSON.stringify(createScheduleObject(
                [...CALL_FLOW_BUSINESS_HOURS_SCHEDULE],
                true,
                [...CALL_FLOW_BUSINESS_HOURS_SIMPLE_SCHEDULE],
            )),
            timezone_id: 1,
            valid: true,
        },
        paths: [],
        background: '#D47FCC',
        defaultHeight: 122,
        info: 'Defines working hours for handling calls depending on the time of day',
        icon: () => BusinessHoursIcon,
    },
    callerInput: {
        id: 8,
        name: 'Caller Input',
        outputs: {
            success: null,
            no_input: null,
            wrong_input: null,
        },
        config: {
            valid: false,
            parameter_name: null,
            audio_url: null,
            description: null,
            language: null,
            voice: null,
            interrupt_audio: false,
            min_digits: 1,
            max_digits: 1,
            valid_digits: '1234567890',
            end_digit: '#',
            enter_timeout: 3,
            timeout: 10,
            retries: 3,
        },
        background: '#7FD4A1',
        defaultHeight: 122,
        info: 'Collects numerical input from the caller',
        icon: () => CallerInputIcon,
    },
    paths: {
        id: 9,
        name: 'Paths',
        outputs: {
            other: null,
        },
        config: {
            valid: true,
        },
        paths: [],
        background: '#D47FCC',
        defaultHeight: 122,
        info: 'Routes calls based on customizable conditions',
        icon: () => PathsIcon,
    },
}

const CALL_FLOW_ALL_COMPONENTS = {
    INCOMING_CALL_COMPONENT,
    ...CALL_FLOW_COMPONENTS,
}

export {
    CALL_FLOW_COMPONENTS,
    INCOMING_CALL_COMPONENT,
    CALL_FLOW_ALL_COMPONENTS,
}
