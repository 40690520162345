import { defineStore } from 'pinia'
import { toRaw } from 'vue'

import { getFiltersPath } from '@/api/call-flows-routes'

import { useDefaultStore } from '@/store/defaultStore'
import { useDetailCallFlowsStore } from '@/store/call-tracking/callFlow/detailCallFlowsStore'

import { defaultGroup, parseCallFilter } from '@/helpers/call-filter-helper'

import { PARAMETERS_TYPES } from '@/constants/parametersTypes'

export const useDetailCallFlowsPathFilterStore = defineStore('detailCallFlowsPathFilter', {
    state: () => ({
        allFiltersLoading: false,
        allOperatorsLoading: false,
        updateLoading: false,
        actionLoading: false,
        clearLoading: false,

        filters: [],
        allFilters: [],

        operators: [],
        allOperators: [],

        callFilterGroups: [],
        localCallFilterGroups: [defaultGroup()],
        enableValidate: false,
        defaultGroupsView: [],
    }),
    actions: {
        async setCallFilterGroups(groups = []) {
            const { success } = await this.loadAllCampaignFilters()

            if (!success) {
                return
            }

            this.defaultGroupsView = groups

            this.callFilterGroups = parseCallFilter({ groups, allFilters: this.allFilters })
        },

        setLocalCallFilterGroups(value) {
            this.localCallFilterGroups = value
        },

        setEnableValidate(value) {
            this.enableValidate = value
        },

        async goToLoadFiltersPath(slug) {
            if (this.allFilters.length) {
                return { success: true }
            }

            const defaultStore = useDefaultStore()

            this.allFiltersLoading = true

            const { success, payload, message } = await getFiltersPath(slug)

            if (success) {
                defaultStore.setSuccessMessage({ message })

                this.parsePayload(payload)
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.allFiltersLoading = false

            return { success }
        },

        parsePayload(payload) {
            this.filters = payload.filters
            this.operators = payload.operators

            this.allOperators = payload.hasOwnProperty('operators') ? this.parseOperators(payload.operators) : []

            const filters = payload.hasOwnProperty('filters') ? payload.filters : []

            this.allFilters = this.parseFilters(filters)
        },

        parseOperators(operators) {
            return Object.keys(operators).map((operatorName) => ({
                name: operatorName,
                id: operators[operatorName].name,
                required_value: operators[operatorName].required_value,
            }))
        },

        setCallerInputParametersItems(parameters) {
            return parameters.map((param) => ({
                label: param.name,
                isOpen: false,
                items: this.setCallerInputParameterItems([param]),
            }))
        },

        setCallerInputParameterItems(items) {
            return items.map((item) => ({
                name: item.param,
                id: item.slug,
                operators: this.setOperatorsToParameter(item),
            }))
        },

        setItemsFilters(name, parameters) {
            if (name === PARAMETERS_TYPES.trafficSourceParameters) {
                return this.setTrafficSourceParametersItems(parameters)
            }

            if (name === PARAMETERS_TYPES.callerInputParameters) {
                return this.setCallerInputParametersItems(parameters)
            }

            if (name === PARAMETERS_TYPES.customParameters) {
                return this.setCustomParametersItems(parameters)
            }

            return this.setAnotherParameterItems(parameters)
        },

        parseFilters(filters) {
            return Object.keys(filters).map((filterName) => ({
                label: filterName,
                isOpen: false,
                name: filters[filterName].name,
                id: filters[filterName].id,
                items: this.setItemsFilters(filterName, filters[filterName].parameters),
            }))
        },

        setTrafficSourceParametersItems(parameters) {
            return Object.keys(parameters).map((paramName) => ({
                label: paramName,
                isOpen: false,
                items: this.setTrafficSourceParameterItems(parameters[paramName]),
            }))
        },

        setCustomParametersItems(items) {
            return items.map((item) => ({
                name: item.name,
                id: item.id,
                operators: this.setOperatorsToParameter(item),
            }))
        },

        setTrafficSourceParameterItems(items) {
            return items.map((item) => ({
                ...item,
                operators: this.setOperatorsToParameter(item),
            }))
        },

        setAnotherParameterItems(parameters) {
            return Object.keys(parameters).map((parameterName) => {
                const parameter = {
                    name: parameterName,
                    id: parameters[parameterName].name,
                    operators: this.setOperatorsToParameter(parameters[parameterName]),
                }

                if (parameters[parameterName].hasOwnProperty('allowed_values')) {
                    parameter.allowedValues = parameters[parameterName].allowed_values
                }

                return parameter
            })
        },

        setOperatorsToParameter(item) {
            return item.hasOwnProperty('allowed_operators') ? this.getAllowedOperators(item.allowed_operators) : toRaw(this.allOperators)
        },

        addOrUpdateCallerInput(block) {
            const findIndex = this.allFilters.findIndex((filter) => filter.name === 'call_flow_parameters')

            if (!(findIndex + 1)) {
                return
            }

            const existFilter = this.allFilters[findIndex].items.some((item) => item.items.some((subItem) => subItem.id === block.slug))

            if (existFilter) {
                this.updateFilterCallFlowParameter(block, findIndex)
            } else {
                this.addFilterCallFlowParameter(block, findIndex)
            }
        },

        addFilterCallFlowParameter(block, findIndex) {
            this.allFilters[findIndex].items.push({
                label: block.name,
                id: findIndex,
                items: [
                    {
                        id: block.slug,
                        name: block.config.parameter_name,
                        operators: this.setOperatorsToParameter({}),
                    },
                ],
            })
        },

        updateFilterCallFlowParameter(block, findIndex) {
            const itemIndex = this.allFilters[findIndex].items.findIndex((item) => item.items.some((subItem) => subItem.id === block.slug))

            if (itemIndex + 1) {
                this.allFilters[findIndex].items[itemIndex] = {
                    label: block.name,
                    items: [
                        {
                            id: block.slug,
                            name: block.config.parameter_name,
                            operators: this.setOperatorsToParameter({}),
                        },
                    ],
                }
            }
        },

        deleteFilterCallFlowParameter(block) {
            const findIndex = this.allFilters.findIndex((filter) => filter.name === 'call_flow_parameters')

            const itemIndex = this.allFilters[findIndex].items.findIndex((item) => item.items && item.items.some((subItem) => subItem.id === block.slug))

            if (itemIndex + 1) {
                const subItemIndex = this.allFilters[findIndex].items[itemIndex].items.findIndex(
                    (subItem) => subItem.id === block.slug,
                )

                if (subItemIndex + 1) {
                    this.allFilters[findIndex].items[itemIndex].items.splice(subItemIndex, 1)

                    if (this.allFilters[findIndex].items[itemIndex].items.length === 0) {
                        this.allFilters[findIndex].items.splice(itemIndex, 1)
                    }
                }
            }
        },

        getAllowedOperators(allowedOperators = []) {
            return allowedOperators.reduce((operators, name) => {
                const foundOperator = this.allOperators.find((operator) => operator.name === name)

                if (foundOperator) {
                    operators.push(toRaw(foundOperator))
                }

                return operators
            }, [])
        },

        changeUpdateLoading(value) {
            this.updateLoading = value
        },

        getPrettyParameter(condition) {
            const { condition_type, parameter, parameter_id } = condition

            const detailCallFlowStore = useDetailCallFlowsStore()

            if (condition_type === PARAMETERS_TYPES.trafficSourceParameters) {
                const item = Object.values(this.filters[condition_type].parameters)
                    .flat()
                    .find((item) => item.id === parameter_id)

                return item ? item.name : ''
            }

            if (condition_type === PARAMETERS_TYPES.callerInputParameters) {
                const item = detailCallFlowStore.blocks.find((block) => block.slug === parameter_id)

                return item && item.config ? item.config.parameter_name : ''
            }

            if (condition_type === PARAMETERS_TYPES.customParameters) {
                const item = this.findCustomParameter(condition)

                return item ? item.name : ''
            }

            return parameter
        },

        findCustomParameter({ condition_type, parameter, parameter_id }) {
            if (parameter_id) {
                return Object.values(this.filters[condition_type].parameters)
                    .flat()
                    .find((item) => item.id === parameter_id)
            }

            return Object.values(this.filters[condition_type].parameters)
                .flat()
                .find((item) => item.name.toLowerCase().replaceAll(' ', '_') === parameter)
        },

        preparePrettyCondition(conditions) {
            return conditions.map((condition) => {
                const condition_filter = this.allFilters.find((filter) => filter.label === condition.condition_type)

                return {
                    ...condition,
                    condition_type_id: condition_filter.id,
                    pretty_operator: condition.operator,
                    parameter: this.prepareParameter(condition),
                    pretty_parameter: this.getPrettyParameter(condition),
                }
            })
        },

        prepareParameter(condition) {
            if (!condition.parameter) {
                return condition.parameter_id
            }

            if (Number.isInteger(condition.parameter)) {
                return condition.parameter
            }

            return condition.parameter.toLowerCase().replaceAll(' ', '_')
        },

        preparePrettyFilter({ groups }) {
            return groups.map((group) => ({
                ...group,
                conditions: this.preparePrettyCondition(group.conditions),
            }))
        },
    },
})
