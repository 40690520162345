import { defineStore } from 'pinia'

import {
    attachTrafficSourceToPool,
    detachTrafficSourceFromPool,
    getAvailableTrafficSources,
    updateTrafficSourcePool,
} from '@/api/pool-routes'

import { useSortFilterTableStore } from '@/store/sortFilterTableStore'
import { useDefaultStore } from '@/store/defaultStore'
import { useDetailNumberPoolStore } from '@/store/call-tracking/numberPools/detailNumberPoolStore'
import { usePermissionsStore } from '@/store/permissionsStore'

import { checkEqualsFilter, createFiltersForSend, localFilterItems } from '@/helpers/filters-sorting-header-table-helper'
import { getFilterLocalStorageByWorkspaceId, setFilterInLocalStorageByWorkspaceId } from '@/helpers/save-filter-local-storage-helper'
import { getClearQueryLocalStorage } from '@/helpers/remove-query-local-storage-helper'
import { getActiveColumnsHeader, getActiveHeadersTable } from '@/helpers/settings-header-table-helper'

import { FILTER_NAME } from '@/constants/localFilters'
import { SETTINGS_HEADER_TABLE } from '@/constants/headersTable/settingsHeaderTable'
import { FILTER_RULES } from '@/constants/filterRules'
import { viewPorts } from '@/constants/viewPorts'
import { DETAIL_NUMBER_POOL_TRAFFIC_SOURCES_HEADERS_TABLE, DETAIL_NUMBER_POOL_TRAFFIC_SOURCES_HEADERS_TABLE_WITH_ACTIONS } from '@/constants/headersTable/detailNumberPoolTrafficSourcesHeadersTable'
import { INTEGRATIONS } from '@/constants/call-tracking/integrations/integrations'

export const useDetailNumberPoolTrafficSourcesStore = defineStore('detailNumberPoolTrafficSourcesStore', {
    state: () => ({
        loading: false,
        actionLoading: false,

        id: null,
        availableTrafficSources: [],
        trafficSources: [],
        availableTrafficSourcesFilters: [FILTER_NAME],
        trafficSourcesFilters: [
            FILTER_NAME,
        ],

        search: null,

        availableTrafficSourcesSelectedFilters: [],
        availableTrafficSourcesFiltersForSend: {},

        trafficSourcesSelectedFilters: [],
        trafficSourcesFiltersForSend: {},

        activeColumnsTrafficSources: [],
        visibleHeadersTrafficSources: [],
    }),

    getters: {
        trafficSourcesControlParams(state) {
            return {
                filters: state.trafficSourcesFilters.map((filter) => ({
                    ...filter,
                    rule: null,
                    value: null,
                })),
                sorting: [],
            }
        },

        availableTrafficSourcesControlParams(state) {
            return {
                filters: state.availableTrafficSourcesFilters.map((filter) => ({
                    ...filter,
                    rule: null,
                    value: null,
                })),
                sorting: [],
            }
        },

        localTrafficSources(state) {
            return localFilterItems(state.trafficSources, state.trafficSourcesFiltersForSend)
        },

        localAvailableTrafficSources(state) {
            return localFilterItems(state.availableTrafficSources, state.availableTrafficSourcesFiltersForSend)
        },
    },

    actions: {
        setData(trafficSources, id, slug) {
            this.id = id
            this.trafficSources = trafficSources
        },

        onSearch(search) {
            this.search = search
        },

        resetSortFilter(isAvailable = false) {
            if (isAvailable) {
                this.availableTrafficSourcesSelectedFilters = []
                this.availableTrafficSourcesFiltersForSend = {}

                this.saveFiltersSortingInLocalStorage(isAvailable)
            } else {
                this.trafficSourcesSelectedFilters = []
                this.trafficSourcesFiltersForSend = {}

                this.saveFiltersSortingInLocalStorage()
            }
        },

        saveFiltersSortingInLocalStorage(isAvailable = false) {
            if (isAvailable) {
                setFilterInLocalStorageByWorkspaceId(this.trafficSourcesFiltersForSend, `${SETTINGS_HEADER_TABLE.detailNumberPoolAvailableTrafficSources}.${this.id}`)
            } else {
                setFilterInLocalStorageByWorkspaceId(this.trafficSourcesFiltersForSend, `${SETTINGS_HEADER_TABLE.detailNumberPoolTrafficSources}.${this.id}`)
            }
        },

        setFiltersSorting(isAvailable = false) {
            this.preparationFiltersForSet(isAvailable)
            this.saveFiltersSortingInLocalStorage(isAvailable)
        },

        getQueryLocalStorage(isAvailable = false) {
            this.getLocalStorageTrafficSourcesHeadersTable()

            const value = getClearQueryLocalStorage()

            if (value) {
                this.getLocalStorageFilter(isAvailable)
                this.updateFilterAfterQueryLocalStorage(this.trafficSourcesFiltersForSend, isAvailable)
            }
        },

        updateFilterAfterQueryLocalStorage(savedFilter, isAvailable = false) {
            const sortFilterTableStore = useSortFilterTableStore()

            const filters = []

            Object.keys(savedFilter).forEach((key) => {
                const findItem = isAvailable ? this.availableTrafficSourcesFilters.find((el) => el.key === key) : this.trafficSourcesFilters.find((el) => el.key === key)

                if (!findItem) {
                    return
                }

                const newItem = {
                    ...findItem,
                    value: savedFilter[key].value,
                    rule: FILTER_RULES[findItem.type].find((rule) => rule.key === savedFilter[key].rule),
                }

                filters.push(newItem)
            })

            if (!filters.length) {
                return
            }

            if (isAvailable) {
                this.availableTrafficSourcesSelectedFilters = filters
            } else {
                this.trafficSourcesSelectedFilters = filters
            }

            this.trafficSourcesSelectedFilters = filters

            if (isAvailable && viewPorts.mb <= window.innerWidth) {
                sortFilterTableStore.changeIndexOpen(SETTINGS_HEADER_TABLE.detailNumberPoolAvailableTrafficSources, -2)
                sortFilterTableStore.openCloseFilter(SETTINGS_HEADER_TABLE.detailNumberPoolAvailableTrafficSources)
            }

            if (viewPorts.mb <= window.innerWidth) {
                sortFilterTableStore.changeIndexOpen(SETTINGS_HEADER_TABLE.detailNumberPoolTrafficSources, -2)
                sortFilterTableStore.openCloseFilter(SETTINGS_HEADER_TABLE.detailNumberPoolTrafficSources)
            }
        },

        getLocalStorageFilter(isAvailable = false) {
            const savedFilter = getFilterLocalStorageByWorkspaceId(`${isAvailable ? SETTINGS_HEADER_TABLE.detailNumberPoolAvailableTrafficSources : SETTINGS_HEADER_TABLE.detailNumberPoolTrafficSources}.${this.id}`)

            if (!savedFilter && !Object.keys(savedFilter).length) {
                return
            }

            if (isAvailable) {
                this.availableTrafficSourcesFiltersForSend = savedFilter
            } else {
                this.trafficSourcesFiltersForSend = savedFilter
            }
        },

        async goToLoadAvailableTrafficSources(withoutLoading = false) {
            const defaultStore = useDefaultStore()
            const detailNumberPoolsStore = useDetailNumberPoolStore()

            this.loading = !withoutLoading

            const { success, payload, message } = await getAvailableTrafficSources(detailNumberPoolsStore.slug)

            if (success) {
                this.availableTrafficSources = payload
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.loading = false
        },

        async goToAttachTrafficSourceToPool(formData) {
            const defaultStore = useDefaultStore()
            const detailNumberPoolsStore = useDetailNumberPoolStore()
            this.actionLoading = true

            const { success, payload, message } = await attachTrafficSourceToPool(detailNumberPoolsStore.slug, formData)

            if (success) {
                this.trafficSources = payload.traffic_sources
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        async goToDetachTrafficSource({ slug, traffic_source_slugs }) {
            const defaultStore = useDefaultStore()
            this.actionLoading = true

            const { success, message } = await detachTrafficSourceFromPool(slug, traffic_source_slugs)

            if (success) {
                this._removeTrafficSourcesAfterDetach(traffic_source_slugs)
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        async goToUpdateTrafficSource({ slug, formData, type }) {
            const defaultStore = useDefaultStore()
            this.actionLoading = true

            const { success, payload, message } = await updateTrafficSourcePool(slug, formData)

            if (success) {
                this._updateConversionAfterUpdateTrafficSource(formData.traffic_source_slug, payload, type)
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false
        },

        _updateConversionAfterUpdateTrafficSource(slug, payload, type) {
            switch (type) {
            case INTEGRATIONS.google.id:
                this._updateGoogleConversionAfterUpdateTrafficSource(slug, payload.google_ads_conversion_id)
                return
            case INTEGRATIONS.facebook.id:
                this._updateFacebookEventAfterUpdateTrafficSource(slug, payload.facebook_ads_event_id)
            }
        },

        _updateGoogleConversionAfterUpdateTrafficSource(slug, conversionId) {
            const findIndex = this.trafficSources.findIndex((trafficSource) => trafficSource.slug === slug)

            if (findIndex + 1) {
                this.trafficSources[findIndex].pivot = { ...this.trafficSources[findIndex].pivot, google_ads_conversion_id: conversionId }
            }
        },

        _updateFacebookEventAfterUpdateTrafficSource(slug, facebookAdsEventId) {
            const findIndex = this.trafficSources.findIndex((trafficSource) => trafficSource.slug === slug)

            if (findIndex + 1) {
                this.trafficSources[findIndex].pivot = { ...this.trafficSources[findIndex].pivot, facebook_ads_event_id: facebookAdsEventId }
            }
        },

        _removeTrafficSourcesAfterDetach(slugs) {
            this.trafficSources = this.trafficSources.filter((trafficSource) => !slugs.includes(trafficSource.slug))
        },

        preparationFiltersForSet(isAvailable = false) {
            if (isAvailable) {
                const newFilter = createFiltersForSend(this.availableTrafficSourcesSelectedFilters, true)

                if (!checkEqualsFilter(newFilter, this.availableTrafficSourcesFiltersForSend)) {
                    this.availableTrafficSourcesFiltersForSend = newFilter
                }
            } else {
                const newFilter = createFiltersForSend(this.trafficSourcesSelectedFilters, true)

                if (!checkEqualsFilter(newFilter, this.trafficSourcesFiltersForSend)) {
                    this.trafficSourcesFiltersForSend = newFilter
                }
            }
        },

        updateSelectedFilters(payload, isAvailable = false) {
            if (isAvailable) {
                this.availableTrafficSourcesSelectedFilters = payload
            } else {
                this.trafficSourcesSelectedFilters = payload
            }

            this.setFiltersSorting(isAvailable)
        },

        getLocalStorageTrafficSourcesHeadersTable() {
            this.activeColumnsTrafficSources = getActiveColumnsHeader(this.getHeadersByPermissions(), SETTINGS_HEADER_TABLE.detailNumberPoolTrafficSources)
            this.setVisibleTrafficSourcesHeaders()
        },

        setVisibleTrafficSourcesHeaders() {
            this.visibleHeadersTrafficSources = getActiveHeadersTable(this.getHeadersByPermissions(), this.activeColumnsTrafficSources)
        },

        getHeadersByPermissions() {
            const permissionsStore = usePermissionsStore()

            return (permissionsStore.permissions.numberPools.edit || permissionsStore.permissions.trafficSources.view)
                ? DETAIL_NUMBER_POOL_TRAFFIC_SOURCES_HEADERS_TABLE_WITH_ACTIONS
                : DETAIL_NUMBER_POOL_TRAFFIC_SOURCES_HEADERS_TABLE
        },

        changeTrafficSourcesHeaders(payload) {
            this.activeColumnsTrafficSources = payload
            this.setVisibleTrafficSourcesHeaders()
        },
    },
})
