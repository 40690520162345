import { defineStore } from 'pinia'
import { toRaw } from 'vue'

import {
    getAllFilters,
    updateCampaignFilters,
    clearCallFilters,
} from '@/api/call-tracking/campaign/campaign-filters-routes'

import { defaultGroup, parseCallFilter } from '@/helpers/call-filter-helper'

import { useDefaultStore } from '@/store/defaultStore'

import { PARAMETERS_TYPES } from '@/constants/parametersTypes'

export const useDetailCampaignFilterStore = defineStore('detailCampaignFilterStore', {
    state: () => ({
        updateLoading: false,
        actionLoading: false,
        clearLoading: false,
        allFilters: [],
        allOperators: [],
        callFilterGroups: [],
        localCallFilterGroups: [defaultGroup()],
        enableValidate: false,
        defaultGroupsView: [],
    }),
    getters: {

    },
    actions: {
        async setCallFilterGroups(groups = []) {
            const { success } = await this.loadAllCampaignFilters()

            if (!success) {
                return
            }

            this.defaultGroupsView = groups

            this.callFilterGroups = parseCallFilter({ groups, allFilters: this.allFilters }, true)
        },

        setLocalCallFilterGroups(value) {
            this.localCallFilterGroups = value
        },

        setEnableValidate(value) {
            this.enableValidate = value
        },

        async loadAllCampaignFilters() {
            if (this.allFilters.length) {
                return { success: true }
            }

            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, payload, message } = await getAllFilters()

            if (success) {
                defaultStore.setSuccessMessage({ message })

                this.parsePayload(payload)
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        parsePayload(payload) {
            this.allOperators = payload.hasOwnProperty('operators') ? this.parseOperators(payload.operators) : []

            const filters = payload.hasOwnProperty('filters') ? payload.filters : []

            this.allFilters = this.parseFilters(filters)
        },

        parseOperators(operators) {
            return Object.keys(operators).map((operatorName) => ({
                name: operatorName,
                id: operators[operatorName].name,
                required_value: operators[operatorName].required_value,
            }))
        },

        parseFilters(filters) {
            return Object.keys(filters).map((filterName) => ({
                label: filterName,
                isOpen: false,
                name: filters[filterName].name,
                id: filters[filterName].id,
                items: this.createItemsFilter(filters, filterName),
            }))
        },

        createItemsFilter(filters, filterName) {
            switch (filterName) {
            case PARAMETERS_TYPES.trafficSourceParameters:
                return this.setTrafficSourceParametersItems(filters[filterName].parameters)
            case PARAMETERS_TYPES.customParameters:
                return this.mapItemsWithOperators(filters[filterName].parameters)
            default:
                return this.setAnotherParameterItems(filters[filterName].parameters)
            }
        },

        setTrafficSourceParametersItems(parameters) {
            return Object.keys(parameters).map((paramName) => ({
                label: paramName,
                isOpen: false,
                items: this.mapItemsWithOperators(parameters[paramName]),
            }))
        },

        mapItemsWithOperators(items) {
            return items.map((item) => ({
                ...item,
                operators: this.setOperatorsToParameter(item),
            }))
        },

        setAnotherParameterItems(parameters) {
            return Object.keys(parameters).map((parameterName) => {
                const parameter = {
                    name: parameterName,
                    id: parameters[parameterName].name,
                    operators: this.setOperatorsToParameter(parameters[parameterName]),
                }

                if (parameters[parameterName].hasOwnProperty('allowed_values')) {
                    parameter.allowedValues = parameters[parameterName].allowed_values
                }

                return parameter
            })
        },

        setOperatorsToParameter(item) {
            return item.hasOwnProperty('allowed_operators') ? this.getAllowedOperators(item.allowed_operators) : toRaw(this.allOperators)
        },

        getAllowedOperators(allowedOperators = []) {
            return allowedOperators.reduce((operators, name) => {
                const foundOperator = this.allOperators.find((operator) => operator.name === name)

                if (foundOperator) {
                    operators.push(toRaw(foundOperator))
                }

                return operators
            }, [])
        },

        async goToUpdateFilters({ slug, formData }) {
            const defaultStore = useDefaultStore()

            this.changeUpdateLoading(true)

            const { success, payload, message } = await updateCampaignFilters({ slug, formData })

            if (success) {
                defaultStore.setSuccessMessage({ message })

                this.setCallFilterGroups(payload.groups)
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.changeUpdateLoading(false)

            return { success }
        },

        changeUpdateLoading(value) {
            this.updateLoading = value
        },

        async goToClearCallFilters(slug) {
            if (this.clearLoading) {
                return
            }

            const defaultStore = useDefaultStore()

            this.clearLoading = true

            const { success, message } = await clearCallFilters(slug)

            if (success) {
                defaultStore.setSuccessMessage({ message })

                this.setCallFilterGroups([defaultGroup()])
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.clearLoading = false

            return { success }
        },
    },
})
