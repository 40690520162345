const VIEWS_OPTIONS = {
    setupCampaign: {
        view: 'setupCampaign',
        title: 'Manage Campaigns',
        backAction: 'backToManagePage',
    },
    accessCampaign: {
        view: 'accessCampaign',
        title: 'Manage Campaigns',
        backAction: 'backToManagePage',
    },
    addUsersToCampaign: {
        view: 'addUsersToCampaign',
        title: 'Setup Campaign',
        backAction: 'backToSetupCampaign',
    },
    enrichmentUrls: {
        view: 'enrichmentUrls',
        title: 'Manage Campaigns',
        backAction: 'backToManagePage',
    },
    rtbCampaign: {
        view: 'rtbCampaign',
        title: 'Manage Campaigns',
        backAction: 'backToManagePage',
    },
    addTrackingNumbers: {
        view: 'addTrackingNumbers',
        title: 'Setup Campaign',
        backAction: 'backToSetupCampaign',
    },
    addNumberPools: {
        view: 'addNumberPools',
        title: 'Setup Campaign',
        backAction: 'backToSetupCampaign',
    },
    addTrafficSourceToPool: {
        view: 'addTrafficSourceToPool',
        title: 'Detail Number Pool',
        backAction: 'backToSetupNumberPool',
    },
    attachTrafficSourceToPool: {
        view: 'attachTrafficSourceToPool',
        title: 'Setup Traffic Source',
        backAction: 'backToSetupNumberPool',
        confirmation: true,
        dialogOptions: {
            title: 'Are you sure you want to exit this setting?<br />Traffic Source has been created, but has not been attached to the Number Pool',
        },
    },
    attachTrafficSourceToTrackingNumber: {
        view: 'attachTrafficSourceToTrackingNumber',
        title: 'Setup Tracking Number',
        backAction: 'backToSetupTrackingNumber',
        confirmation: true,
        dialogOptions: {
            title: 'Are you sure you want to exit this setting?<br />Traffic Source has been created, but has not been attached to the Tracking Number',
        },
    },
    buyTrackingNumbers: {
        view: 'buyTrackingNumbers',
        title: 'Setup Campaign',
        backAction: 'backToSetupCampaign',
    },
    detailTrackingNumber: {
        view: 'detailTrackingNumber',
        title: 'Setup Campaign',
        backAction: 'backToSetupCampaign',
    },
    detailTrackingNumberAfterBuy: {
        view: 'detailTrackingNumberAfterBuy',
        title: 'Buy Tracking Numbers',
        backAction: 'backToBuyTrackingNumbers',
    },
    setupNewNumberPool: {
        view: 'setupNewNumberPool',
        title: 'Setup Tracking Number',
        backAction: 'backToSetupTrackingNumber',
        confirmation: true,
        dialogOptions: {
            title: 'Are you sure you want to exit this setting?<br />Number pool has been created, but has not been attached to the Tracking Number',
        },
    },
    detailNumberPool: {
        view: 'detailNumberPool',
        title: 'Setup Campaign',
        backAction: 'backToSetupCampaign',
    },
    addTrackingNumbersToNumberPool: {
        view: 'addTrackingNumbersToNumberPool',
        title: 'Setup Number Pool',
        backAction: 'backToSetupCampaign',
    },
    buyTrackingNumbersToNumberPool: {
        view: 'buyTrackingNumbersToNumberPool',
        title: 'Setup Number Pool',
        backAction: 'backToSetupCampaign',
    },
    detailTrafficSourceInPool: {
        view: 'detailTrafficSourceInPool',
        title: 'Setup Number Pool',
        backAction: 'backToSetupNumberPool',
    },
    detailTrafficSourceInTrackingNumber: {
        view: 'detailTrafficSourceInTrackingNumber',
        title: 'Setup Tracking Number',
        backAction: 'backToSetupTrackingNumber',
    },
    setupNewVendor: {
        view: 'setupNewVendorView',
        title: 'Setup Tracking Number',
        backAction: 'backToSetupTrackingNumber',
        confirmation: true,
        dialogOptions: {
            title: 'Are you sure you want to exit this setting?<br />Vendor has been created, but has not been attached to the Tracking Number',
        },
    },
    attachVendorToPool: {
        view: 'attachVendorToPool',
        title: 'Setup Tracking Number',
        backAction: 'backToSetupNumberPool',
        confirmation: true,
        dialogOptions: {
            title: 'Are you sure you want to exit this setting?<br />Vendor has been created, but has not been attached to the Number Pool',
        },
    },
    detailVendor: {
        view: 'detailVendorView',
        title: 'Setup Tracking Number',
        backAction: 'backToSetupTrackingNumber',
    },
    addDestinations: {
        view: 'addDestinationsView',
        title: 'Setup Campaign',
        backAction: 'backToSetupCampaign',
    },
    addDestinationGroups: {
        view: 'addDestinationGroupsView',
        title: 'Setup Campaign',
        backAction: 'backToSetupCampaign',
    },
    addDestinationToMenu: {
        view: 'addDestinationToMenuView',
        title: 'Setup Campaign',
        backAction: 'backToSetupCampaign',
    },
    addGroupToMenu: {
        view: 'addGroupToMenuView',
        title: 'Setup Campaign',
        backAction: 'backToSetupCampaign',
    },
}

export {
    VIEWS_OPTIONS,
}
