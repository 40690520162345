import { getAccessToken } from '@/helpers/auth-helper'

import { ROUTES } from '@/constants/routes'

export default function notFound({ router, store }) {
    if (!getAccessToken()) {
        return router.push({ name: ROUTES.authLogin })
    }

    return router.push({ name: ROUTES.reporting, params: { workspace: store.getWorkspaceSlug } })
}
