import axios from '@/plugins/axios'

import { useAuthorizationStore } from '@/store/authorizationStore'

const getWorkspaceId = () => useAuthorizationStore().getWorkspaceSlug

const getAvailableNumbers = (slug) => axios.get(`${getWorkspaceId()}/campaigns/${slug}/numbers`)
const getAvailableNumberPools = (slug) => axios.get(`${getWorkspaceId()}/campaigns/${slug}/number-pools`)
const buyNumberWithAttach = ({ formData, slug }) => axios.post(`${getWorkspaceId()}/campaigns/${slug}/numbers`, formData)
const attachNumbers = ({ formData, slug }) => axios.patch(`${getWorkspaceId()}/campaigns/${slug}/numbers`, formData)
const attachNumberPools = ({ formData, slug }) => axios.patch(`${getWorkspaceId()}/campaigns/${slug}/number-pools`, formData)
const updateAttachedNumber = ({ formData, slug }) => axios.put(`${getWorkspaceId()}/campaigns/${slug}/numbers`, formData)
const detachNumbers = ({ data, slug }) => axios.delete(`${getWorkspaceId()}/campaigns/${slug}/numbers`, { data })

const updateAttachedNumberPool = ({ formData, slug }) => axios.put(`${getWorkspaceId()}/campaigns/${slug}/number-pools`, formData)
const detachNumberPools = ({ number_pool_slugs, slug }) => axios.delete(`${getWorkspaceId()}/campaigns/${slug}/number-pools`, { data: { number_pool_slugs } })

const getAvailableVendors = (slug) => axios.get(`${getWorkspaceId()}/campaigns/${slug}/vendors`)
const attachVendor = ({ formData, slug, vendorSlug }) => axios.post(`${getWorkspaceId()}/campaigns/${slug}/vendors/${vendorSlug}`, formData)
const updateAttachedVendor = ({ formData, slug, vendorSlug }) => axios.patch(`${getWorkspaceId()}/campaigns/${slug}/vendors/${vendorSlug}`, formData)
const detachVendor = ({ slug, vendorSlug }) => axios.delete(`${getWorkspaceId()}/campaigns/${slug}/vendors/${vendorSlug}`)

const getAvailableDestinations = (slug) => axios.get(`${getWorkspaceId()}/campaigns/${slug}/destinations`)
const attachDestinations = ({ formData, slug }) => axios.post(`${getWorkspaceId()}/campaigns/${slug}/destinations`, formData)
const updateAttachedDestination = ({ formData, slug, destinationSlug }) => axios.patch(`${getWorkspaceId()}/campaigns/${slug}/destinations/${destinationSlug}`, formData)
const detachDestination = ({ slug, slugs }) => axios.delete(`${getWorkspaceId()}/campaigns/${slug}/destinations`, { data: { slugs } })

const getAvailableGroups = (slug) => axios.get(`${getWorkspaceId()}/campaigns/${slug}/destination-groups`)
const attachGroups = ({ formData, slug }) => axios.post(`${getWorkspaceId()}/campaigns/${slug}/destination-groups`, formData)
const updateAttachedGroup = ({ formData, slug, groupSlug }) => axios.patch(`${getWorkspaceId()}/campaigns/${slug}/destination-groups/${groupSlug}`, formData)
const detachGroup = ({ slug, slugs }) => axios.delete(`${getWorkspaceId()}/campaigns/${slug}/destination-groups`, { data: { slugs } })

const attachDestinationToMenu = ({ formData, slug }) => axios.patch(`${getWorkspaceId()}/campaigns/${slug}/menu/destinations`, formData)
const updateDestinationInMenu = ({ formData, slug }) => axios.put(`${getWorkspaceId()}/campaigns/${slug}/menu/destinations`, formData)
const detachDestinationFromMenu = ({ data, slug }) => axios.delete(`${getWorkspaceId()}/campaigns/${slug}/menu/destinations`, { data })

const attachDestinationGroupToMenu = ({ formData, slug }) => axios.patch(`${getWorkspaceId()}/campaigns/${slug}/menu/destination-groups`, formData)
const updateDestinationGroupInMenu = ({ formData, slug }) => axios.put(`${getWorkspaceId()}/campaigns/${slug}/menu/destination-groups`, formData)
const detachDestinationGroupFromMenu = ({ data, slug }) => axios.delete(`${getWorkspaceId()}/campaigns/${slug}/menu/destination-groups`, { data })

const getAvailableUsers = (slug) => axios.get(`${getWorkspaceId()}/campaigns/${slug}/users`)
const attachUsers = ({ formData, slug }) => axios.patch(`${getWorkspaceId()}/campaigns/${slug}/users`, formData)
const detachUsers = ({ emails, slug }) => axios.delete(`${getWorkspaceId()}/campaigns/${slug}/users`, { data: { emails } })

export {
    getAvailableNumbers,
    getAvailableNumberPools,
    buyNumberWithAttach,
    attachNumbers,
    attachNumberPools,
    updateAttachedNumber,
    detachNumbers,
    getAvailableVendors,
    attachVendor,
    updateAttachedVendor,
    detachVendor,
    getAvailableDestinations,
    attachDestinations,
    updateAttachedDestination,
    detachDestination,
    getAvailableGroups,
    attachGroups,
    updateAttachedGroup,
    detachGroup,
    attachDestinationToMenu,
    updateDestinationInMenu,
    detachDestinationFromMenu,
    attachDestinationGroupToMenu,
    updateDestinationGroupInMenu,
    detachDestinationGroupFromMenu,
    getAvailableUsers,
    attachUsers,
    detachUsers,

    updateAttachedNumberPool,
    detachNumberPools,
}
