<template>
    <CustomMenu
        v-model="menu"
        hideTransitionSlider
    >
        <template #dropdown>
            <div
                class="max-mob:w-full w-107.5 fixed shadow-notifications bg-white dark:bg-blue-840 border border-gray-100 dark:border-blue-720 rounded-custom"
                ref="dropdownRef"
                :style="position"
                @click.stop
            >
                <div class="flex justify-between p-5">
                    <div
                        v-if="urgentlyNotification"
                        class="flex flex-1 gap-3 min-w-0"
                    >
                        <div>
                            <AlertIcon class="text-yellow" />
                        </div>

                        <p
                            class="text-xs text-gray-370 dark:text-white line-clamp-2 break-words"
                            v-html="urgentlyNotification.data.message"
                        ></p>
                    </div>

                    <div
                        v-else
                        class="text-xs text-gray-370 dark:text-white line-clamp-2 break-words"
                    >
                        You have {{ notifications.length }} unread {{ notifications.length > 1 ? 'messages' : 'message' }}
                    </div>

                    <div class="flex pl-5 gap-x-7">
                        <div
                            @click="openNotificationMenu"
                            class="text-xs text-purple cursor-pointer text-nowrap"
                        >
                            View all
                        </div>

                        <CloseIcon
                            @click="close"
                            class="text-gray-200 hover:text-primary cursor-pointer transition-all"
                        />
                    </div>
                </div>
            </div>
        </template>
    </CustomMenu>
</template>

<script setup>
import { computed, inject, ref } from 'vue'

import CustomMenu from '@/components/menu/CustomMenu/CustomMenu.vue'
import CloseIcon from '@/components/icons/CloseIcon16x16.vue'
import AlertIcon from '@/components/icons/AlertIcon.vue'

import { viewPorts } from '@/constants/viewPorts'

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
    notifications: {
        type: Array,
        default: () => [],
    },
    lastUrgentlyNotification: {
        default: null,
    },
})
const emits = defineEmits(['update:modelValue', 'onOpenNotificationMenu'])

const currentViewSize = inject('currentViewSize')

const dropdownRef = ref(null)

const isMobView = computed(() => viewPorts.mob >= currentViewSize.value)

const menu = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emits('update:modelValue', value)
    },
})

const position = computed(() => {
    if (!dropdownRef.value) {
        return
    }

    const elem = dropdownRef.value.getBoundingClientRect()
    const left = window.innerWidth - elem.width

    return {
        left: isMobView.value ? '0' : `${left - 20}px`,
        top: isMobView.value ? 'h-app-bar' : '48px',
    }
})

const urgentlyNotification = computed(() => props.lastUrgentlyNotification)

function openNotificationMenu() {
    emits('onOpenNotificationMenu', true)

    close()
}

function close() {
    menu.value = false
}
</script>
