import axios from 'axios'

import { getAccessToken, removeAccessToken } from '@/helpers/auth-helper'

import { useAuthorizationStore } from '@/store/authorizationStore'

const axiosExportApi = axios.create({
    baseURL: `${import.meta.env.VITE_API_EXPORT_URL}/api/`,
})

axiosExportApi.interceptors.request.use(
    (config) => {
        const token = getAccessToken()
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },

    (error) => Promise.reject(error),
)

axiosExportApi.interceptors.response.use(
    (response) => {
        if (response.headers['content-type'].indexOf('text/csv;') + 1) {
            return Promise.resolve(response)
        }

        if (response.headers['content-type'].indexOf('text/plain;') + 1) {
            return Promise.resolve(response)
        }

        if (response.headers['content-type'].indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') + 1) {
            return Promise.resolve(response)
        }

        return Promise.resolve(response.data)
    },

    (error) => {
        if (error?.response?.status) {
            const authorizationStore = useAuthorizationStore()

            switch (error.response.status) {
            // case 303:
            //   store.commit(authTypes.mutations.SET_ACCOUNT_PBX, false)
            //   break
            case 401:
                removeAccessToken()
                authorizationStore.$reset()
                break
                // case 402:
                //     authorizationStore.changePaymentRedirect(true)
                //     break
                // case 403:
                //   store.commit(authTypes.mutations.REDIRECT_FORBIDDEN, true)
                //   break
                // case 409:
                //   store.commit(authTypes.mutations.REDIRECT_ACCOUNT_BLOCKED, true)
                //   break
            }
        }

        const message = error?.response ? error.response.data.message : error.message
        return Promise.resolve({
            success: false,
            message,
        })
    },
)

export default axiosExportApi
