import axios from '@/plugins/axios'

import { useAuthorizationStore } from '@/store/authorizationStore'

const getWorkspaceId = () => useAuthorizationStore().getWorkspaceSlug

const getAllFilters = () => axios.get(`${getWorkspaceId()}/campaigns/filters/list`)
const updateCampaignFilters = ({ slug, formData }) => axios.put(`${getWorkspaceId()}/campaigns/${slug}/filters`, formData)
const clearCallFilters = (slug) => axios.delete(`${getWorkspaceId()}/campaigns/${slug}/filters`)

export {
    getAllFilters,
    updateCampaignFilters,
    clearCallFilters,
}
