import { defineStore } from 'pinia'

import {
    detachNumbersToPool,
    getDetailPool,
    updatePool,
    getAvailableNumbersForPool,
    buyAndAttachNumbersToPool,
    autoBuyPool,
} from '@/api/pool-routes'

import {
    checkEqualsFilter,
    createFiltersForSend, localFilterItems,
    updateHeaderAfterSort,
} from '@/helpers/filters-sorting-header-table-helper'
import {
    getFilterLocalStorageByWorkspaceId,
    setFilterInLocalStorageByWorkspaceId,
} from '@/helpers/save-filter-local-storage-helper'
import { getClearQueryLocalStorage } from '@/helpers/remove-query-local-storage-helper'
import { getActiveColumnsHeader, getActiveHeadersTable } from '@/helpers/settings-header-table-helper'

import { useDefaultStore } from '@/store/defaultStore'
import { useBreadCrumbsStore } from '@/store/breadCrumbsStore'
import { useSortFilterTableStore } from '@/store/sortFilterTableStore'
import { useDetailNumberPoolTrafficSourcesStore } from '@/store/call-tracking/numberPools/detailNumberPoolTrafficSourcesStore'
import { usePermissionsStore } from '@/store/permissionsStore'

import { FILTER_RULES } from '@/constants/filterRules'
import { FILTER_STATUS } from '@/constants/localFilters'
import { SETTINGS_HEADER_TABLE } from '@/constants/headersTable/settingsHeaderTable'
import { NUMBER_IN_POOLS_HEADERS_TABLE, NUMBER_IN_POOLS_HEADERS_TABLE_WITH_ACTIONS } from '@/constants/headersTable/numberInPoolsHeadersTable'
import { VIEWS_OPTIONS } from '@/constants/call-tracking/numberPool/numberPoolSettingsOptions'
import { viewPorts } from '@/constants/viewPorts'

export const useDetailNumberPoolStore = defineStore('detailNumberPool', {
    state: () => ({
        slug: null,
        id: null,
        loading: false,
        updateLoading: false,
        actionLoading: false,
        availableLoading: false,

        formData: {},
        search: '',
        onPage: 25,
        attachedNumbers: [],
        purchasedNumbers: [],

        attachedNumbersFilters: [
            FILTER_STATUS,
        ],
        attachedSelectedFilters: [],
        attachedFiltersForSend: {},

        availableNumbers: [],
        availableNumbersFilters: [
            FILTER_STATUS,
        ],
        availableSelectedFilters: [],
        availableFiltersForSend: {},

        currentView: VIEWS_OPTIONS.setupNumberPool,
        activeColumnsInPoolTable: [],
        visibleHeadersInPoolTable: [],
        selected: [],
    }),

    getters: {
        availableControlParams(state) {
            return {
                filters: state.availableNumbersFilters.map((filter) => ({
                    ...filter,
                    rule: null,
                    value: null,
                })),
                sorting: [],
            }
        },

        attachedControlParams(state) {
            return {
                filters: state.attachedNumbersFilters.map((filter) => ({
                    ...filter,
                    rule: null,
                    value: null,
                })),
                sorting: [],
            }
        },

        localAvailableNumbers(state) {
            return localFilterItems(state.availableNumbers, state.availableFiltersForSend)
        },

        localAttachedNumbers(state) {
            return localFilterItems(state.attachedNumbers, state.attachedFiltersForSend)
        },
    },

    actions: {
        resetSortFilter(isAvailable = false) {
            if (isAvailable) {
                this.availableSelectedFilters = []
                this.availableFiltersForSend = {}

                this.saveFiltersSortingInLocalStorage(isAvailable)
            } else {
                this.attachedSelectedFilters = []
                this.attachedFiltersForSend = {}

                this.visibleHeadersTableParameters = updateHeaderAfterSort(this.visibleHeadersTableParameters)

                this.saveFiltersSortingInLocalStorage(isAvailable)
            }
        },

        saveFiltersSortingInLocalStorage(isAvailable = false) {
            if (isAvailable) {
                setFilterInLocalStorageByWorkspaceId(this.availableFiltersForSend, `${SETTINGS_HEADER_TABLE.availableNumbersInNumberPool}.${this.id}`)
            } else {
                setFilterInLocalStorageByWorkspaceId(this.attachedFiltersForSend, `${SETTINGS_HEADER_TABLE.numberInPool}.${this.id}`)
            }
        },

        setFiltersSorting(isAvailable = false) {
            this.preparationFiltersForSet(isAvailable)
            this.saveFiltersSortingInLocalStorage(isAvailable)
        },

        getQueryLocalStorage(isAvailable = false) {
            const value = getClearQueryLocalStorage()

            if (value) {
                this.getLocalStorageFilter(isAvailable)
                this.updateFilterAfterQueryLocalStorage(
                    isAvailable ? this.availableFiltersForSend : this.attachedFiltersForSend,
                    isAvailable,
                )
                this.getLocalStorageInPoolHeadersTable()
            }
        },

        updateFilterAfterQueryLocalStorage(savedFilter, isAvailable) {
            const sortFilterTableStore = useSortFilterTableStore()

            const filters = []

            Object.keys(savedFilter).forEach((key) => {
                const findItem = isAvailable ? this.availableNumbersFilters.find((el) => el.key === key) : this.attachedNumbersFilters.find((el) => el.key === key)

                if (!findItem) {
                    return
                }

                const newItem = {
                    ...findItem,
                    value: savedFilter[key].value,
                    rule: FILTER_RULES[findItem.type].find((rule) => rule.key === savedFilter[key].rule),
                }

                filters.push(newItem)
            })

            if (!filters.length) {
                return
            }

            if (isAvailable) {
                this.availableSelectedFilters = filters
            }

            if (isAvailable && viewPorts.mb <= window.innerWidth) {
                sortFilterTableStore.changeIndexOpen(SETTINGS_HEADER_TABLE.availableNumbersInNumberPool, -2)
                sortFilterTableStore.openCloseFilter(SETTINGS_HEADER_TABLE.availableNumbersInNumberPool)

                return
            }

            if (!isAvailable) {
                this.attachedSelectedFilters = filters
            }

            if (!isAvailable && viewPorts.mb <= window.innerWidth) {
                sortFilterTableStore.changeIndexOpen(SETTINGS_HEADER_TABLE.numberInPool, -2)
                sortFilterTableStore.openCloseFilter(SETTINGS_HEADER_TABLE.numberInPool)
            }
        },

        getLocalStorageFilter(isAvailable = false) {
            const savedFilter = getFilterLocalStorageByWorkspaceId(isAvailable
                ? `${SETTINGS_HEADER_TABLE.availableNumbersInNumberPool}.${this.id}`
                : `${SETTINGS_HEADER_TABLE.numberInPool}.${this.id}`)

            if (!savedFilter && !Object.keys(savedFilter).length) {
                return
            }

            if (isAvailable) {
                this.availableFiltersForSend = savedFilter
            } else {
                this.attachedFiltersForSend = savedFilter
            }
        },

        preparationFiltersForSet(isAvailable = false) {
            if (isAvailable) {
                const newFilter = createFiltersForSend(this.availableSelectedFilters, true)

                if (!checkEqualsFilter(newFilter, this.availableFiltersForSend)) {
                    this.availableFiltersForSend = newFilter
                }
            } else {
                const newFilter = createFiltersForSend(this.attachedSelectedFilters, true)

                if (!checkEqualsFilter(newFilter, this.attachedFiltersForSend)) {
                    this.attachedFiltersForSend = newFilter
                }
            }
        },

        updateSelectedFilters(payload, isAvailable = false) {
            if (isAvailable) {
                this.availableSelectedFilters = payload
                this.setFiltersSorting(isAvailable)
            } else {
                this.selected = []
                this.attachedSelectedFilters = payload
                this.setFiltersSorting(isAvailable)
            }
        },

        setNumberPoolSlug(slug) {
            this.slug = slug
        },

        setCurrentView(item) {
            if (item.hasOwnProperty('view')) {
                this.currentView = item.view

                return
            }

            if (item.hasOwnProperty('routeName')) {
                this.router.push({ name: item.routeName })
            }
        },

        openAddTrackingNumbers() {
            const breadCrumbsStore = useBreadCrumbsStore()

            const { addTrackingNumbers } = VIEWS_OPTIONS
            this.currentView = addTrackingNumbers

            breadCrumbsStore.addItem({ title: 'Add Tracking Numbers', view: addTrackingNumbers })
        },

        openBuyNumbers() {
            const breadCrumbsStore = useBreadCrumbsStore()

            const { buyTrackingNumbers } = VIEWS_OPTIONS
            this.currentView = buyTrackingNumbers

            breadCrumbsStore.addItem({ title: 'Buy Tracking Numbers', view: buyTrackingNumbers })
        },

        parsePayload({ numbers, ...other }) {
            this.id = other.id
            this.formData = other
            this.attachedNumbers = numbers
        },

        changeSelectedItems(newSelectedItems) {
            this.selected = newSelectedItems
        },

        addAttachedNumbers(numbers) {
            this.attachedNumbers = [...numbers, ...this.attachedNumbers]
        },

        updateTrackingNumberStatus(payload) {
            payload.forEach((item) => {
                const index = this.attachedNumbers.findIndex((i) => i.slug === item.slug)
                if (index !== -1) {
                    this.attachedNumbers.splice(index, 1, item)
                }
            })
        },

        clearSelected() {
            this.selected = []
        },

        _changeAttachedNumberAfterDetach(number_slugs) {
            this.attachedNumbers = this.attachedNumbers.filter(({ slug }) => !number_slugs.includes(slug))
        },

        openDetailVendor() {
            const breadCrumbsStore = useBreadCrumbsStore()

            this.currentView = VIEWS_OPTIONS.detailVendor

            breadCrumbsStore.addItem({ title: 'Detail Vendor', view: VIEWS_OPTIONS.detailVendor.view })
        },

        openAttachVendor() {
            const breadCrumbsStore = useBreadCrumbsStore()

            this.currentView = VIEWS_OPTIONS.attachVendor

            breadCrumbsStore.addItem({ title: 'Setup Vendor', view: VIEWS_OPTIONS.attachVendor.view })
        },

        openAddTrafficSources() {
            const breadCrumbsStore = useBreadCrumbsStore()

            this.currentView = VIEWS_OPTIONS.addTrafficSources

            breadCrumbsStore.addItem({ title: 'Add Traffic Sources', view: VIEWS_OPTIONS.addTrafficSources.view })
        },

        openDetailTrafficSource() {
            const breadCrumbsStore = useBreadCrumbsStore()

            this.currentView = VIEWS_OPTIONS.detailTrafficSource

            breadCrumbsStore.addItem({ title: 'Detail Traffic Source', view: VIEWS_OPTIONS.detailTrafficSource.view })
        },

        openAttachTrafficSource() {
            const breadCrumbsStore = useBreadCrumbsStore()

            this.currentView = VIEWS_OPTIONS.setupNewTrafficSource

            breadCrumbsStore.addItem({ title: 'Setup Traffic Source', view: VIEWS_OPTIONS.setupNewTrafficSource.view })
        },

        async goToLoadDetailNumberPool() {
            if (!this.slug) {
                return
            }

            const defaultStore = useDefaultStore()
            const detailNumberPoolTrafficSourcesStore = useDetailNumberPoolTrafficSourcesStore()

            this.loading = true

            const { success, payload, message } = await getDetailPool(this.slug)

            if (success) {
                this.parsePayload(payload)
                detailNumberPoolTrafficSourcesStore.setData(payload.traffic_sources, payload.id, payload.slug)
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.loading = false

            return success
        },

        async goToUpdateNumberPool(formDataForUpdate) {
            const defaultStore = useDefaultStore()

            this.updateLoading = true

            const formDataForSend = {
                ...this.formData,
                ...formDataForUpdate,
            }

            if (formDataForSend.vendor && typeof formDataForSend.vendor !== 'string') {
                formDataForSend.vendor = formDataForSend.vendor.slug
            }

            const { success, payload, message } = await updatePool({
                formData: formDataForSend,
                slug: this.slug,
            })

            if (success) {
                this.parsePayload({ ...payload, numbers: this.attachedNumbers })

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.updateLoading = false

            return { success }
        },

        async goToDetachNumbersFromPool({
            numbers = [], number = null, unrent = false, slug,
        }) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const formData = {
                unrent,
            }

            if (number) {
                formData.number_slugs = [number.slug]
            } else {
                formData.number_slugs = numbers.map(({ slug }) => slug)
            }

            const { success, message } = await detachNumbersToPool({ slug, data: formData })

            if (success) {
                this._changeAttachedNumberAfterDetach(formData.number_slugs)
                this.selected = []
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return success
        },
        async goToLoadAvailableNumbersForPool(withoutLoading = false) {
            const defaultStore = useDefaultStore()

            this.availableLoading = !withoutLoading

            const { success, payload, message } = await getAvailableNumbersForPool(this.slug)

            if (success) {
                this.availableNumbers = payload
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.availableLoading = false

            return success
        },

        async goToBuyAndAttachNumbersToPool(formData) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, message, payload } = await buyAndAttachNumbersToPool({
                slug: this.slug,
                formData,
            })

            if (success) {
                this.attachedNumbers = [payload, ...this.attachedNumbers]

                this.addPurchasedNumber(payload)

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        async goToAutoBuyAndAttachNumbersToPool(formData) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, message } = await autoBuyPool({
                slug: this.slug,
                formData,
            })

            if (success) {
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        addPurchasedNumber(number) {
            this.purchasedNumbers.push(number)
        },

        changeSearch(value) {
            this.search = value
        },

        changePage(value) {
            this.onPage = value
        },

        getLocalStorageInPoolHeadersTable() {
            this.activeColumnsInPoolTable = getActiveColumnsHeader(this.getHeadersByPermission(), SETTINGS_HEADER_TABLE.numberInPool)
            this.setVisibleInPoolHeaders()
        },

        setVisibleInPoolHeaders() {
            this.visibleHeadersInPoolTable = getActiveHeadersTable(this.getHeadersByPermission(), this.activeColumnsInPoolTable)
        },

        getHeadersByPermission() {
            const permissionsStore = usePermissionsStore()

            return permissionsStore.permissions.numberPools.edit ? NUMBER_IN_POOLS_HEADERS_TABLE_WITH_ACTIONS : NUMBER_IN_POOLS_HEADERS_TABLE
        },

        changeInPoolHeaders(payload) {
            this.activeColumnsInPoolTable = payload
            this.setVisibleInPoolHeaders()
        },
    },
})
