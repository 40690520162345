import { viewPorts } from '@/constants/viewPorts'

export const FORWARDS_CALL_TO_BY_REVENUE_HEADERS_TABLE = [
    { name: 'Name', value: 'name', disabled: true },
    { name: 'Number', value: 'destination' },
    { name: 'Type', value: 'type' },
    { name: 'Revenue', value: 'revenue' },
    {
        name: 'Status', value: 'status', align: 'center', width: 100,
    },
    {
        name: 'Actions', value: 'actions', width: 90, align: 'center', disabled: true, sticky: true, right: viewPorts.mb >= window.innerWidth ? -1 : 0,
    },
]
