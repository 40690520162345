import { useBreadCrumbsStore } from '@/store/breadCrumbsStore'
import { useDetailTrafficSourceStore } from '@/store/call-tracking/trafficSources/detailTrafficSourceStore'
import { useDetailCampaignTrackingNumberStore } from '@/store/call-tracking/campaigns/detailCampaignTrackingNumberStore'
import { useDetailNumberPoolStore } from '@/store/call-tracking/numberPools/detailNumberPoolStore'

import { INTEGRATIONS } from '@/constants/call-tracking/integrations/integrations'
import { REDIRECT_PART_PATH_PAGE } from '@/constants/call-tracking/integrations/redirectRoutesViews'
import { VIEWS_OPTIONS } from '@/constants/call-tracking/campaign/campaignSettingsOptions'

function findIntegrationById(id) {
    return Object.values(INTEGRATIONS).find((integration) => integration.id === id) || INTEGRATIONS.google
}

function convertCustomQueryAndAddParamsForFacebook(facebookAuthResult) {
    const {
        sc, code, redirect_route, integration_type_id,
    } = facebookAuthResult

    const [path, queryString] = redirect_route.split('?')
    const queryArray = queryString ? queryString.split(';;') : []

    const params = new URLSearchParams()

    queryArray.forEach((pair) => {
        const [key, value] = pair.split('=')
        if (key && value) {
            params.set(key, value)
        }
    })

    params.set('sc', sc)
    params.set('code', code)
    params.set('integration_type_id', integration_type_id)

    const newQueryString = params.toString()

    return `${path}?${newQueryString}`
}

function _getViewAndFromInstruction() {
    const breadCrumbsStore = useBreadCrumbsStore()
    const lastBC = breadCrumbsStore.items[breadCrumbsStore.items.length - 1]

    return {
        view: typeof lastBC.view === 'string' ? lastBC.view : lastBC.view?.view,
    }
}

function getRedirectRoute(separate = '&') {
    const { pathname } = window.location
    const parts = pathname.split('/').filter(Boolean)
    const [_, section] = parts

    switch (section) {
    case REDIRECT_PART_PATH_PAGE.trafficSources:
    case REDIRECT_PART_PATH_PAGE.integration:
        return pathname
    case REDIRECT_PART_PATH_PAGE.numbersPool:
    case REDIRECT_PART_PATH_PAGE.trackingNumbers:
        return _getDefaultRedirect(pathname, separate)
    case REDIRECT_PART_PATH_PAGE.campaigns:
        return _getCampaignRedirect(pathname, separate)
    }
}

function _getDefaultRedirect(pathname, separate) {
    const detailTrafficSourceStore = useDetailTrafficSourceStore()
    const trafficSourceSlug = detailTrafficSourceStore.slug

    const { view } = _getViewAndFromInstruction()

    return `${pathname}?redirect=true${separate}trafficSource=${trafficSourceSlug}${separate}scope=${view}`
}
function _getCampaignRedirect(pathname, separate) {
    const { view } = _getViewAndFromInstruction()

    switch (view) {
    case VIEWS_OPTIONS.detailTrafficSourceInPool.view:
    case VIEWS_OPTIONS.attachTrafficSourceToPool.view:
        return _getCampaignNumberPoolRedirectRoute(pathname, separate, view)
    case VIEWS_OPTIONS.detailTrafficSourceInTrackingNumber.view:
    case VIEWS_OPTIONS.attachTrafficSourceToTrackingNumber.view:
        return _getCampaignTrackingNumberRedirectRoute(pathname, separate, view)
    }
}

function _getCampaignTrackingNumberRedirectRoute(pathname, separate, view) {
    const detailTrafficSourceStore = useDetailTrafficSourceStore()
    const detailCampaignTrackingNumberStore = useDetailCampaignTrackingNumberStore()

    const trackingNumber = detailCampaignTrackingNumberStore.selectedNumberSlug
    const trafficSourceSlug = detailTrafficSourceStore.slug

    return `${pathname}?redirect=true${separate}trackingNumber=${trackingNumber}${separate}trafficSource=${trafficSourceSlug}${separate}scope=${view}`
}

function _getCampaignNumberPoolRedirectRoute(pathname, separate, view) {
    const detailTrafficSourceStore = useDetailTrafficSourceStore()
    const detailNumberPoolStore = useDetailNumberPoolStore()

    const numberPoolSlug = detailNumberPoolStore.slug
    const trafficSourceSlug = detailTrafficSourceStore.slug

    return `${pathname}?redirect=true${separate}numberPool=${numberPoolSlug}${separate}trafficSource=${trafficSourceSlug}${separate}scope=${view}`
}

export {
    findIntegrationById,
    getRedirectRoute,
    convertCustomQueryAndAddParamsForFacebook,
}
