import { defineStore } from 'pinia'

import {
    getDetailTrafficSource,
    updateTrafficSource,
    deleteTrafficSourceParameter,
    getTrafficSourceEventsAll,
    updateStatusTrafficSourcesParameter,
} from '@/api/call-tracking/traffic-sources/traffic-sources-routes'

import {
    checkEqualsFilter,
    createFiltersForSend,
    localFilterItems,
    updateHeaderAfterSort,
} from '@/helpers/filters-sorting-header-table-helper'
import { getActiveColumnsHeader, getActiveHeadersTable } from '@/helpers/settings-header-table-helper'
import { getClearQueryLocalStorage } from '@/helpers/remove-query-local-storage-helper'
import { getFilterLocalStorageByWorkspaceId, setFilterInLocalStorageByWorkspaceId } from '@/helpers/save-filter-local-storage-helper'

import { useDefaultStore } from '@/store/defaultStore'
import { useSortFilterTableStore } from '@/store/sortFilterTableStore'
import { usePermissionsStore } from '@/store/permissionsStore'

import { SETTINGS_HEADER_TABLE } from '@/constants/headersTable/settingsHeaderTable'
import { TRAFFIC_SOURCES_PARAMETERS_HEADERS, TRAFFIC_SOURCES_PARAMETERS_HEADERS_WITH_ACTIONS } from '@/constants/headersTable/trafficSourcesParametersHeadersTable'
import { FILTER_ACTIVE, FILTER_NAME, FILTER_URL_PARAM } from '@/constants/localFilters'
import { FILTER_RULES } from '@/constants/filterRules'
import { LOCAL_FILTER_SHORT_NAME } from '@/constants/localFilterShortName'
import { viewPorts } from '@/constants/viewPorts'

export const useDetailTrafficSourceStore = defineStore('detailTrafficSource', {
    state: () => ({
        slug: null,
        loading: false,
        updateLoading: false,
        actionLoading: false,

        formData: {
            name: null,
            event_id: null,
            postback_url: null,
            facebook_events: [],
        },

        integration: null,

        search: '',
        selected: [],
        events: [],
        parameters: [],

        parametersFilters: [
            FILTER_NAME,
            FILTER_URL_PARAM,
            FILTER_ACTIVE,
        ],
        parametersSelectedFilters: [],
        parametersFiltersForSend: {},

        activeColumnsTableParameters: [],
        visibleHeadersTableParameters: [],
    }),

    getters: {
        parametersControlParams(state) {
            return {
                filters: state.parametersFilters.map((filter) => ({
                    ...filter,
                    rule: null,
                    value: null,
                })),
                sorting: [],
            }
        },

        localParameters(state) {
            return localFilterItems(state.parameters, state.parametersFiltersForSend)
        },
    },

    actions: {
        updateIntegration(integration) {
            this.integration = integration
        },

        resetSortFilter() {
            this.parametersSelectedFilters = []
            this.parametersFiltersForSend = {}

            this.visibleHeadersTableParameters = updateHeaderAfterSort(this.visibleHeadersTableParameters)

            this.saveFiltersSortingInLocalStorage()
        },

        setFiltersSorting() {
            this.preparationFiltersForSet()
            this.saveFiltersSortingInLocalStorage()
        },

        saveFiltersSortingInLocalStorage() {
            setFilterInLocalStorageByWorkspaceId(this.parametersFiltersForSend, LOCAL_FILTER_SHORT_NAME[SETTINGS_HEADER_TABLE.trafficSourceParameters])
        },

        async getQueryLocalStorage() {
            const value = getClearQueryLocalStorage()

            if (value) {
                this.getLocalStorageFilter()
                this.updateFilterAfterQueryLocalStorage(this.parametersFiltersForSend)
            }
        },

        getLocalStorageFilter() {
            const savedFilter = getFilterLocalStorageByWorkspaceId(LOCAL_FILTER_SHORT_NAME[SETTINGS_HEADER_TABLE.trafficSourceParameters])

            if (savedFilter && Object.keys(savedFilter).length) {
                this.parametersFiltersForSend = savedFilter
            }
        },

        updateFilterAfterQueryLocalStorage(savedFilter) {
            const sortFilterTableStore = useSortFilterTableStore()

            const filters = []

            Object.keys(savedFilter).forEach((key) => {
                const findItem = this.parametersFilters.find((el) => el.key === key)

                if (!findItem) {
                    return
                }

                const newItem = {
                    ...findItem,
                    value: savedFilter[key].value,
                    rule: FILTER_RULES[findItem.type].find((rule) => rule.key === savedFilter[key].rule),
                }

                filters.push(newItem)
            })

            if (!filters.length) {
                return
            }

            this.parametersSelectedFilters = filters

            if (viewPorts.mob <= window.innerWidth) {
                sortFilterTableStore.changeIndexOpen(SETTINGS_HEADER_TABLE.trafficSourceParameters, -2)
                sortFilterTableStore.openCloseFilter(SETTINGS_HEADER_TABLE.trafficSourceParameters)
            }
        },

        preparationFiltersForSet() {
            const newFilter = createFiltersForSend(this.parametersSelectedFilters, true)

            if (!checkEqualsFilter(newFilter, this.parametersFiltersForSend)) {
                this.parametersFiltersForSend = newFilter
            }
        },

        updateSelectedFilters(payload) {
            this.parametersSelectedFilters = payload
            this.setFiltersSorting()
        },

        setTrafficSourceSlug(slug) {
            this.slug = slug
        },

        addParameter(newParameter) {
            this.parameters.push(newParameter)
        },

        _changeParametersAfterDelete(ids) {
            this.parameters = this.parameters.filter((i) => !ids.includes(i.id))
        },

        _changeManyParametersAfterUpdate(payload) {
            payload.forEach((item) => {
                const index = this.parameters.findIndex((i) => i.id === item.id)
                if (index !== -1) {
                    this.parameters.splice(index, 1, item)
                }
            })
        },

        getHeadersByPermission() {
            const permissionsStore = usePermissionsStore()

            return permissionsStore.permissions.trafficSources.edit ? TRAFFIC_SOURCES_PARAMETERS_HEADERS_WITH_ACTIONS : TRAFFIC_SOURCES_PARAMETERS_HEADERS
        },

        getLocalStorageHeadersTable() {
            this.activeColumnsTableParameters = getActiveColumnsHeader(
                this.getHeadersByPermission(),
                SETTINGS_HEADER_TABLE.trafficSourceParameters,
            )
            this.setVisibleHeaders()
        },

        setVisibleHeaders() {
            this.visibleHeadersTableParameters = getActiveHeadersTable(
                this.getHeadersByPermission(),
                this.activeColumnsTableParameters,
            )
        },

        changeHeadersParameters(payload) {
            this.activeColumnsTableParameters = payload
            this.setVisibleHeaders()
        },

        parsePayload({ integration, url_params = [], ...other }) {
            this.formData = other
            this.parameters = url_params

            this.integration = integration || null
        },

        changeSelectedItems(newSelectedItems) {
            this.selected = newSelectedItems
        },

        async goToLoadDetailTrafficSource(withoutLoading = false) {
            const defaultStore = useDefaultStore()

            this.loading = !withoutLoading

            const { success, payload, message } = await getDetailTrafficSource(this.slug)

            if (success) {
                this.parsePayload(payload)
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.loading = false

            return success
        },

        async goToUpdateTrafficSource(formDataForUpdate) {
            const defaultStore = useDefaultStore()

            this.updateLoading = true

            const formDataForSend = {
                ...this.formData,
                ...formDataForUpdate,
            }

            const { success, payload, message } = await updateTrafficSource({
                formData: formDataForSend,
                slug: this.slug,
            })

            if (success) {
                this.parsePayload({ ...formDataForSend, integration: payload.integration, url_params: this.parameters })

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.updateLoading = false
        },

        async goToDeleteTrafficSourceParameter({ trafficSources = [], trafficSource = null }) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const formData = {}

            if (trafficSource) {
                formData.ids = [trafficSource.id]
            } else {
                formData.ids = trafficSources.map(({ id }) => id)
            }

            const { success, message } = await deleteTrafficSourceParameter({
                slug: this.slug,
                data: formData,
            })

            if (success) {
                this._changeParametersAfterDelete(formData.ids)
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return success
        },

        clearSelected() {
            this.selected = []
        },

        async goToChangeStatusActiveTrafficSourceParameter({ trafficSource = null, trafficSources = [], status_id }) {
            const defaultStore = useDefaultStore()

            this.updateLoading = true

            const formData = {}

            if (trafficSource) {
                formData.ids = [trafficSource.id]
            } else {
                formData.ids = trafficSources.map(({ id }) => id)
            }
            formData.active = status_id

            const { success, payload, message } = await updateStatusTrafficSourcesParameter({
                slug: this.slug,
                formData,
            })

            if (success) {
                this._changeManyParametersAfterUpdate(payload)
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.updateLoading = false

            return success
        },

        async goToLoadTrafficSourceEventsAll() {
            const defaultStore = useDefaultStore()

            const { success, payload, message } = await getTrafficSourceEventsAll()

            if (success) {
                this.events = payload
            } else {
                defaultStore.setErrorMessage({ message })
            }
        },

        changeSearch(value) {
            this.search = value
        },
    },
})
