import axios from '@/plugins/axios'

import { useAuthorizationStore } from '@/store/authorizationStore'

const getWorkspaceId = () => useAuthorizationStore().getWorkspaceSlug

const getDestinationsForManager = (params) => axios.get(`${getWorkspaceId()}/destinations/paginated`, { params })
const getDestinationsForBuyer = (params) => axios.get(`${getWorkspaceId()}/buyer/destinations/paginated`, { params })
const getDetailDestinationForManager = (slug) => axios.get(`${getWorkspaceId()}/destinations/${slug}`)
const getDetailDestinationForBuyer = (slug) => axios.get(`${getWorkspaceId()}/buyer/destinations/${slug}`)
const getAllDestinations = () => axios.get(`${getWorkspaceId()}/destinations/all`)
const getDestinationsOptions = () => axios.get(`${getWorkspaceId()}/destinations`)
const createDestination = (formData) => axios.post(`${getWorkspaceId()}/destinations`, formData)
const updateDestinationsStatus = (formData) => axios.patch(`${getWorkspaceId()}/destinations/status`, formData)
const updateDestinationsStatusForBuyer = (formData) => axios.patch(`${getWorkspaceId()}/buyer/destinations/status`, formData)
const updateDestination = ({ formData, slug }) => axios.patch(`${getWorkspaceId()}/destinations/${slug}`, formData)
const updateDestinationForBuyer = ({ formData, slug }) => axios.patch(`${getWorkspaceId()}/buyer/destinations/${slug}`, formData)
const updateDestinationRevenueForBuyer = ({ formData, slug }) => axios.patch(`${getWorkspaceId()}/buyer/destinations/${slug}/revenues`, formData)
const deleteDestination = (data) => axios.delete(`${getWorkspaceId()}/destinations`, { data })
const resetDestinationCaps = (slug) => axios.delete(`${getWorkspaceId()}/destinations/${slug}/counters`)
const countersDestinationForManager = (formData) => axios.post(`${getWorkspaceId()}/destinations/counters`, formData)
const countersDestinationForBuyer = (formData) => axios.post(`${getWorkspaceId()}/buyer/destinations/counters`, formData)

const getDestinationFiltersAndSorting = () => axios.get(`${getWorkspaceId()}/destinations/filters-and-sorting`)

const sendPingRequest = (slug) => axios.get(`${getWorkspaceId()}/destinations/${slug}/ping-request`)
const sendPostRequest = (slug) => axios.get(`${getWorkspaceId()}/destinations/${slug}/post-request`)

export {
    getDestinationsForManager,
    getDestinationsForBuyer,
    getDestinationsOptions,
    createDestination,
    updateDestinationsStatus,
    updateDestinationsStatusForBuyer,
    updateDestination,
    updateDestinationForBuyer,
    updateDestinationRevenueForBuyer,
    deleteDestination,
    resetDestinationCaps,
    getAllDestinations,
    countersDestinationForManager,
    countersDestinationForBuyer,
    getDestinationFiltersAndSorting,
    getDetailDestinationForManager,
    getDetailDestinationForBuyer,
    sendPingRequest,
    sendPostRequest,
}
