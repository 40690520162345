<template>
    <div class="absolute w-full h-app-bar-bc">
        <div
            ref="mobileBCRef"
            class="relative w-full h-app-bar-bc flex bg-white dark:bg-blue-800 border-gray-100 dark:border-blue-720 transition-all duration-500 custom-scroll-x-w-0"
            :class="breadCrumbsStore.showMobileBC && isMbView ? 'top-app-bar border-b' : '-top-app-bar'"
        >
            <div class="flex items-center grow relative max-w-min pr-10">
                <CustomBreadCrumbs />
            </div>

            <div class="fixed top-app-bar right-15">
                <div class="absolute bg-bc-gradient dark:bg-bc-gradient-dark h-7.5 w-15"></div>
            </div>
        </div>
    </div>

    <div
        class="absolute pr-5 max-mob:pr-4 w-full h-app-bar bg-white dark:bg-blue-800 border-b border-gray-100 dark:border-gray-760"
        :class="wrapperClass"
    >
        <div class="flex w-full justify-between items-center h-full">
            <div
                class="relative h-full flex items-center grow overflow-hidden custom-scroll-x-w-0"
                ref="headerRef"
            >
                <div
                    class="h-full flex items-center cursor-pointer"
                    @click="showSideBars"
                >
                    <MenuIcon class="ml-4 text-gray-370 dark:text-white block min-mb:hidden" />
                </div>

                <div class="flex items-center h-fit">
                    <CustomBreadCrumbs class="hidden min-mb:block" />
                </div>

                <div class="max-mob:hidden sticky top-0 h-app-bc-shadow right-15">
                    <div class="absolute bg-bc-gradient dark:bg-bc-gradient-dark h-full w-15"></div>
                </div>
            </div>

            <div class="flex items-center gap-x-7 max-mob:gap-x-4">
                <AccountBalance
                    v-if="isManager"
                    :workspace="workspace"
                />

                <div class="flex items-center gap-x-2">
                    <div class="text-xs text-gray-370 dark:text-white">
                        Live:
                    </div>

                    <div class="text-xs text-green-600 dark:text-green">
                        {{ liveCalls }}
                    </div>
                </div>

                <div class="flex items-center gap-x-2">
                    <div class="text-xs text-gray-370 dark:text-white">
                        Total:
                    </div>

                    <div class="text-xs text-purple">
                        {{ totalCalls }}
                    </div>
                </div>

                <div>
                    <NotificationMenu
                        v-model="notificationMenu"
                        v-if="showNotification"
                        :smallNotificationMenu="smallNotificationMenu"
                        :unreadNotifications="unreadNotifications"
                        :readNotifications="readNotifications"
                        :loadingRead="notificationsStore.loadingRead"
                        :loadingReadIds="notificationsStore.loadingReadIds"
                        @onRead="notificationsStore.onRead"
                    />

                    <NotificationSmallMenu
                        v-if="showNotification && unreadNotifications.length"
                        v-model="smallNotificationMenu"
                        :notifications="unreadNotifications"
                        :lastUrgentlyNotification="lastUrgentlyNotification"
                        @onOpenNotificationMenu="handleNotificationMenu"
                    />
                </div>
            </div>
        </div>

        <TrialVersionInfo
            v-if="isAccountTrial"
            :showMobileBC="breadCrumbsStore.showMobileBC && isMbView"
        />
    </div>
</template>

<script setup>
import {
    computed, onUnmounted, ref, watch, inject,
} from 'vue'

import AccountBalance from '@/components/accountBalance/AccountBalance.vue'
import CustomBreadCrumbs from '@/components/breadCrumbs/CustomBreadCrumbs.vue'
import TrialVersionInfo from '@/components/trialVersionInfo/TrialVersionInfo.vue'
import NotificationMenu from '@/components/menu/NotificationMenu.vue'
import NotificationSmallMenu from '@/components/menu/NotificationSmallMenu.vue'
import MenuIcon from '@/components/icons/MenuIcon.vue'

import { getHeaderCalls } from '@/api/headers-routes'

import { useBreadCrumbsStore } from '@/store/breadCrumbsStore'
import { useDefaultStore } from '@/store/defaultStore'
import { useAuthorizationStore } from '@/store/authorizationStore'
import { useNotificationsStore } from '@/store/notificaitonsStore'

import { viewPorts } from '@/constants/viewPorts'

const props = defineProps({
    mini: {
        type: Boolean,
        default: false,
    },
    marginClass: {
        type: Array,
        default: () => [],
    },
})

const emits = defineEmits(['showSideBars'])

const defaultStore = useDefaultStore()
const notificationsStore = useNotificationsStore()
const breadCrumbsStore = useBreadCrumbsStore()
const authorizationStore = useAuthorizationStore()

const currentViewSize = inject('currentViewSize')

const mobileBCRef = ref(null)
const headerRef = ref(null)

const liveCalls = ref(0)
const totalCalls = ref(0)

const liveInterval = ref(null)

const notificationMenu = ref(false)
const smallNotificationMenu = ref(true)

const wrapperClass = computed(() => [
    ...props.marginClass,
    props.mini && 'min-mb:w-app-bar-min',
    !props.mini && 'min-mb:w-app-bar-max',
])

const isMbView = computed(() => viewPorts.mb >= currentViewSize.value)

const workspace = computed(() => authorizationStore.workspace)
const isAccountTrial = computed(() => authorizationStore.isAccountTrial)
const isManager = computed(() => authorizationStore.isManager)

const showNotification = computed(() => notificationsStore.showNotification)
const unreadNotifications = computed(() => notificationsStore.unreadNotifications)
const readNotifications = computed(() => notificationsStore.readNotifications)
const lastUrgentlyNotification = computed(() => notificationsStore.lastUnreadUrgentlyNotification)

function setLiveInterval() {
    clearInterval(liveInterval.value)

    loadHeaderCalls()

    liveInterval.value = setInterval(() => {
        loadHeaderCalls()
    }, 60000)
}

async function loadHeaderCalls() {
    const { success, payload, message } = await getHeaderCalls()

    if (success) {
        const { live = 0, completed = 0 } = payload

        liveCalls.value = live
        totalCalls.value = completed
    } else {
        defaultStore.setErrorMessage(message)
    }
}

function showSideBars() {
    emits('showSideBars')
}

function handleNotificationMenu(value) {
    notificationMenu.value = value
}

function scrollToRight() {
    setTimeout(() => {
        if (headerRef.value) {
            headerRef.value.scrollTo({ top: 0, left: 9999, behavior: 'smooth' })
        }

        if (mobileBCRef.value) {
            mobileBCRef.value.scrollTo({ top: 0, left: 9999, behavior: 'smooth' })
        }
    }, 100)
}

function scrollToRightMobileBC() {
    setTimeout(() => {
        mobileBCRef.value.scrollTo({ top: 0, left: 9999, behavior: 'smooth' })
    }, 100)
}

watch(() => breadCrumbsStore.items, () => {
    scrollToRight()
    scrollToRightMobileBC()
}, { deep: true })

watch(workspace, (newWorkspace) => {
    if (newWorkspace) {
        setLiveInterval()
        notificationsStore.setIntervalNotification()
    }
}, { immediate: true })

onUnmounted(() => {
    clearInterval(liveInterval.value)
})
</script>
