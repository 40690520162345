const VIEWS_OPTIONS = {
    setupNumberPool: {
        view: 'setupNumberPool',
        title: 'Manage Number Pools',
    },
    detailVendor: {
        view: 'detailVendor',
        title: 'Setup Number Pool',
    },
    detailTrafficSource: {
        view: 'detailTrafficSource',
        title: 'Setup Number Pool',
    },
    addTrackingNumbers: {
        view: 'addTrackingNumbers',
        title: 'Setup Number Pool',
    },
    addTrafficSources: {
        view: 'addTrafficSources',
        title: 'Setup Number Pool',
    },
    buyTrackingNumbers: {
        view: 'buyTrackingNumbers',
        title: 'Setup Number Pool',
    },
    setupNewTrafficSource: {
        view: 'setupNewTrafficSource',
        title: 'Setup Traffic Source',
        confirmation: true,
        dialogOptions: {
            title: 'Are you sure you want to exit this setting?<br />Traffic Source has been created, but has not been attached to the Number Pool',
        },
    },
    attachVendor: {
        view: 'attachVendor',
        title: 'Setup Number Pool',
        confirmation: true,
        dialogOptions: {
            title: 'Are you sure you want to exit this setting?<br />Vendor has been created, but has not been attached to the Number Pool',
        },
    },
}

export {
    VIEWS_OPTIONS,
}
