import { defineStore } from 'pinia'

import {
    attachDestinationGroupToMenu,
    attachGroups,
    detachDestinationGroupFromMenu,
    detachGroup,
    getAvailableGroups,
    updateAttachedGroup,
    updateDestinationGroupInMenu,
} from '@/api/call-tracking/campaign/campaign-actions-routes'

import { getFilterLocalStorageByWorkspaceId, setFilterInLocalStorageByWorkspaceId } from '@/helpers/save-filter-local-storage-helper'
import { checkEqualsFilter, createFiltersForSend, localFilterItems } from '@/helpers/filters-sorting-header-table-helper'
import { getClearQueryLocalStorage } from '@/helpers/remove-query-local-storage-helper'

import { useDefaultStore } from '@/store/defaultStore'
import { useDetailCampaignStore } from '@/store/call-tracking/campaigns/detailCampaignStore'
import { useDetailCampaignDestinationStore } from '@/store/call-tracking/campaigns/detailCampaignDestinationStore'
import { useSortFilterTableStore } from '@/store/sortFilterTableStore'

import { SETTINGS_HEADER_TABLE } from '@/constants/headersTable/settingsHeaderTable'
import { FILTER_STATUS } from '@/constants/localFilters'
import { FILTER_RULES } from '@/constants/filterRules'
import { LOCAL_FILTER_SHORT_NAME } from '@/constants/localFilterShortName'
import { viewPorts } from '@/constants/viewPorts'

export const useDetailCampaignGroupDestinationStore = defineStore('detailCampaignGroupDestinationStore', {
    state: () => ({
        loading: false,
        actionLoading: false,

        destination_groups: [],

        availableGroups: [],
        availableGroupsFilters: [
            FILTER_STATUS,
        ],
        availableGroupsSelectedFilters: [],
        availableGroupsFiltersForSend: {},

        menu_groups: [],
    }),
    getters: {
        availableGroupsControlsParams(state) {
            return {
                filters: state.availableGroupsFilters.map((filter) => ({
                    ...filter,
                    rule: null,
                    value: null,
                })),
                sorting: [],
            }
        },

        localAvailableGroups(state) {
            return localFilterItems(state.availableGroups, state.availableGroupsFiltersForSend)
        },
    },
    actions: {
        resetSortFilter() {
            this.availableGroupsSelectedFilters = []
            this.availableGroupsFiltersForSend = {}

            this.saveFiltersSortingInLocalStorage()
        },

        saveFiltersSortingInLocalStorage() {
            const detailCampaignStore = useDetailCampaignStore()
            setFilterInLocalStorageByWorkspaceId(this.availableGroupsFiltersForSend, `${LOCAL_FILTER_SHORT_NAME[SETTINGS_HEADER_TABLE.campaignAvailableGroupDestination]}.${detailCampaignStore.id}`)
        },

        setGroupDestination({ destination_groups, menu }) {
            this.destination_groups = destination_groups
            this.menu_groups = menu.destination_groups.map((g) => ({ ...g, isGroup: true }))
        },

        getQueryLocalStorage() {
            const value = getClearQueryLocalStorage()

            if (value) {
                this.getLocalStorageFilter()
                this.updateFilterAfterQueryLocalStorage(this.availableGroupsFiltersForSend)
            }
        },

        updateFilterAfterQueryLocalStorage(savedFilter) {
            const sortFilterTableStore = useSortFilterTableStore()

            const filters = []

            Object.keys(savedFilter).forEach((key) => {
                const findItem = this.availableGroupsFilters.find((el) => el.key === key)

                if (!findItem) {
                    return
                }

                const newItem = {
                    ...findItem,
                    value: savedFilter[key].value,
                    rule: FILTER_RULES[findItem.type].find((rule) => rule.key === savedFilter[key].rule),
                }

                filters.push(newItem)
            })

            if (!filters.length) {
                return
            }

            this.availableGroupsSelectedFilters = filters

            if (viewPorts.mob <= window.innerWidth) {
                sortFilterTableStore.changeIndexOpen(SETTINGS_HEADER_TABLE.campaignAvailableGroupDestination, -2)
                sortFilterTableStore.openCloseFilter(SETTINGS_HEADER_TABLE.campaignAvailableGroupDestination)
            }
        },

        getLocalStorageFilter() {
            const detailCampaignStore = useDetailCampaignStore()
            const savedFilter = getFilterLocalStorageByWorkspaceId(`${LOCAL_FILTER_SHORT_NAME[SETTINGS_HEADER_TABLE.campaignAvailableGroupDestination]}.${detailCampaignStore.id}`)

            if (!savedFilter && !Object.keys(savedFilter).length) {
                return
            }

            this.availableGroupsFiltersForSend = savedFilter
        },

        setFiltersSorting() {
            this.preparationFiltersForSet()

            this.saveFiltersSortingInLocalStorage()
        },

        preparationFiltersForSet() {
            const newFilter = createFiltersForSend(this.availableGroupsSelectedFilters, true)

            if (!checkEqualsFilter(newFilter, this.availableGroupsFiltersForSend)) {
                this.availableGroupsFiltersForSend = newFilter
            }
        },

        updateSelectedFilters(payload) {
            this.availableGroupsSelectedFilters = payload

            this.setFiltersSorting()
        },

        async goToLoadAvailableGroups(withoutLoading = false) {
            const detailCampaignStore = useDetailCampaignStore()
            const defaultStore = useDefaultStore()

            this.loading = !withoutLoading

            const { success, payload, message } = await getAvailableGroups(detailCampaignStore.slug)

            if (success) {
                this.availableGroups = payload
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.loading = false
        },

        async goToAttachGroupsToCampaign({ groups = [], pivot }) {
            const detailCampaignStore = useDetailCampaignStore()
            const defaultStore = useDefaultStore()

            if (!groups.length) {
                return
            }

            this.actionLoading = true

            const { success, payload, message } = await attachGroups({
                formData: {
                    groups: groups.map((n) => n.slug),
                    ...pivot,
                },
                slug: detailCampaignStore.slug,
            })

            if (success) {
                this.setAttachedGroups(payload)

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        updateGroupStatusAfterChange(payload) {
            payload.forEach((group) => {
                const index = this.destination_groups.findIndex((d) => d.slug === group.slug)

                if (index + 1) {
                    this.destination_groups[index] = {
                        ...this.destination_groups[index],
                        status_id: group.status_id,
                    }
                }
            })
        },

        goToUpdateAttachedChildDestination(item, formData) {
            const { pivot = {}, slug } = item
            const { group_id } = pivot
            const findGroup = this.destination_groups.find((g) => g.id === group_id)

            if (!findGroup) {
                return
            }

            const index = findGroup.destinations.findIndex((d) => d.slug === slug)

            if (index + 1) {
                findGroup.destinations[index].pivot = {
                    ...formData,
                    group_id,
                }
            }
        },

        async goToDetachGroup({ group = null, groups = [] }) {
            const detailCampaignStore = useDetailCampaignStore()
            const defaultStore = useDefaultStore()

            const formData = {
                slug: detailCampaignStore.slug,
            }

            if (group) {
                formData.slugs = [group.slug]
            } else {
                formData.slugs = groups.map(({ slug }) => slug)
            }

            this.actionLoading = true

            const { success, payload, message } = await detachGroup(formData)

            if (success) {
                this.removeGroup(formData.slugs)

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success, payload }
        },

        updateChildrenDestinationStatus(destination, group_id) {
            const findGroup = this.destination_groups.find((g) => g.id === group_id)

            if (!findGroup) {
                return
            }

            const index = findGroup.destinations.findIndex((d) => d.slug === destination.slug)

            if (index + 1) {
                findGroup.destinations[index].status_id = destination.status_id
            }
        },

        async goToUpdateAttachedGroup({ formData, groupSlug }) {
            const detailCampaignStore = useDetailCampaignStore()
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, payload, message } = await updateAttachedGroup({
                slug: detailCampaignStore.slug,
                formData,
                groupSlug,
            })

            if (success) {
                this.updateGroupAfterChange({ ...payload, isGroup: true })

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        updateGroupAfterChange(group) {
            const index = this.destination_groups.findIndex((g) => g.slug === group.slug)

            if (index + 1) {
                this.destination_groups[index] = group
            }
        },

        removeGroup(slugs) {
            this.destination_groups = this.destination_groups.filter(({ slug }) => !slugs.find((item) => item === slug))
        },

        removeChildDestination(destination, group_id) {
            const findGroup = this.destination_groups.find((g) => g.id === group_id)

            if (!findGroup) {
                return
            }

            findGroup.destinations = findGroup.destinations.filter((d) => d.slug !== destination.slug)
        },

        setAttachedGroups(groups = []) {
            this.destination_groups.push(...groups.map((g) => ({ ...g, isGroup: true })))
        },

        updateMenuGroupStatusAfterChange(payload) {
            const [destination] = payload

            this.menu_groups.forEach((d) => {
                if (d.slug === destination.slug) {
                    d.status_id = destination.status_id
                }
            })
        },

        async goToAttachGroupToMenu(formData) {
            const detailCampaignStore = useDetailCampaignStore()
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, payload, message } = await attachDestinationGroupToMenu({ formData, slug: detailCampaignStore.slug })

            if (success) {
                defaultStore.setSuccessMessage({ message })

                this._addGroupToMenu(payload)
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        _addGroupToMenu(group) {
            this.menu_groups.push({ ...group, isGroup: true })
        },

        async goToUpdateGroupToMenu(formData) {
            const detailCampaignStore = useDetailCampaignStore()
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, payload, message } = await updateDestinationGroupInMenu({ formData, slug: detailCampaignStore.slug })

            if (success) {
                defaultStore.setSuccessMessage({ message })

                this._updateGroupToMenu(payload, formData)
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        _updateGroupToMenu(group, { old_digit }) {
            const foundIndex = this.menu_groups.findIndex((g) => g.pivot.digit === old_digit)

            if (foundIndex + 1) {
                this.menu_groups[foundIndex] = {
                    ...group,
                    isGroup: true,
                }
            }
        },

        async goToDetachGroupFromMenu({ data, removeBlockAfterDelete = false }) {
            const detailCampaignStore = useDetailCampaignStore()
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, message } = await detachDestinationGroupFromMenu({ data, slug: detailCampaignStore.slug })

            if (success) {
                defaultStore.setSuccessMessage({ message })

                this.removeGroupFromMenu({ digit: data.digit, removeBlockAfterDelete })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        removeGroupFromMenu({ digit, removeBlockAfterDelete = true }) {
            if (removeBlockAfterDelete) {
                this.menu_groups = this.menu_groups.filter((g) => g.pivot.digit !== digit)
            } else {
                this.menu_groups = this.menu_groups.map((g) => {
                    if (g.pivot.digit === digit) {
                        g.removed = true
                    }

                    return g
                })
            }
        },

        goToUpdateAttachedChildDestinationMenu(group_id, slug, formData) {
            this.menu_groups.forEach((g) => {
                if (g.id !== group_id) {
                    return
                }

                const index = g.destinations.findIndex((d) => d.slug === slug)

                if (index + 1) {
                    g.destinations[index].pivot = {
                        ...formData,
                        group_id,
                    }
                }
            })
        },

        updateMenuChildrenDestinationStatus(destination) {
            const detailCampaignDestinationStore = useDetailCampaignDestinationStore()

            this.menu_groups.forEach((d) => {
                d.destinations = detailCampaignDestinationStore.updateMenuDestinationStatus(d.destinations, destination)
            })
        },

        removeMenuChildDestination(destination) {
            this.menu_groups.forEach((g) => {
                g.destinations = g.destinations.filter((d) => d.slug !== destination.slug)
            })
        },
    },
})
