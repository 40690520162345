import axios from '@/plugins/axios'

import { useAuthorizationStore } from '@/store/authorizationStore'

const getWorkspaceId = () => useAuthorizationStore().getWorkspaceSlug

const getTrafficSourcesAll = () => axios.get(`${getWorkspaceId()}/traffic-sources/all`)
const getTrafficSources = (params) => axios.get(`${getWorkspaceId()}/traffic-sources/paginated`, { params })
const getDetailTrafficSource = (slug) => axios.get(`${getWorkspaceId()}/traffic-sources/${slug}`)
const getTrafficSourceEventsAll = () => axios.get(`${getWorkspaceId()}/traffic-source-events`)
const createTrafficSource = (formData) => axios.post(`${getWorkspaceId()}/traffic-sources`, formData)
const updateTrafficSource = ({ formData, slug }) => axios.patch(`${getWorkspaceId()}/traffic-sources/${slug}`, formData)
const deleteTrafficSource = (data) => axios.delete(`${getWorkspaceId()}/traffic-sources`, { data })

const createTrafficSourcesParameter = ({ formData, slug }) => axios.post(`${getWorkspaceId()}/traffic-sources/${slug}/params`, formData)
const deleteTrafficSourceParameter = ({ data, slug }) => axios.delete(`${getWorkspaceId()}/traffic-sources/${slug}/params`, { data })
const updateStatusTrafficSourcesParameter = ({ slug, formData }) => axios.patch(`${getWorkspaceId()}/traffic-sources/${slug}/params/status`, formData)
const getTrafficSourceFiltersAndSorting = () => axios.get(`${getWorkspaceId()}/traffic-sources/filters-and-sorting`)

export {
    getTrafficSourcesAll,
    getTrafficSources,
    getDetailTrafficSource,
    getTrafficSourceEventsAll,
    createTrafficSource,
    updateTrafficSource,
    createTrafficSourcesParameter,
    deleteTrafficSource,
    deleteTrafficSourceParameter,
    updateStatusTrafficSourcesParameter,
    getTrafficSourceFiltersAndSorting,
}
