import { defineStore } from 'pinia'

import {
    getAvailableUsers, attachUsers, detachUsers,
} from '@/api/call-tracking/campaign/campaign-actions-routes'

import { getActiveColumnsHeader, getActiveHeadersTable } from '@/helpers/settings-header-table-helper'
import {
    checkEqualsFilter,
    createFiltersForSend, localFilterItems,
    updateHeaderAfterSort,
} from '@/helpers/filters-sorting-header-table-helper'
import {
    getFilterLocalStorageByWorkspaceId,
    setFilterInLocalStorageByWorkspaceId,
} from '@/helpers/save-filter-local-storage-helper'
import { getClearQueryLocalStorage } from '@/helpers/remove-query-local-storage-helper'
import { uniqArrayByField } from '@/helpers/app-helper'

import { useDetailCampaignStore } from '@/store/call-tracking/campaigns/detailCampaignStore'
import { useDefaultStore } from '@/store/defaultStore'
import { useAuthorizationStore } from '@/store/authorizationStore'
import { useSortFilterTableStore } from '@/store/sortFilterTableStore'

import { DETAIL_CAMPAIGN_ACCESS_HEADERS } from '@/constants/headersTable/detailCampaignAccessHeadersTable'
import { SETTINGS_HEADER_TABLE } from '@/constants/headersTable/settingsHeaderTable'
import { LOCAL_FILTER_SHORT_NAME } from '@/constants/localFilterShortName'
import { FILTER_RULES } from '@/constants/filterRules'
import { viewPorts } from '@/constants/viewPorts'
import { FILTER_EMAIL, FILTER_ROLE } from '@/constants/localFilters'

export const useDetailCampaignAccessStore = defineStore('detailCampaignAccess', {
    state: () => ({
        loading: false,
        attachLoading: false,
        actionLoading: false,

        filters: [],
        selectedFilters: [],
        filtersForSend: {},

        activeColumnsTable: [],
        visibleHeadersTable: [],

        availableUsers: [],

        selectedUsers: [],

        users: [],
    }),

    getters: {
        controlParams(state) {
            return {
                filters: state.filters.map((filter) => ({
                    ...filter,
                    rule: null,
                    value: null,
                })),
                sorting: state.sorting,
            }
        },

        localUsers(state) {
            return localFilterItems(state.users, state.filtersForSend)
        },
    },

    actions: {
        getQueryLocalStorage() {
            this.getLocalStorageHeadersTable()

            const value = getClearQueryLocalStorage()

            if (value) {
                this.getLocalStorageFilter()
                this.updateFilterAfterQueryLocalStorage(this.filtersForSend)
            }
        },

        getLocalStorageHeadersTable() {
            this.activeColumnsTable = getActiveColumnsHeader(DETAIL_CAMPAIGN_ACCESS_HEADERS, SETTINGS_HEADER_TABLE.campaignAccess)
            this.setVisibleHeaders()
        },

        setVisibleHeaders() {
            this.visibleHeadersTable = getActiveHeadersTable(DETAIL_CAMPAIGN_ACCESS_HEADERS, this.activeColumnsTable)
        },

        changeHeaders(payload) {
            this.activeColumnsTable = payload
            this.setVisibleHeaders()
        },
        resetSortFilter() {
            this.selectedFilters = []
            this.filtersForSend = {}

            this.visibleHeadersTable = updateHeaderAfterSort(this.visibleHeadersTable)

            this.saveFiltersSortingInLocalStorage()
        },

        setFiltersSorting() {
            this.preparationFiltersForSet()
            this.saveFiltersSortingInLocalStorage()
        },

        saveFiltersSortingInLocalStorage() {
            const detailCampaignStore = useDetailCampaignStore()

            setFilterInLocalStorageByWorkspaceId(this.filtersForSend, `${LOCAL_FILTER_SHORT_NAME[SETTINGS_HEADER_TABLE.campaignAccess]}.${detailCampaignStore.id}`)
        },

        preparationFiltersForSet() {
            const newFilter = createFiltersForSend(this.selectedFilters, true)

            if (!checkEqualsFilter(newFilter, this.filtersForSend)) {
                this.filtersForSend = newFilter
            }
        },

        updateSelectedFilters(payload) {
            this.selectedFilters = payload
            this.setFiltersSorting()
        },

        updateFilterAfterQueryLocalStorage(savedFilter) {
            const sortFilterTableStore = useSortFilterTableStore()

            const filters = []

            Object.keys(savedFilter).forEach((key) => {
                const findItem = this.filters.find((el) => el.key === key)

                if (!findItem) {
                    return
                }

                const newItem = {
                    ...findItem,
                    value: savedFilter[key].value,
                    rule: FILTER_RULES[findItem.type].find((rule) => rule.key === savedFilter[key].rule),
                }

                filters.push(newItem)
            })

            if (!filters.length) {
                return
            }

            this.selectedFilters = filters

            if (viewPorts.mob <= window.innerWidth) {
                sortFilterTableStore.changeIndexOpen(SETTINGS_HEADER_TABLE.campaignAccess, -2)
                sortFilterTableStore.openCloseFilter(SETTINGS_HEADER_TABLE.campaignAccess)
            }
        },

        getLocalStorageFilter() {
            const detailCampaignStore = useDetailCampaignStore()

            const savedFilter = getFilterLocalStorageByWorkspaceId(`${LOCAL_FILTER_SHORT_NAME[SETTINGS_HEADER_TABLE.campaignAccess]}.${detailCampaignStore.id}`)

            if (savedFilter && Object.keys(savedFilter).length) {
                this.filtersForSend = savedFilter
            }
        },

        setMembers(payload) {
            const authorizationStore = useAuthorizationStore()

            this.users = payload.users.filter((user) => user.email !== authorizationStore.user.email).map((user) => ({
                ...user,
                role: user.roles[0].name,
            }))
        },

        async goToLoadAvailableUsers() {
            const defaultStore = useDefaultStore()
            const detailCampaignStore = useDetailCampaignStore()

            this.loading = true

            const { success, payload, message } = await getAvailableUsers(detailCampaignStore.slug)

            if (success) {
                payload.forEach((user) => {
                    user.role = user.roles[0].name
                })

                this.availableUsers = payload
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.loading = false
        },

        parseFiltersAvailableRoles() {
            this.filters = [
                FILTER_EMAIL,
                {
                    ...FILTER_ROLE,
                    values: uniqArrayByField(
                        this.users.map((item) => ({ name: item.role, id: item.role })),
                        'id',
                    ),
                },
            ]
        },

        selectUsers(users) {
            this.selectedUsers = users
        },

        async goToAttachUsers() {
            const defaultStore = useDefaultStore()
            const detailCampaignStore = useDetailCampaignStore()

            this.attachLoading = true

            const formData = {
                emails: this.selectedUsers.map((user) => user.email),
            }

            const { success, payload, message } = await attachUsers({
                formData,
                slug: detailCampaignStore.slug,
            })

            if (success) {
                this.setMembers(payload)

                await this.goToLoadAvailableUsers()

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.attachLoading = false
        },

        async goToDetachUser(emails) {
            const defaultStore = useDefaultStore()
            const detailCampaignStore = useDetailCampaignStore()

            this.actionLoading = true

            const { success, payload, message } = await detachUsers({
                emails,
                slug: detailCampaignStore.slug,
            })

            if (success) {
                this.setMembers(payload)

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },
    },
})
