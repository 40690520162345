import { useAuthorizationStore } from '@/store/authorizationStore'

function _getAllTimezonesFromLocalStorage() {
    const { getWorkspaceSlug } = useAuthorizationStore()
    const allTimezones = localStorage.getItem('account_timezones')

    const parsedTimezones = allTimezones ? JSON.parse(allTimezones) : { [getWorkspaceSlug]: {} }

    if (Array.isArray(parsedTimezones)) {
        localStorage.removeItem('account_timezones')
        return { [getWorkspaceSlug]: {} }
    }

    return parsedTimezones
}

function getCurrentTimezone(type) {
    const { getWorkspaceSlug } = useAuthorizationStore()
    const timezone = _getAllTimezonesFromLocalStorage()[getWorkspaceSlug]

    return timezone?.[type]
}

function setTimezoneInLocalStorage(timezoneId, type) {
    const { getWorkspaceSlug } = useAuthorizationStore()
    const activeTimezone = _getAllTimezonesFromLocalStorage()

    if (!activeTimezone[getWorkspaceSlug]) {
        activeTimezone[getWorkspaceSlug] = {}
    }

    activeTimezone[getWorkspaceSlug][type] = timezoneId

    localStorage.setItem('account_timezones', JSON.stringify(activeTimezone))
}

export { getCurrentTimezone, setTimezoneInLocalStorage }
