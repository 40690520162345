<template>
    <div class="custom-scroll-y px-5 max-h-screen-70 divide-y divide-white-500 dark:divide-gray-760">
        <div
            v-for="(item, index) in notifications"
            class="px-3 py-4 relative hover:bg-purple-50 dark:hover:bg-blue-770 transition-all"
            :class="[
                ...getStyleNotification(item, index),
                loadingReadIds[item.id] ? 'cursor-wait' : 'cursor-pointer',
            ]"
            :key="index"
            @click="onRead(item, index)"
        >
            <div class="relative mb-1">
                <div
                    class="mr-7.5 text-xs whitespace-pre-wrap text-gray-370 dark:text-white break-words"
                    v-html="messages(item)"
                />

                <div
                    v-if="!item.read_at"
                    class="w-6 h-6 flex items-center justify-center absolute top-0 right-0.5"
                    v-tippy="getTippyTable('Mark as read')"
                >
                    <div class="w-1.5 h-1.5 rounded-full bg-purple" />
                </div>
            </div>

            <div class="flex items-center gap-x-2">
                <div
                    v-if="item.data.type === NOTIFICATION_TYPES.recommendation"
                    class="px-2 py-1 rounded-1.25 text-xs text-orange-50 bg-orange-50/25"
                >
                    Urgently
                </div>

                <div class="text-xs text-gray-200 dark:text-gray-280">
                    {{ date(item) }}
                </div>
            </div>
        </div>

        <div class="flex flex-col gap-y-4 mt-5 pb-5">
            <CustomDivider />

            <div class="flex justify-center text-xs text-gray-280">
                These are all the notifications from the last 30 days
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'

import CustomDivider from '@/components/divider/CustomDivider.vue'

import { formatDateForNotification } from '@/helpers/date-helper'
import { getTippyTable } from '@/helpers/tippy-helper'

import { NOTIFICATION_TYPES } from '@/constants/notificationTypes'

const props = defineProps({
    notifications: {
        type: Array,
        default: () => [],
    },
    loadingReadIds: {
        type: Object,
        default: () => ({}),
    },
})

const emits = defineEmits(['onRead'])

const selectItem = ref(null)

function getStyleNotification(notification, index) {
    const classes = []

    if (!notification.read_at) {
        classes.push('bg-gray-80 dark:bg-blue-780')
    }

    if (index === 0) {
        classes.push('rounded-t-1.25')
    }

    if (props.notifications.length === index + 1) {
        classes.push('rounded-b-1.25')
    }

    return classes
}

function checkUrl(url) {
    return /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi.test(url)
}

function messages({ data = {} }) {
    const rows = data.message.split('\n')
    const results = rows.map((textRow) => {
        if (checkUrl(textRow)) {
            const res = textRow.split(' ').map((text) => {
                if (checkUrl(text)) {
                    return `<a target="_blank" href="${text}" class="text-purple dark:text-purple-140">${text}</a>`
                }
                return text
            })
            return res.join(' ')
        }
        return textRow
    })
    return results.join('\n')
}

function date({ created_at }) {
    return formatDateForNotification(created_at)
}

async function onRead({ id, read_at }, index) {
    selectItem.value = id

    if (!read_at) {
        emits('onRead', id, index)
    }
}
</script>
