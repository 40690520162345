export const CALL_FLOW_BUSINESS_HOURS_SCHEDULE = [
    {
        day: 'Mon', valid: true, active: true, range: { start: [9, 0], end: [20, 0] },
    },
    {
        day: 'Tue', valid: true, active: true, range: { start: [9, 0], end: [20, 0] },
    },
    {
        day: 'Wed', valid: true, active: true, range: { start: [9, 0], end: [20, 0] },
    },
    {
        day: 'Thu', valid: true, active: true, range: { start: [9, 0], end: [20, 0] },
    },
    {
        day: 'Fri', valid: true, active: true, range: { start: [9, 0], end: [20, 0] },
    },
    {
        day: 'Sat', valid: true, active: true, range: { start: [9, 0], end: [20, 0] },
    },
    {
        day: 'Sun', valid: true, active: true, range: { start: [9, 0], end: [20, 0] },
    },
]

export const CALL_FLOW_BUSINESS_HOURS_SIMPLE_SCHEDULE = [{ valid: true, active: true, range: { start: [9, 0], end: [20, 0] } }]
