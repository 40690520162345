export default function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index]

    if (!subsequentMiddleware) {
        return context.next
    }

    return (...parameters) => {
        // Добавляем флаг, чтобы убедиться, что next() вызывается только один раз
        let called = false

        const nextMiddleware = (...args) => {
            if (called) {
                return // предотвращаем повторный вызов
            }

            called = true
            return nextFactory(context, middleware, index + 1)(...args)
        }

        subsequentMiddleware({ ...context, next: nextMiddleware })

        if (!called) {
            context.next(...parameters)
        }
    }
}
