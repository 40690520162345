import { defineStore } from 'pinia'

import { getDetailCallFlow, updateCallFlow } from '@/api/call-flows-routes'
import { getAllDestinations } from '@/api/destinations-routes'
import { getAllGroupDestinations } from '@/api/destinations-group-routes'

import { checkEqualsArrays, deepCopy } from '@/helpers/app-helper'
import { checkEqualsFilter, createFiltersForSend, localFilterItems } from '@/helpers/filters-sorting-header-table-helper'
import { getFilterLocalStorageByWorkspaceId, setFilterInLocalStorageByWorkspaceId } from '@/helpers/save-filter-local-storage-helper'
import { getClearQueryLocalStorage } from '@/helpers/remove-query-local-storage-helper'

import { useDefaultStore } from '@/store/defaultStore'
import { useSortFilterTableStore } from '@/store/sortFilterTableStore'
import { usePermissionsStore } from '@/store/permissionsStore'
import { useDetailCallFlowsPathFilterStore } from '@/store/call-tracking/callFlow/detailCallFlowsPathFilterStore'

import {
    CALL_FLOW_ALL_COMPONENTS,
    CALL_FLOW_COMPONENTS,
    INCOMING_CALL_COMPONENT,
} from '@/constants/call-tracking/call-flow/callFlowComponents'
import { FILTER_STATUS } from '@/constants/localFilters'
import { LOCAL_FILTER_SHORT_NAME } from '@/constants/localFilterShortName'
import { SETTINGS_HEADER_TABLE } from '@/constants/headersTable/settingsHeaderTable'
import { FILTER_RULES } from '@/constants/filterRules'
import { viewPorts } from '@/constants/viewPorts'
import { XLARGE_ZOOM_SIZE } from '@/constants/zoomSize'

export const useDetailCallFlowsStore = defineStore('detailCallFlows', {
    state: () => ({
        loading: false,
        updateLoading: false,
        loadingAvailableDestinations: false,
        loadingAvailableGroupDestinations: false,

        formData: {
            blocks: [],
        },
        blocks: [],

        selectedPath: null,
        selectedComponent: null,

        availableDestinations: [],
        availableDestinationsFilters: [
            FILTER_STATUS,
        ],
        availableDestinationsSelectedFilters: [],
        availableDestinationsFiltersForSend: {},

        availableGroupDestinations: [],
        availableGroupsFilters: [
            FILTER_STATUS,
        ],
        availableGroupsSelectedFilters: [],
        availableGroupsFiltersForSend: {},

        enabledFullScreen: false,
        zoomLevel: XLARGE_ZOOM_SIZE,
        canvasX: 0,
        canvasY: 0,
        containerLeft: null,
        containerTop: null,
        containerHeight: 0,
        containerWidth: 0,
        startOffsetLeft: 100,

        isDraggingNewElement: false,
    }),

    getters: {
        availableDestinationsControlsParams(state) {
            return {
                filters: state.availableDestinationsFilters.map((filter) => ({
                    ...filter,
                    rule: null,
                    value: null,
                })),
                sorting: [],
            }
        },

        availableGroupsControlsParams(state) {
            return {
                filters: state.availableGroupsFilters.map((filter) => ({
                    ...filter,
                    rule: null,
                    value: null,
                })),
                sorting: [],
            }
        },

        localAvailableDestinations(state) {
            return localFilterItems(state.availableDestinations, state.availableDestinationsFiltersForSend)
        },

        localAvailableGroups(state) {
            return localFilterItems(state.availableGroupDestinations, state.availableGroupsFiltersForSend)
        },

        isModify({ blocks, formData }) {
            return !checkEqualsArrays(blocks, formData.blocks)
        },

        lineBlocks({ blocks = [] }) {
            const outputs = blocks.flatMap((block) => {
                const items = []
                Object.entries(block.outputs).forEach((output) => {
                    items.push([...output, block.slug])
                })
                return items
            })

            return outputs.filter(([_, value]) => value)
                .map(([key, slug, blockSlug]) => ({ key, slug, blockSlug }))
        },

        blockWithoutOutputs() {
            return this.blocks.some((block) => block.type_id !== 1 && Object.values(block.outputs).some((value) => value))
        },

        blockAllValid({ blocks }) {
            return !blocks.find((block) => !block.config.valid)
        },

        findOrphanBlocks({ blocks }) {
            const outputSlugs = new Set()

            blocks.forEach((block) => {
                Object.values(block.outputs).forEach((slug) => {
                    outputSlugs.add(slug)
                })
            })

            return blocks.filter((block) => block.type_id !== INCOMING_CALL_COMPONENT.id && !outputSlugs.has(block.slug))
        },

        offsetByZoomX() {
            // Рассчитываем смещение по оси X, которое возникает из-за масштабирования контейнера (zoom level)
            return ((this.containerWidth - (this.containerWidth * this.zoomLevel)) / 2) / this.zoomLevel
        },

        offsetByZoomY() {
            // Вычисляем смещение по оси Y с учетом уровня масштабирования (zoom level)
            return ((this.containerHeight - (this.containerHeight * this.zoomLevel)) / 2) / this.zoomLevel
        },
    },

    actions: {
        resetSortFilterAvailableDestinations() {
            this.availableDestinationsSelectedFilters = []
            this.availableDestinationsFiltersForSend = {}

            this.saveFiltersSortingAvailableDestinationsInLocalStorage()
        },

        resetSortFilterAvailableGroups() {
            this.availableGroupsSelectedFilters = []
            this.availableGroupsFiltersForSend = {}

            this.saveFiltersSortingAvailableGroupsInLocalStorage()
        },

        setFiltersSortingAvailableDestinations() {
            this.preparationFiltersAvailableDestinationsForSet()
            this.saveFiltersSortingAvailableDestinationsInLocalStorage()
        },

        setFiltersSortingAvailableGroups() {
            this.preparationFiltersAvailableGroupsForSet()

            this.saveFiltersSortingAvailableGroupsInLocalStorage()
        },

        saveFiltersSortingAvailableDestinationsInLocalStorage() {
            setFilterInLocalStorageByWorkspaceId(this.availableDestinationsFiltersForSend, `${LOCAL_FILTER_SHORT_NAME[SETTINGS_HEADER_TABLE.callFlowAvailableDestinations]}.${this.slug}`)
        },

        saveFiltersSortingAvailableGroupsInLocalStorage() {
            setFilterInLocalStorageByWorkspaceId(this.availableGroupsFiltersForSend, `${LOCAL_FILTER_SHORT_NAME[SETTINGS_HEADER_TABLE.callFlowAvailableGroups]}.${this.slug}`)
        },

        getQueryLocalStorageAvailableDestinations() {
            const value = getClearQueryLocalStorage()

            if (value) {
                this.getLocalStorageFilterAvailableDestinations()
                this.updateFilterAvailableDestinationsAfterQueryLocalStorage(this.availableDestinationsFiltersForSend)
            }
        },

        getQueryLocalStorageAvailableGroups() {
            const value = getClearQueryLocalStorage()

            if (value) {
                this.getLocalStorageFilterAvailableGroups()
                this.updateFilterAvailableGroupsAfterQueryLocalStorage(this.availableGroupsFiltersForSend)
            }
        },

        updateFilterAvailableDestinationsAfterQueryLocalStorage(savedFilter) {
            const sortFilterTableStore = useSortFilterTableStore()

            const filters = []

            Object.keys(savedFilter).forEach((key) => {
                const findItem = this.availableDestinationsFilters.find((el) => el.key === key)

                if (!findItem) {
                    return
                }

                const newItem = {
                    ...findItem,
                    value: savedFilter[key].value,
                    rule: FILTER_RULES[findItem.type].find((rule) => rule.key === savedFilter[key].rule),
                }

                filters.push(newItem)
            })

            if (!filters.length) {
                return
            }

            this.availableDestinationsSelectedFilters = filters

            if (viewPorts.mob <= window.innerWidth) {
                sortFilterTableStore.changeIndexOpen(SETTINGS_HEADER_TABLE.callFlowAvailableDestinations, -2)
                sortFilterTableStore.openCloseFilter(SETTINGS_HEADER_TABLE.callFlowAvailableDestinations)
            }
        },

        updateFilterAvailableGroupsAfterQueryLocalStorage(savedFilter) {
            const sortFilterTableStore = useSortFilterTableStore()

            const filters = []

            Object.keys(savedFilter).forEach((key) => {
                const findItem = this.availableGroupsFilters.find((el) => el.key === key)

                if (!findItem) {
                    return
                }

                const newItem = {
                    ...findItem,
                    value: savedFilter[key].value,
                    rule: FILTER_RULES[findItem.type].find((rule) => rule.key === savedFilter[key].rule),
                }

                filters.push(newItem)
            })

            if (!filters.length) {
                return
            }

            this.availableGroupsSelectedFilters = filters

            if (viewPorts.mob <= window.innerWidth) {
                sortFilterTableStore.changeIndexOpen(SETTINGS_HEADER_TABLE.callFlowAvailableGroups, -2)
                sortFilterTableStore.openCloseFilter(SETTINGS_HEADER_TABLE.callFlowAvailableGroups)
            }
        },

        getLocalStorageFilterAvailableDestinations() {
            const savedFilter = getFilterLocalStorageByWorkspaceId(`${LOCAL_FILTER_SHORT_NAME[SETTINGS_HEADER_TABLE.callFlowAvailableDestinations]}.${this.slug}`)

            if (!savedFilter && !Object.keys(savedFilter).length) {
                return
            }

            this.availableDestinationsFiltersForSend = savedFilter
        },

        getLocalStorageFilterAvailableGroups() {
            const savedFilter = getFilterLocalStorageByWorkspaceId(`${LOCAL_FILTER_SHORT_NAME[SETTINGS_HEADER_TABLE.callFlowAvailableGroups]}.${this.slug}`)

            if (!savedFilter && !Object.keys(savedFilter).length) {
                return
            }

            this.availableGroupsFiltersForSend = savedFilter
        },

        preparationFiltersAvailableDestinationsForSet() {
            const newFilter = createFiltersForSend(this.availableDestinationsSelectedFilters, true)

            if (!checkEqualsFilter(newFilter, this.availableDestinationsFiltersForSend)) {
                this.availableDestinationsFiltersForSend = newFilter
            }
        },

        preparationFiltersAvailableGroupsForSet() {
            const newFilter = createFiltersForSend(this.availableGroupsSelectedFilters, true)

            if (!checkEqualsFilter(newFilter, this.availableGroupsFiltersForSend)) {
                this.availableGroupsFiltersForSend = newFilter
            }
        },

        updateAvailableDestinationsSelectedFilters(payload) {
            this.availableDestinationsSelectedFilters = payload

            this.setFiltersSortingAvailableDestinations()
        },

        updateAvailableGroupsSelectedFilters(payload) {
            this.availableGroupsSelectedFilters = payload

            this.setFiltersSortingAvailableGroups()
        },

        updateDraggingNewElement(isDraggingNewElement) {
            this.isDraggingNewElement = isDraggingNewElement
        },

        updateFullScreen(enabledFullScreen) {
            this.enabledFullScreen = enabledFullScreen
        },

        updateZoomLevel(zoomLevel) {
            this.zoomLevel = zoomLevel
        },

        updateCanvasX(canvasX) {
            this.canvasX = canvasX
        },

        updateCanvasY(canvasY) {
            this.canvasY = canvasY
        },

        updateContainerLeft(containerLeft) {
            this.containerLeft = containerLeft
        },

        updateContainerTop(containerTop) {
            this.containerTop = containerTop
        },

        updateContainerHeight(containerHeight) {
            this.containerHeight = containerHeight
        },

        updateContainerWidth(containerWidth) {
            this.containerWidth = containerWidth
        },

        calculateCanvasX(blockX) {
            // Возвращаем итоговое значение canvasX, учитывая начальное смещение и смещение, вызванное масштабированием
            return -blockX - this.offsetByZoomX + this.startOffsetLeft
        },

        calculateCanvasY(blockY) {
            // Вычисляем верхнюю границу (top) с учетом масштабирования и смещения
            const adjustedTop = -blockY - this.offsetByZoomY

            // Возвращаем итоговое значение canvasY, добавляя дополнительное смещение для центрирования элемента по вертикали
            return adjustedTop + (this.containerHeight / (2 * this.zoomLevel)) - (INCOMING_CALL_COMPONENT.defaultHeight / 2)
        },

        goToIncomingCall() {
            const [incomingCall = {}] = this.blocks

            const { styles = {} } = incomingCall

            const { x, y } = styles

            // Обновляем координаты canvas для перемещения к элементу "входящего вызова"
            this.canvasY = this.calculateCanvasY(y)
            this.canvasX = this.calculateCanvasX(x)
        },

        setCallFlowSlug(slug) {
            this.slug = slug
        },

        addNewBlock(block) {
            block.styles.zIndex = this.maxZIndex() + 1
            this.blocks = [...this.blocks, block]
        },

        updateStylesBlock(index, x, y) {
            this.blocks[index].styles.x = x
            this.blocks[index].styles.y = y
        },

        setStylesNewElement(defaultHeight) {
            return {
                x: -this.canvasX + this.startOffsetLeft,
                y: -this.canvasY + ((this.containerHeight - defaultHeight) / 2),
            }
        },

        updateBlocksFirstLoader(blocks) {
            const [incomingCall = {}] = blocks
            const { styles } = incomingCall

            if (!styles.x || !styles.y) {
                incomingCall.styles = {
                    ...styles,
                    ...this.setStylesNewElement(INCOMING_CALL_COMPONENT.defaultHeight),
                }
            }

            blocks.forEach((block) => {
                if (block.type_id === CALL_FLOW_COMPONENTS.paths.id) {
                    block.paths.sort((a, b) => a.sort - b.sort)
                    block.outputs = { ...this.sortOutputsPath(block.paths, block.outputs), other: block.outputs.other }
                }
            })

            return blocks
        },

        sortOutputsPath(paths, outputs) {
            return paths.reduce((acc, curr) => ({
                ...acc,
                [`${curr.slug}_${curr.name}`]: outputs[`${curr.slug}_${curr.name}`] || null,
            }), {})
        },

        maxZIndex() {
            return this.blocks.reduce((max, item) => (item.styles.zIndex > max ? item.styles.zIndex : max), 10)
        },

        changeBlockZIndexToEnd(slug) {
            this.blocks = this.blocks.map((item) => {
                if (item.slug === slug) {
                    return {
                        ...item,
                        styles: {
                            ...item.styles,
                            zIndex: this.maxZIndex(),
                        },
                    }
                }

                return { ...item }
            })

            this.blocks = this.blocks.map((item) => {
                if (item.slug !== slug) {
                    return {
                        ...item,
                        styles: {
                            ...item.styles,
                            zIndex: item.styles.zIndex <= 10 ? 10 : item.styles.zIndex - 1,
                        },
                    }
                }

                return { ...item }
            })
        },

        onSelectComponent(item) {
            if (item) {
                this.selectedComponent = item
                this.onSelectPath({})
            } else {
                setTimeout(() => {
                    this.selectedComponent = item
                    this.onSelectPath({})
                }, 0)
            }
        },

        onSelectPath(item) {
            const { blockSlug } = item
            const foundBlock = this.blocks.find((item) => item.slug === blockSlug)

            if (foundBlock) {
                this.selectedPath = {
                    item,
                    activeComponent: foundBlock,
                }
            } else {
                this.selectedPath = null
            }
        },

        findValueInOutputs(searchValue) {
            return this.blocks.some((block) => Object.values(block.outputs).includes(searchValue))
        },

        goToDeletePath(selectedItem) {
            const { activeComponent = {}, item = {} } = selectedItem
            activeComponent.outputs[item.key] = null
        },

        addNewPath({ key, slug }, activeItem) {
            const foundBlock = this.blocks.find((block) => block.slug === slug)

            foundBlock.outputs[key] = activeItem.slug
        },

        goToDeleteComponent() {
            const detailCallFlowPathFilterStore = useDetailCallFlowsPathFilterStore()

            const { slug } = this.selectedComponent

            this.removeLine()

            const findIndex = this.blocks.findIndex((block) => block.slug === slug)

            if (this.selectedComponent.type_id === CALL_FLOW_COMPONENTS.callerInput.id && this.blocks[findIndex].config.parameter_name) {
                detailCallFlowPathFilterStore.deleteFilterCallFlowParameter(this.blocks[findIndex], findIndex)
                this.removeBlockPaths(this.blocks[findIndex].slug)
                this.checkValidBlocks()
            }

            this.blocks = this.blocks.filter((block) => block.slug !== slug)
            this.onSelectComponent(null)
        },

        checkPathOutputBlocks(paths) {
            this.blocks.forEach((block) => {
                if (block.type_id === CALL_FLOW_COMPONENTS.paths.id) {
                    paths.forEach((path) => {
                        const { [`${path.slug}_${path.name}`]: removed, ...rest } = block.outputs
                        block.outputs = rest
                    })
                }
            })
        },

        checkValidBlocks() {
            this.blocks.forEach((block) => {
                if (block.type_id === CALL_FLOW_COMPONENTS.paths.id) {
                    block.config.valid = !!block.paths.length
                }
            })
        },

        removeLine() {
            const { slug } = this.selectedComponent

            this.blocks.forEach((block) => {
                const { outputs = {} } = block

                Object.keys(outputs).forEach((key) => {
                    if (outputs[key] === slug) {
                        outputs[key] = null
                    }
                })
            })
        },

        removeBlockPaths(slug) {
            this.blocks.forEach((block) => {
                if (block.paths) {
                    block.paths.forEach((path) => {
                        path.groups.forEach((group) => {
                            group.conditions.forEach((condition, conditionIndex) => {
                                if (condition.parameter_id === slug) {
                                    group.conditions.splice(conditionIndex, 1)
                                }
                            })
                        })
                    })
                }
            })
        },

        updateBlockPaths(block) {
            const { slug, config } = block
            this.blocks.forEach((block) => {
                if (block.paths) {
                    block.paths.forEach((path) => {
                        path.groups.forEach((group) => {
                            group.conditions.forEach((condition, index) => {
                                if (condition.parameter_id === slug) {
                                    group.conditions[index] = { ...group.conditions[index], pretty_parameter: config.parameter_name, parameter: config.parameter_name }
                                }
                            })
                        })
                    })
                }
            })
        },

        goToUpdateComponentName(name) {
            const detailCallFlowPathFilterStore = useDetailCallFlowsPathFilterStore()
            const { slug } = this.selectedComponent

            const findIndex = this.blocks.findIndex((block) => block.slug === slug)

            if (findIndex !== -1) {
                this.blocks[findIndex].name = name
            }

            if (this.selectedComponent.type_id === CALL_FLOW_COMPONENTS.callerInput.id && this.blocks[findIndex].config.parameter_name) {
                detailCallFlowPathFilterStore.addOrUpdateCallerInput(this.blocks[findIndex])
                this.updateBlockPaths(this.blocks[findIndex])
            }
        },

        goToUpdateComponentConfig(config) {
            const detailCallFlowPathFilterStore = useDetailCallFlowsPathFilterStore()
            const { slug } = this.selectedComponent

            const findIndex = this.blocks.findIndex((block) => block.slug === slug)

            if (findIndex !== -1) {
                this.blocks[findIndex].config = config
                this.selectedComponent.config = config
            }

            if (this.selectedComponent.type_id === CALL_FLOW_COMPONENTS.callerInput.id && this.blocks[findIndex].config.parameter_name) {
                detailCallFlowPathFilterStore.addOrUpdateCallerInput(this.blocks[findIndex])
                this.updateBlockPaths(this.blocks[findIndex])
            }
        },

        goToUpdateComponentBlock(block) {
            const { slug } = this.selectedComponent

            const findIndex = this.blocks.findIndex((block) => block.slug === slug)

            if (findIndex !== -1) {
                this.blocks[findIndex] = { ...this.blocks[findIndex], ...block }
            }
        },

        goToUpdateComponentOutputs(outputs) {
            const { slug } = this.selectedComponent

            const findIndex = this.blocks.findIndex((block) => block.slug === slug)

            if (findIndex !== -1) {
                this.blocks[findIndex].outputs = outputs
                this.selectedComponent.outputs = outputs
            }
        },

        async goToLoadAvailableDestinations() {
            const permissionsStore = usePermissionsStore()

            if (!permissionsStore.permissions.destinations.view) {
                return
            }

            const defaultStore = useDefaultStore()
            this.loadingAvailableDestinations = true

            const { success, payload, message } = await getAllDestinations()

            if (success) {
                this.availableDestinations = payload
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.loadingAvailableDestinations = false
        },

        async goToLoadAvailableGroupDestinations() {
            const permissionsStore = usePermissionsStore()

            if (!permissionsStore.permissions.destinations.view) {
                return
            }

            const defaultStore = useDefaultStore()
            this.loadingAvailableGroupDestinations = true

            const { success, payload, message } = await getAllGroupDestinations()

            if (success) {
                this.availableGroupDestinations = payload
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.loadingAvailableGroupDestinations = false
        },

        foundCallFlowComponent(type_id) {
            return Object.values(CALL_FLOW_ALL_COMPONENTS).find((component) => component.id === type_id)
        },

        parsePayload(formData) {
            const newFormData = {
                ...formData,
                blocks: this.updateBlocksFirstLoader(formData.blocks),
            }

            this.formData = newFormData
            this.blocks = deepCopy(newFormData.blocks)
        },

        async goToUpdateCallFlow(formDataForUpdate, onlyName) {
            const defaultStore = useDefaultStore()

            this.updateLoading = true

            const { success, message, payload } = await updateCallFlow({
                formData: {
                    ...this.formData,
                    ...formDataForUpdate,
                },
                slug: this.slug,
            })

            if (success) {
                if (onlyName) {
                    this.formData.name = payload.name
                } else {
                    this.parsePayload(payload)
                }

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.updateLoading = false

            return { success }
        },

        async goToLoadDetailCallFlow() {
            const defaultStore = useDefaultStore()

            this.loading = true

            const { success, payload, message } = await getDetailCallFlow(this.slug)

            if (success) {
                this.parsePayload(payload)
                this.goToIncomingCall()
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.loading = false

            return { success }
        },
    },
})
