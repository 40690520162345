export const DETAIL_CAMPAIGN_ACCESS_HEADERS = [
    { name: 'User', value: 'email', disabled: true },
    { name: 'Role', value: 'role' },
    {
        name: 'Actions',
        value: 'actions',
        width: 80,
        align: 'center',
        disabled: true,
    },
]
