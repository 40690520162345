<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4063_199556)">
            <path d="M8.00065 14.6654C11.6825 14.6654 14.6673 11.6806 14.6673 7.9987C14.6673 4.3168 11.6825 1.33203 8.00065 1.33203C4.31875 1.33203 1.33398 4.3168 1.33398 7.9987C1.33398 11.6806 4.31875 14.6654 8.00065 14.6654Z" stroke="currentColor"/>
            <path d="M8 5L8 7.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
            <circle cx="8" cy="11" r="1" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_4063_199556">
                <rect width="16" height="16" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>