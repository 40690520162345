import { defineStore } from 'pinia'

import {
    attachNumbers,
    buyNumberWithAttach, detachNumbers,
    getAvailableNumbers,
    updateAttachedNumber,
} from '@/api/call-tracking/campaign/campaign-actions-routes'
import { updateNumbersStatus } from '@/api/number-routes'

import { useDefaultStore } from '@/store/defaultStore'
import { useSortFilterTableStore } from '@/store/sortFilterTableStore'
import { useDetailCampaignStore } from '@/store/call-tracking/campaigns/detailCampaignStore'
import { useDetailCampaignTrackingNumberTableStore } from '@/store/call-tracking/campaigns/detailCampaignTrackingNumberTable'

import {
    checkEqualsFilter,
    createFiltersForSend,
    localFilterItems,
} from '@/helpers/filters-sorting-header-table-helper'
import {
    getFilterLocalStorageByWorkspaceId,
    setFilterInLocalStorageByWorkspaceId,
} from '@/helpers/save-filter-local-storage-helper'
import { getClearQueryLocalStorage } from '@/helpers/remove-query-local-storage-helper'

import { SETTINGS_HEADER_TABLE } from '@/constants/headersTable/settingsHeaderTable'
import { viewPorts } from '@/constants/viewPorts'
import { FILTER_STATUS } from '@/constants/localFilters'
import { FILTER_RULES } from '@/constants/filterRules'
import { LOCAL_FILTER_SHORT_NAME } from '@/constants/localFilterShortName'
import { NUMBERABLE_TYPES } from '@/constants/numberableTypes'

export const useDetailCampaignTrackingNumberStore = defineStore('detailCampaignTrackingNumberStore', {
    state: () => ({
        loading: false,
        actionLoading: false,
        countersLoading: {},

        selectedNumberSlug: null,

        trackingNumberFormData: {},

        availableNumbers: [],
        availableNumbersFilters: [
            FILTER_STATUS,
        ],
        availableNumbersSelectedFilters: [],
        availableNumbersFiltersForSend: {},

        selectedTrackingNumbers: [],
    }),
    getters: {
        availableNumbersControlParams(state) {
            return {
                filters: state.availableNumbersFilters.map((filter) => ({
                    ...filter,
                    rule: null,
                    value: null,
                })),
                sorting: [],
            }
        },

        selectedNumber(state) {
            const detailCampaignTrackingNumberTableStore = useDetailCampaignTrackingNumberTableStore()
            return state.selectedNumberSlug ? detailCampaignTrackingNumberTableStore.numbers.find((n) => n.slug === state.selectedNumberSlug) : null
        },

        attachedVendor(state) {
            const detailCampaignTrackingNumberTableStore = useDetailCampaignTrackingNumberTableStore()
            return (state.selectedNumberSlug && this.selectedNumber?.vendor_id)
                ? detailCampaignTrackingNumberTableStore.attachedVendors.find((v) => this.selectedNumber.vendor_id === v.pivot.vendor_id && v.pivot.numberable_id === this.selectedNumber.id && v.pivot.numberable_type === NUMBERABLE_TYPES.STATIC_NUMBER)
                : null
        },

        localAvailableNumbers(state) {
            return localFilterItems(state.availableNumbers, state.availableNumbersFiltersForSend)
        },
    },
    actions: {
        resetSortFilter() {
            this.availableNumbersSelectedFilters = []
            this.availableNumbersFiltersForSend = {}

            this.saveFiltersSortingInLocalStorage()
        },

        saveFiltersSortingInLocalStorage() {
            const detailCampaignStore = useDetailCampaignStore()

            setFilterInLocalStorageByWorkspaceId(this.availableNumbersFiltersForSend, `${LOCAL_FILTER_SHORT_NAME[SETTINGS_HEADER_TABLE.campaignAvailableTrackingNumber]}.${detailCampaignStore.id}`)
        },

        updateFilterAfterQueryLocalStorage(savedFilter) {
            const sortFilterTableStore = useSortFilterTableStore()

            const filters = []

            Object.keys(savedFilter).forEach((key) => {
                const findItem = this.availableNumbersFilters.find((el) => el.key === key)

                if (!findItem) {
                    return
                }

                const newItem = {
                    ...findItem,
                    value: savedFilter[key].value,
                    rule: FILTER_RULES[findItem.type].find((rule) => rule.key === savedFilter[key].rule),
                }

                filters.push(newItem)
            })

            if (!filters.length) {
                return
            }

            this.availableNumbersSelectedFilters = filters

            if (viewPorts.mob <= window.innerWidth) {
                sortFilterTableStore.changeIndexOpen(SETTINGS_HEADER_TABLE.campaignAvailableTrackingNumber, -2)
                sortFilterTableStore.openCloseFilter(SETTINGS_HEADER_TABLE.campaignAvailableTrackingNumber)
            }
        },

        getQueryLocalStorage() {
            const value = getClearQueryLocalStorage()

            if (value) {
                this.getLocalStorageFilter()
                this.updateFilterAfterQueryLocalStorage(this.availableNumbersFiltersForSend)
            }
        },

        getLocalStorageFilter() {
            const detailCampaignStore = useDetailCampaignStore()
            const savedFilter = getFilterLocalStorageByWorkspaceId(`${LOCAL_FILTER_SHORT_NAME[SETTINGS_HEADER_TABLE.campaignAvailableTrackingNumber]}.${detailCampaignStore.id}`)

            if (!savedFilter && !Object.keys(savedFilter).length) {
                return
            }

            this.availableNumbersFiltersForSend = savedFilter
        },

        setFiltersSorting() {
            this.preparationFiltersForSet()
            this.saveFiltersSortingInLocalStorage()
        },

        preparationFiltersForSet() {
            const newFilter = createFiltersForSend(this.availableNumbersSelectedFilters, true)

            if (!checkEqualsFilter(newFilter, this.availableNumbersFiltersForSend)) {
                this.availableNumbersFiltersForSend = newFilter
            }
        },

        updateSelectedFilters(payload) {
            this.selectedTrackingNumbers = []

            this.availableNumbersSelectedFilters = payload

            this.setFiltersSorting()
        },

        setTrackingNumber(trackingNumber) {
            const detailCampaignTrackingNumberTableStore = useDetailCampaignTrackingNumberTableStore()
            this.selectedNumberSlug = trackingNumber.slug

            const {
                slug, name, traffic_source, slave_pool, conversion, vendor, cap_enabled, cap_on_id, global_cap, monthly_cap, daily_cap, hourly_cap, max_concurrency_enabled, max_concurrency, facebook_ads_event_id,
            } = trackingNumber

            this.trackingNumberFormData.number = slug
            this.trackingNumberFormData.name = name
            this.trackingNumberFormData.traffic_source = traffic_source ? traffic_source.slug : null
            this.trackingNumberFormData.vendor = vendor ? vendor.slug : null
            this.trackingNumberFormData.number_pool = slave_pool ? slave_pool.slug : null
            this.trackingNumberFormData.conversion = conversion ? conversion.slug : null

            this.trackingNumberFormData.cap_enabled = cap_enabled
            this.trackingNumberFormData.cap_on_id = cap_on_id
            this.trackingNumberFormData.global_cap = global_cap
            this.trackingNumberFormData.monthly_cap = monthly_cap
            this.trackingNumberFormData.daily_cap = daily_cap
            this.trackingNumberFormData.hourly_cap = hourly_cap

            this.trackingNumberFormData.max_concurrency_enabled = max_concurrency_enabled
            this.trackingNumberFormData.max_concurrency = max_concurrency

            this.trackingNumberFormData.facebook_ads_event_id = facebook_ads_event_id || undefined

            this.trackingNumberFormData.payout = null
            this.trackingNumberFormData.convert_on_id = null
            this.trackingNumberFormData.duplicate_id = null
            this.trackingNumberFormData.length = null
            this.trackingNumberFormData.time_limit = null

            const findAttachedVendor = vendor ? detailCampaignTrackingNumberTableStore.attachedVendors.find((v) => v.slug === vendor.slug) : null

            if (findAttachedVendor) {
                const settings = findAttachedVendor.pivot

                this.trackingNumberFormData.payout = settings.payout
                this.trackingNumberFormData.convert_on_id = settings.convert_on_id
                this.trackingNumberFormData.duplicate_id = settings.duplicate_id
                this.trackingNumberFormData.length = settings.length
                this.trackingNumberFormData.time_limit = settings.time_limit
            }
        },

        async goToBuyNumberWithAttach({ formData, slug }) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, payload, message } = await buyNumberWithAttach({ formData, slug })

            if (success) {
                defaultStore.setSuccessMessage({ message })

                this.setAttachedNumbersAndVendors(payload)
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success, payload }
        },

        async goToAttachNumbersToCampaign({ numbers = [], slug }) {
            const defaultStore = useDefaultStore()

            if (!numbers.length) {
                return
            }

            this.actionLoading = true

            const { success, payload, message } = await attachNumbers({
                formData: { number_slugs: numbers.map((n) => n.slug) },
                slug,
            })

            if (success) {
                this.setAttachedNumbersAndVendors(payload)
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        async goToLoadAvailableNumbers(slug, withoutLoading = false) {
            const defaultStore = useDefaultStore()

            this.loading = !withoutLoading

            const { success, payload, message } = await getAvailableNumbers(slug)

            if (success) {
                this.availableNumbers = payload
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.loading = false
        },

        async goToUpdateCounterTrackingNumber({ formData, counterName, campaignSlug }) {
            this.setTrackingNumber(formData)

            const counterLoadingKey = `${formData.slug}-${counterName}`

            this.changeCountersLoading({ counterLoadingKey })

            await this.goToUpdateTrackingNumber({
                max_concurrency: formData.max_concurrency,
            }, campaignSlug, counterLoadingKey)
        },

        changeCountersLoading({ counterLoadingKey, value = true }) {
            this.countersLoading[counterLoadingKey] = value
        },

        async goToUpdateTrackingNumber(formData, slug, counterLoadingKey = null) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const formDataForSend = {
                ...this.trackingNumberFormData,
                ...formData,
            }

            const { success, payload, message } = await updateAttachedNumber({
                formData: formDataForSend,
                slug,
            })

            if (success) {
                this.trackingNumberFormData = formDataForSend

                defaultStore.setSuccessMessage({ message })

                this.setAttachedNumbersAndVendors(payload)
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            if (counterLoadingKey) {
                this.changeCountersLoading({ counterLoadingKey, value: false })
            }

            return { success }
        },

        setAttachedNumbersAndVendors({ numbers = [], vendors = [], phone_number_pools = [] }) {
            const detailCampaignTrackingNumberTableStore = useDetailCampaignTrackingNumberTableStore()

            detailCampaignTrackingNumberTableStore.setVendors(vendors)

            const parseNumbers = detailCampaignTrackingNumberTableStore.parseNumbers({ numbers })
            const parseNumberPools = detailCampaignTrackingNumberTableStore.parseNumberPools({ phone_number_pools })

            detailCampaignTrackingNumberTableStore.setNumbers(parseNumbers)
            detailCampaignTrackingNumberTableStore.setNumberPools(parseNumberPools)
        },

        async goToDetachTrackingNumber({ trackingNumber = null, trackingNumbers = [], slug }) {
            const detailCampaignTrackingNumberTableStore = useDetailCampaignTrackingNumberTableStore()
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const formData = {}

            if (trackingNumber) {
                formData.number_slugs = [trackingNumber.slug]
            } else {
                formData.number_slugs = trackingNumbers.map(({ slug }) => slug)
            }

            const { success, message } = await detachNumbers({ data: formData, slug })

            if (success) {
                detailCampaignTrackingNumberTableStore._removeTrackingNumbersAfterDelete(formData.number_slugs)

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        async goToUpdateTrackingNumberStatus({ slugs, status_id }) {
            const detailCampaignTrackingNumberTableStore = useDetailCampaignTrackingNumberTableStore()
            const defaultStore = useDefaultStore()
            this.actionLoading = true

            const { success, payload, message } = await updateNumbersStatus({ slugs, status_id })

            if (success) {
                detailCampaignTrackingNumberTableStore.updateTrackingNumberStatus(payload)
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        changeSelectedTrackingNumbers(newSelectedItems) {
            this.selectedTrackingNumbers = newSelectedItems
        },

        clearSelectedTrackingNumbers() {
            this.selectedTrackingNumbers = []
        },
    },
})
