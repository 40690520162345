function createScheduleObject(schedules, simple, simpleSchedule) {
    if (simple) {
        return schedules.map((schedule) => ({
            isClosed: !simpleSchedule[0].active,
            openTime: { hour: simpleSchedule[0].range.start[0], minute: simpleSchedule[0].range.start[1] },
            closeTime: { hour: simpleSchedule[0].range.end[0], minute: simpleSchedule[0].range.end[1] },
            day: schedule.day,
        }))
    }

    return schedules.map((schedule) => ({
        isClosed: !schedule.active,
        openTime: { hour: schedule.range.start[0], minute: schedule.range.start[1] },
        closeTime: { hour: schedule.range.end[0], minute: schedule.range.end[1] },
        day: schedule.day,
    }))
}

function reverseTransformation(transformedSchedules) {
    return transformedSchedules.map((schedule) => ({
        active: !schedule.isClosed,
        range: {
            start: [schedule.openTime.hour, schedule.openTime.minute],
            end: [schedule.closeTime.hour, schedule.closeTime.minute],
        },
        day: schedule.day,
    }))
}

export {
    createScheduleObject,
    reverseTransformation,
}
