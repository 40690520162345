import axios from '@/plugins/axios'

import { useAuthorizationStore } from '@/store/authorizationStore'

const getAccountId = () => useAuthorizationStore().getAccountSlug

const checkWhiteLabel = () => axios.get('whitelabel/check')
const getWhiteLabel = () => axios.get(`${getAccountId()}/white-label`)
const updateWhiteLabel = (formData) => axios.patch(`${getAccountId()}/white-label`, formData)
const deleteWhiteLabel = () => axios.delete(`${getAccountId()}/white-label`)
const issueCertificateWhiteLabel = () => axios.post(`${getAccountId()}/white-label/certificate`)
const verifyCertificateIssued = () => axios.get(`${getAccountId()}/white-label/certificate/is-issued`)
const verifyCertificateSet = () => axios.get(`${getAccountId()}/white-label/certificate/is-set`)
const createDistribution = () => axios.post(`${getAccountId()}/white-label/distribution`)
const verifyDistributionSet = () => axios.get(`${getAccountId()}/white-label/distribution/is-set`)
const getStatusWhiteLabel = () => axios.get(`${getAccountId()}/white-label/status`)

export {
    checkWhiteLabel,
    getWhiteLabel,
    updateWhiteLabel,
    deleteWhiteLabel,
    issueCertificateWhiteLabel,
    verifyCertificateIssued,
    verifyCertificateSet,
    createDistribution,
    verifyDistributionSet,
    getStatusWhiteLabel,
}
