import { viewPorts } from '@/constants/viewPorts'

const CAMPAIGN_TRACKING_NUMBERS_HEADERS_TABLE = [
    { name: 'Number', value: 'number', disabled: true },
    {
        name: 'Type', value: 'type', align: 'center', activeDefault: true,
    },
    { name: 'Name', value: 'name', disabled: true },
    { name: 'Vendor', value: 'vendor' },
    { name: 'Payout', value: 'payout' },
    {
        name: 'Live',
        value: 'live',
        align: 'center',
        activeDefault: false,
    },
    {
        name: 'Hourly',
        value: 'hourly_revenue_cap',
        align: 'center',
        activeDefault: false,
    },
    {
        name: 'Daily',
        value: 'daily_revenue_cap',
        align: 'center',
        activeDefault: false,
    },
    {
        name: 'Monthly',
        value: 'monthly_revenue_cap',
        align: 'center',
        activeDefault: false,
    },
    {
        name: 'Global',
        value: 'global_revenue_cap',
        align: 'center',
        activeDefault: false,
    },
    { name: 'Status', value: 'status', align: 'center' },
]

const CAMPAIGN_TRACKING_NUMBERS_HEADERS_TABLE_WITH_ACTIONS = [
    ...CAMPAIGN_TRACKING_NUMBERS_HEADERS_TABLE,
    {
        name: 'Actions', value: 'actions', width: 90, align: 'center', disabled: true, sticky: true, right: viewPorts.mb >= window.innerWidth ? -1 : 0,
    },
]

export {
    CAMPAIGN_TRACKING_NUMBERS_HEADERS_TABLE, CAMPAIGN_TRACKING_NUMBERS_HEADERS_TABLE_WITH_ACTIONS,
}
