const REDIRECT_PART_PATH_PAGE = {
    campaigns: 'campaigns',
    trafficSources: 'traffic-sources',
    trackingNumbers: 'tracking-numbers',
    numbersPool: 'pools',
    integration: 'integration',
}

export {
    REDIRECT_PART_PATH_PAGE,
}
