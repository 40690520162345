const ACTIONS = {
    name: 'Actions', value: 'actions', width: 114, align: 'center', disabled: true,
}

const TRAFFIC_SOURCES_PARAMETERS_HEADERS = [
    { name: 'Name', value: 'name', disabled: true },
    { name: 'Parameter', value: 'url_param' },
    {
        name: 'Status', value: 'active', width: 100, align: 'center',
    },
]

const TRAFFIC_SOURCES_PARAMETERS_HEADERS_WITH_ACTIONS = [
    { name: 'Name', value: 'name', disabled: true },
    { name: 'Parameter', value: 'url_param' },
    {
        name: 'Status', value: 'active', width: 100, align: 'center',
    },
    ACTIONS,
]

export {
    TRAFFIC_SOURCES_PARAMETERS_HEADERS, TRAFFIC_SOURCES_PARAMETERS_HEADERS_WITH_ACTIONS,
}
