import axios from '@/plugins/axios'

import { useAuthorizationStore } from '@/store/authorizationStore'

const getWorkspaceId = () => useAuthorizationStore().getWorkspaceSlug

const getAllCallFlows = () => axios.get(`${getWorkspaceId()}/ivr/all`)

const getCallFlowFiltersAndSorting = () => axios.get(`${getWorkspaceId()}/ivr/filters-and-sorting`)
const getCallFlows = (params) => axios.get(`${getWorkspaceId()}/ivr/paginated`, { params })

const getDetailCallFlow = (slug) => axios.get(`${getWorkspaceId()}/ivr/${slug}`)
const createCallFlow = (formData) => axios.post(`${getWorkspaceId()}/ivr`, formData)
const updateCallFlow = ({ formData, slug }) => axios.patch(`${getWorkspaceId()}/ivr/${slug}`, formData)
const deleteCallFlow = (data) => axios.delete(`${getWorkspaceId()}/ivr`, { data })
const cloneCallFlow = ({ formData, slug }) => axios.post(`${getWorkspaceId()}/ivr/${slug}`, formData)

const getFiltersPath = (slug) => axios.get(`${getWorkspaceId()}/ivr/${slug}/paths/filters`)

const getCallFlowAvailableDestinations = () => axios.get(`${getWorkspaceId()}/destinations/all`)
const getCallFlowAvailableGroupDestinations = () => axios.get(`${getWorkspaceId()}/destination-groups/all`)

export {
    getAllCallFlows,
    getCallFlowFiltersAndSorting,
    getCallFlows,
    getDetailCallFlow,
    createCallFlow,
    updateCallFlow,
    deleteCallFlow,
    cloneCallFlow,

    getCallFlowAvailableDestinations,
    getCallFlowAvailableGroupDestinations,

    getFiltersPath,
}
