<template>
    <component
        :is="layout"
        ref="appRef"
    />

    <notifications
        class="min-md:mr-5 min-md:mt-5 z-110 cursor-pointer"
        :max="3"
        width="90%"
        :position="positionNotification"
    />
</template>

<script setup>
import {
    onMounted, computed, markRaw, watch, onUnmounted, ref, provide,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useNotification } from '@kyvg/vue3-notification'
import { useResizeObserver } from '@vueuse/core'

import MainLayout from '@/layouts/MainLayout.vue'
import EmptyLayout from '@/layouts/EmptyLayout.vue'
import WorkspaceLayout from '@/layouts/WorkspaceLayout.vue'

import { stopPropagationForNotification } from '@/helpers/app-helper'
import { setTheme, getCurrentTheme } from '@/helpers/theme-local-storage-helper'
import { loginCarrotquest, setUserDataToCarrotquest } from '@/helpers/carrot-quest-helper'
import { getAccessToken } from '@/helpers/auth-helper'

import { useDefaultStore } from '@/store/defaultStore'
import { useAppStore } from '@/store/appStore'
import { useAuthorizationStore } from '@/store/authorizationStore'

import { ROUTES } from '@/constants/routes'
import { viewPorts } from '@/constants/viewPorts'

const defaultStore = useDefaultStore()
const appStore = useAppStore()
const authorizationStore = useAuthorizationStore()

const route = useRoute()
const router = useRouter()

const { notify } = useNotification()

const currentViewSize = ref(0)

const appRef = ref(null)

const layout = computed(() => {
    const layout = `${route.meta.layout || 'empty'}-layout`

    const lookup = {
        'main-layout': MainLayout,
        'empty-layout': EmptyLayout,
        'workspace-layout': WorkspaceLayout,
    }

    return lookup.hasOwnProperty(layout) ? markRaw(lookup[layout]) : null
})

const isMbView = computed(() => viewPorts.mb >= currentViewSize.value)

const positionNotification = computed(() => (isMbView.value ? 'bottom center' : 'top right'))

provide('currentViewSize', currentViewSize)

onMounted(() => {
    appStore.loadCountries()
    appStore.loadTimezones()

    stopPropagationForNotification()

    appStore.changeTimerId(setTheme())

    checkAccessToken()
})

useResizeObserver(appRef, (entries) => {
    const [entry] = entries
    const { contentRect } = entry
    const { width } = contentRect
    const name = Object.keys(viewPorts).find((name) => viewPorts[name] >= width)
    currentViewSize.value = viewPorts[name]
})

function checkAccessToken() {
    if (!getAccessToken()) {
        setUserDataToCarrotquest([
            { op: 'update_or_create', key: 'Logged in', value: false },
        ])
    }
}

watch(() => defaultStore.message, (text) => {
    if (text) {
        notify({ type: defaultStore.type, text, duration: defaultStore.duration })
        defaultStore.clearMessage()
    }
})

watch(() => authorizationStore.role, (role) => {
    if (!role) {
        router.push({ name: ROUTES.authLogin })
    }
})

watch(() => authorizationStore.carrotHash, (hash) => {
    if (!hash) {
        return
    }

    loginCarrotquest({ userId: authorizationStore.getUserId, hash })

    if (authorizationStore.isOwner) {
        setUserDataToCarrotquest([
            { op: 'update_or_create', key: '$email', value: authorizationStore.user.email },
            { op: 'update_or_create', key: '$phone', value: authorizationStore.user.phone },
            { op: 'update_or_create', key: 'theme', value: getCurrentTheme() },
            { op: 'update_or_create', key: 'Registered', value: true },
            { op: 'update_or_create', key: 'Logged in', value: true },
            { op: 'update_or_create', key: 'Verified', value: authorizationStore.account.verified },
            { op: 'update_or_create', key: 'User type', value: 'Account Owner' },
            { op: 'update_or_create', key: 'Trial', value: authorizationStore.account.trial },
            { op: 'update_or_create', key: 'Trial ended', value: authorizationStore.account.trial_ended },
            { op: 'update_or_create', key: 'Trial days', value: authorizationStore.account.trial_days },
        ])
    } else {
        setUserDataToCarrotquest([
            { op: 'update_or_create', key: '$email', value: authorizationStore.user.email },
            { op: 'update_or_create', key: '$phone', value: authorizationStore.user.phone },
            { op: 'update_or_create', key: 'theme', value: getCurrentTheme() },
            { op: 'update_or_create', key: 'Registered', value: true },
            { op: 'update_or_create', key: 'Logged in', value: true },
            { op: 'update_or_create', key: 'User type', value: 'Invited' },
        ])
    }
})

onUnmounted(() => {
    clearTimeout(appStore.themeTimerId)
})
</script>
