import { formatDateByTzOffset, formatYearMonthDay } from '@/helpers/date-helper'
import { getCurrentTimezone } from '@/helpers/workspace-timezone-helper'

import { useAppStore } from '@/store/appStore'
import { useAuthorizationStore } from '@/store/authorizationStore'

import { SETTINGS_HEADER_TABLE } from '@/constants/headersTable/settingsHeaderTable'

function _timezoneOffset() {
    const appStore = useAppStore()
    const authorizationStore = useAuthorizationStore()

    const currentTimezone = getCurrentTimezone(SETTINGS_HEADER_TABLE.leads) || authorizationStore.getWorkspaceTimezoneId
    return appStore.getTimezoneOffsetById(currentTimezone)
}

function createQueryForGlobalSearch(date) {
    const dateByTimezone = formatDateByTzOffset(_timezoneOffset(), new Date(date))
    const dateForRange = formatYearMonthDay(dateByTimezone)

    return {
        range: [dateForRange, dateForRange],
        preset: 'customRange',
    }
}

export {
    createQueryForGlobalSearch,
}
