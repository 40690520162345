import axios from '@/plugins/axios'

import { useAuthorizationStore } from '@/store/authorizationStore'
import axiosExportApi from '@/plugins/axiosExport'

const getWorkspaceId = () => useAuthorizationStore().getWorkspaceSlug

const getLeads = (params) => axios.get(`${getWorkspaceId()}/leads/paginated`, { params })
const getDetailLead = (slug) => axios.get(`${getWorkspaceId()}/leads/${slug}`)
const deleteLeads = (data) => axios.delete(`${getWorkspaceId()}/leads`, { data })

const getLeadsFiltersAndSorting = () => axios.get(`${getWorkspaceId()}/leads/filters-and-sorting`)

const exportLeads = (params) => axiosExportApi.get(`export/${getWorkspaceId()}/leads`, { params, responseType: 'blob' })

export {
    getLeads,
    getDetailLead,
    deleteLeads,
    getLeadsFiltersAndSorting,
    exportLeads,
}
