import { createRouter, createWebHistory } from 'vue-router'

import { setTitle, handleAdminToken, checkToken } from '@/router/config/configRoutes'
import nextFactory from '@/router/middleware/nextFactory'

import { BASE_ROUTES } from '@/router/config/baseRoutes'

import { useAppStore } from '@/store/appStore'
import { useAuthorizationStore } from '@/store/authorizationStore'
import { usePermissionsStore } from '@/store/permissionsStore'
import { useWhiteLabelStore } from '@/store/call-tracking/whiteLabelStore'

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: BASE_ROUTES,
})

router.beforeEach(async (to, from, next) => {
    const authorizationStore = useAuthorizationStore()
    const permissionsStore = usePermissionsStore()
    const whiteLabelStore = useWhiteLabelStore()
    const appStore = useAppStore()

    appStore.setRouteLoading(true)
    setTitle(to, whiteLabelStore)

    await handleAdminToken(to.query)
    await whiteLabelStore.getCheckWhiteLabel()
    await checkToken(to, next, authorizationStore)

    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware]

        const context = {
            to,
            from,
            next,
            store: authorizationStore,
            permissionsStore,
            router,
        }

        const nextMiddleware = nextFactory(context, middleware, 1)

        return middleware[0]({ ...context, next: nextMiddleware })
            .then(() => next())
            .catch((error) => {
                next(error)
            })
    }

    return next()
})

router.onError((error, to) => {
    window.open(to.path, '_self')
})

router.beforeResolve(() => {
    const appStore = useAppStore()
    appStore.setRouteLoading(false)
})

export default router
