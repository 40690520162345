import { defineStore } from 'pinia'

import { checkPermission, checkSomePermission } from '@/helpers/permissions-helper'

import { PERMISSION_NAMES } from '@/constants/workspace/permissionSettings'

export const usePermissionsStore = defineStore('permissionsStore', {
    state: () => ({
        allPermissions: null,
    }),

    getters: {
        permissions(state) {
            return {
                reporting: {
                    view: checkPermission(state.allPermissions, PERMISSION_NAMES.view_reporting),
                    edit: checkPermission(state.allPermissions, PERMISSION_NAMES.edit_reporting),
                    listen: checkSomePermission(state.allPermissions, [PERMISSION_NAMES.listen_to_recordings, PERMISSION_NAMES.see_record]),
                    blockNumbers: checkSomePermission(state.allPermissions, [PERMISSION_NAMES.edit_blocked_numbers, PERMISSION_NAMES.block_numbers]),
                    download: checkSomePermission(state.allPermissions, [PERMISSION_NAMES.edit_reporting, PERMISSION_NAMES.download_reports]),
                    hangupCalls: checkSomePermission(state.allPermissions, [PERMISSION_NAMES.edit_reporting, PERMISSION_NAMES.hangup_calls]),
                },
                campaigns: {
                    view: checkPermission(state.allPermissions, PERMISSION_NAMES.view_campaigns),
                    edit: checkPermission(state.allPermissions, PERMISSION_NAMES.edit_campaigns),
                    delete: checkPermission(state.allPermissions, PERMISSION_NAMES.delete_campaigns),
                },
                callFlows: {
                    view: checkPermission(state.allPermissions, PERMISSION_NAMES.view_call_flows),
                    edit: checkPermission(state.allPermissions, PERMISSION_NAMES.edit_call_flows),
                    delete: checkPermission(state.allPermissions, PERMISSION_NAMES.delete_call_flows),
                },
                numbers: {
                    view: checkPermission(state.allPermissions, PERMISSION_NAMES.view_phone_numbers),
                    create: checkSomePermission(state.allPermissions, [PERMISSION_NAMES.edit_phone_numbers, PERMISSION_NAMES.create_numbers]),
                    edit: checkPermission(state.allPermissions, PERMISSION_NAMES.edit_phone_numbers),
                    delete: checkPermission(state.allPermissions, PERMISSION_NAMES.delete_phone_numbers),
                },
                numberPools: {
                    view: checkPermission(state.allPermissions, PERMISSION_NAMES.view_phone_number_pools),
                    edit: checkPermission(state.allPermissions, PERMISSION_NAMES.edit_phone_number_pools),
                    delete: checkPermission(state.allPermissions, PERMISSION_NAMES.delete_phone_number_pools),
                },
                destinations: {
                    view: checkPermission(state.allPermissions, PERMISSION_NAMES.view_destinations),
                    edit: checkPermission(state.allPermissions, PERMISSION_NAMES.edit_destinations),
                    editEndpoint: checkSomePermission(state.allPermissions, [PERMISSION_NAMES.edit_destinations, PERMISSION_NAMES.edit_endpoint]),
                    pause: checkSomePermission(state.allPermissions, [PERMISSION_NAMES.edit_destinations, PERMISSION_NAMES.pause_destinations]),
                    capacity: checkSomePermission(state.allPermissions, [PERMISSION_NAMES.edit_destinations, PERMISSION_NAMES.set_capacity]),
                    concurrency: checkSomePermission(state.allPermissions, [PERMISSION_NAMES.edit_destinations, PERMISSION_NAMES.set_concurrency]),
                    delete: checkPermission(state.allPermissions, PERMISSION_NAMES.delete_destinations),
                },
                groups: {
                    view: checkPermission(state.allPermissions, PERMISSION_NAMES.view_destination_groups),
                    edit: checkPermission(state.allPermissions, PERMISSION_NAMES.edit_destination_groups),
                    delete: checkPermission(state.allPermissions, PERMISSION_NAMES.delete_destination_groups),
                },
                buyers: {
                    view: checkPermission(state.allPermissions, PERMISSION_NAMES.view_buyers),
                    edit: checkPermission(state.allPermissions, PERMISSION_NAMES.edit_buyers),
                    delete: checkPermission(state.allPermissions, PERMISSION_NAMES.delete_buyers),
                },
                vendors: {
                    view: checkPermission(state.allPermissions, PERMISSION_NAMES.view_vendors),
                    edit: checkPermission(state.allPermissions, PERMISSION_NAMES.edit_vendors),
                    delete: checkPermission(state.allPermissions, PERMISSION_NAMES.delete_vendors),
                },
                trafficSources: {
                    view: checkPermission(state.allPermissions, PERMISSION_NAMES.view_traffic_sources),
                    edit: checkPermission(state.allPermissions, PERMISSION_NAMES.edit_traffic_sources),
                    delete: checkPermission(state.allPermissions, PERMISSION_NAMES.delete_traffic_sources),
                },
                integrations: {
                    view: checkPermission(state.allPermissions, PERMISSION_NAMES.view_integrations),
                    edit: checkPermission(state.allPermissions, PERMISSION_NAMES.edit_integrations),
                    delete: checkPermission(state.allPermissions, PERMISSION_NAMES.delete_integrations),
                },
                voipBlackLists: {
                    view: checkPermission(state.allPermissions, PERMISSION_NAMES.view_voip_black_lists),
                    edit: checkPermission(state.allPermissions, PERMISSION_NAMES.edit_voip_black_lists),
                    delete: checkPermission(state.allPermissions, PERMISSION_NAMES.delete_voip_black_lists),
                },
                leads: {
                    view: checkPermission(state.allPermissions, PERMISSION_NAMES.view_leads),
                    delete: checkPermission(state.allPermissions, PERMISSION_NAMES.delete_leads),
                },
                blockedNumbers: {
                    view: checkPermission(state.allPermissions, PERMISSION_NAMES.view_blocked_numbers),
                    edit: checkPermission(state.allPermissions, PERMISSION_NAMES.edit_blocked_numbers),
                    delete: checkPermission(state.allPermissions, PERMISSION_NAMES.delete_blocked_numbers),
                },
                customParameters: {
                    view: checkPermission(state.allPermissions, PERMISSION_NAMES.view_custom_parameters),
                    edit: checkPermission(state.allPermissions, PERMISSION_NAMES.edit_custom_parameters),
                    delete: checkPermission(state.allPermissions, PERMISSION_NAMES.delete_custom_parameters),
                },
            }
        },
    },

    actions: {
        setPermissions(payload) {
            this.allPermissions = payload.permissions
        },

        checkPermissionByName(permissionName) {
            return checkPermission(this.allPermissions, permissionName)
        },

        filterMenuByPermission(menuItems) {
            return menuItems
                .filter((item) => {
                    const hasPermission = item.permissions
                        ? item.permissions.some((permission) => checkPermission(this.allPermissions, permission))
                        : false

                    if (item.subMenu) {
                        item.subMenu = this.filterMenuByPermission(item.subMenu)
                    }

                    return hasPermission || (item.subMenu && item.subMenu.length > 0)
                })
        },
    },
})
