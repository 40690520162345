import { getAccessToken, setAccessToken } from '@/helpers/auth-helper'
import { convertCustomQueryAndAddParamsForFacebook } from '@/helpers/integration-hepler'

import { ROUTES } from '@/constants/routes'
import { INTEGRATIONS } from '@/constants/call-tracking/integrations/integrations'

function setTitle({ meta = {} }, whiteLabelStore) {
    const { title = 'Dialics' } = meta

    document.title = whiteLabelStore.whiteLabelIsEnabled ? whiteLabelStore.whiteLabelData.company_name || title : title
}

async function handleAdminToken({ adminToken }) {
    if (adminToken) {
        setAccessToken(adminToken)
    }
}

function checkFacebookAuth({ state, code }) {
    if (!state) {
        return { valid: false }
    }

    try {
        const decodedState = decodeURIComponent(state)
        const stateObject = JSON.parse(decodedState)

        if (stateObject.auth === 'authFacebook') {
            return {
                valid: true,
                code,
                ...stateObject,
                integration_type_id: INTEGRATIONS.facebook.id,
            }
        }
    } catch (error) {
    }

    return { valid: false }
}

function handleFacebookAuthorization(next, facebookAuthResult) {
    const path = convertCustomQueryAndAddParamsForFacebook(facebookAuthResult)
    return next(path)
}

async function handleAuthorization(to, next, authorizationStore) {
    const workspaceFromURL = to.params?.workspace ? to.params.workspace : null
    const accountFromURL = to.params?.account ? to.params.account : null

    await authorizationStore.getCheckWorkspace(workspaceFromURL)

    if (authorizationStore.workspace?.owner_blocked) {
        return next({ name: ROUTES.accountDisabled })
    }

    if (workspaceFromURL && authorizationStore.workspace?.slug && to.params.workspace !== authorizationStore.workspace.slug) {
        const params = { workspace: authorizationStore.workspace.slug }

        if (authorizationStore.isOwner) {
            params.account = authorizationStore.account?.slug || null
        }

        return next({ name: to.name, params })
    }

    if (accountFromURL && authorizationStore.account?.slug && to.params.account !== authorizationStore.account.slug) {
        return next({
            name: to.name,
            params: {
                account: authorizationStore.account.slug,
                workspace: workspaceFromURL,
            },
            query: to.query,
        })
    }
}

async function checkToken(to, next, authorizationStore) {
    if (!!getAccessToken() && !authorizationStore.role) {
        authorizationStore.$reset()
        await authorizationStore.getCheckUserAccess()

        const facebookAuthResult = checkFacebookAuth(to.query)

        if (facebookAuthResult.valid) {
            return handleFacebookAuthorization(next, facebookAuthResult)
        }

        return handleAuthorization(to, next, authorizationStore)
    }
}

export {
    setTitle,
    handleAdminToken,
    checkToken,
}
