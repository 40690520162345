export const LEADS_HEADERS = [
    {
        name: 'ID',
        value: 'id',
    },
    {
        name: 'Caller ID',
        value: 'callerId',
        sortSettings: {
            key: '',
            sortBy: 'byCallerId',
        },
        filterSettings: {},
        disabled: true,
    },
    {
        name: 'Status',
        value: 'status',
        align: 'center',
        sortSettings: {
            key: '',
            sortBy: 'byStatus',
        },
        filterSettings: {},
    },
    {
        name: 'Created at',
        value: 'created_at',
    },
    {
        name: 'First name',
        value: 'firstName',
        sortSettings: {
            key: '',
            sortBy: 'byFirstName',
        },
        filterSettings: {},
    },
    {
        name: 'Last name',
        value: 'lastName',
        sortSettings: {
            key: '',
            sortBy: 'byLastName',
        },
        filterSettings: {},
    },
    {
        name: 'Email',
        value: 'email',
        sortSettings: {
            key: '',
            sortBy: 'byEmail',
        },
        filterSettings: {},
    },
    {
        name: 'Campaign',
        value: 'campaign',
        sortSettings: {
            key: '',
            sortBy: 'byCampaign',
        },
        filterSettings: {},
    },
    {
        name: 'Vendor',
        value: 'vendor',
        sortSettings: {
            key: '',
            sortBy: 'byVendor',
        },
        filterSettings: {},
    },
    {
        name: 'Actions',
        value: 'actions',
        width: 114,
        align: 'center',
        disabled: true,
    },
]
