const ENDPOINT_TYPE = {
    number: 1,
    sip: 2,
}
const OPTIONS_TYPE = {
    static: 1,
    dynamic: 2,
}
const DESTINATION_TYPE = {
    default: 1,
    ping: 2,
}

export {
    ENDPOINT_TYPE, DESTINATION_TYPE, OPTIONS_TYPE,
}
