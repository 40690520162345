<template>
    <div class="min-w-6 min-h-6 max-w-6 max-h-6 flex items-center justify-center rounded-1.25 bg-blue-200 text-white text-xs text-medium uppercase">
        {{ defaultName }}
    </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
    name: {
        type: String,
        default: '',
    },
})

const defaultName = computed(() => {
    const arrayString = props.name.split(' ')
    const [first, second] = arrayString

    return props.name ? `${first[0]}${second ? second[0] : ''}` : ' '
})
</script>
