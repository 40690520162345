import SearchIcon from '@/components/icons/SearchIcon.vue'
import FeedbackIcon from '@/components/icons/navigations/FeedbackIcon.vue'
import HelpIcon from '@/components/icons/navigations/HelpIcon.vue'
import LastChanges from '@/components/icons/navigations/LastChanges.vue'
import SettingsIcon from '@/components/icons/SettingsIcon.vue'
import GettingStartIcon from '@/components/icons/GettingStartIcon.vue'

import { NAVIGATION_ACTIONS } from '@/constants/navigationActions'
import { ROUTES } from '@/constants/routes'

import { ROUTER_PARAMS_NAMES } from '@/constants/routerParamsNames'

const GETTING_START = {
    id: 3,
    name: 'Getting Started',
    routeName: ROUTES.gettingStart,
    paramsNames: [ROUTER_PARAMS_NAMES.workspace],
    icon: () => GettingStartIcon,
}

const TOP_MENU_ACCOUNT_FOR_OWNER = [
    {
        id: 1,
        action: NAVIGATION_ACTIONS.search,
        name: 'Search',
        tippy: 'Search',
        icon: () => SearchIcon,
    },
    {
        id: 2,
        name: 'Workspace Settings',
        routeName: ROUTES.manageWorkspace,
        paramsNames: [ROUTER_PARAMS_NAMES.account, ROUTER_PARAMS_NAMES.workspace],
        icon: () => SettingsIcon,
    },
]

const TOP_MENU_ACCOUNT_FOR_MANAGER = [
    {
        id: 1,
        action: NAVIGATION_ACTIONS.search,
        name: 'Search',
        tippy: 'Search',
        icon: () => SearchIcon,
    },
    GETTING_START,
]

const BOTTOM_MENU_ACCOUNT = [
    {
        id: 1,
        action: NAVIGATION_ACTIONS.feedback,
        name: 'Leave Feedback',
        tippy: 'Leave Feedback',
        icon: () => FeedbackIcon,
    },
    {
        id: 2,
        action: NAVIGATION_ACTIONS.last_changes,
        name: 'Latest Changes',
        tippy: 'Latest Changes',
        icon: () => LastChanges,
    },
    {
        id: 3,
        action: NAVIGATION_ACTIONS.help,
        name: 'Help Center',
        tippy: 'Help Center',
        icon: () => HelpIcon,
    },
]

export {
    GETTING_START,
    TOP_MENU_ACCOUNT_FOR_OWNER,
    TOP_MENU_ACCOUNT_FOR_MANAGER,
    BOTTOM_MENU_ACCOUNT,
}
