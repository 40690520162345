const CALL_FILTERS_PARAMETERS = {
    DEFAULT: 1,
    TRAFFIC_SOURCE: 2,
    CALL_FLOW_PARAMETERS: 3,
    CUSTOM_PARAMETERS: 4,
}

export {
    CALL_FILTERS_PARAMETERS,
}
