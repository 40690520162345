import axios from '@/plugins/axios'

import { useAuthorizationStore } from '@/store/authorizationStore'

const getWorkspaceId = () => useAuthorizationStore().getWorkspaceSlug

const getAllCampaigns = () => axios.get(`${getWorkspaceId()}/campaigns/all`)
const getCampaigns = (params) => axios.get(`${getWorkspaceId()}/campaigns/paginated`, { params })
const getCountersCampaign = (formData) => axios.post(`${getWorkspaceId()}/campaigns/counters`, formData)
const getDetailCampaign = (slug) => axios.get(`${getWorkspaceId()}/campaigns/${slug}`)
const createCampaign = (formData) => axios.post(`${getWorkspaceId()}/campaigns`, formData)
const cloneCampaign = ({ formData, slug }) => axios.post(`${getWorkspaceId()}/campaigns/${slug}`, formData)
const updateCampaign = ({ formData, slug }) => axios.patch(`${getWorkspaceId()}/campaigns/${slug}`, formData)
const updateCampaignsStatus = (formData) => axios.patch(`${getWorkspaceId()}/campaigns/status`, formData)
const deleteCampaigns = (data) => axios.delete(`${getWorkspaceId()}/campaigns`, { data })
const resetCampaignCaps = (slug) => axios.delete(`${getWorkspaceId()}/campaigns/${slug}/counters`)
const getAvailableIvr = (slug) => axios.get(`${getWorkspaceId()}/campaigns/${slug}/ivr`)

const getCampaignsFiltersAndSorting = () => axios.get(`${getWorkspaceId()}/campaigns/filters-and-sorting`)

export {
    getAllCampaigns,
    getCampaigns,
    getDetailCampaign,
    createCampaign,
    cloneCampaign,
    updateCampaign,
    updateCampaignsStatus,
    deleteCampaigns,
    getCampaignsFiltersAndSorting,
    getCountersCampaign,
    resetCampaignCaps,
    getAvailableIvr,
}
