import { ROUTES } from '@/constants/routes'

export const GLOBAL_SEARCH_TYPES = {
    vendors: {
        name: 'Vendor',
        style: '#D093E7',
        route: ROUTES.detailVendor,
    },
    buyers: {
        name: 'Buyer',
        style: '#D2635E',
        route: ROUTES.detailBuyer,
    },
    campaigns: {
        name: 'Campaign',
        style: '#E7916D',
        route: ROUTES.detailCampaigns,
    },
    destination_groups: {
        name: 'Group',
        style: '#6CA578',
        route: ROUTES.detailDestinationGroup,
    },
    phone_numbers: {
        name: 'Number',
        style: '#7F5AAF',
        route: ROUTES.detailNumber,
    },
    blocked_numbers: {
        name: 'Blocked',
        style: '#F06269',
        route: ROUTES.blockNumber,
    },
    destinations: {
        name: 'Destination',
        style: '#644FB0',
        route: ROUTES.detailDestination,
    },
    traffic_sources: {
        name: 'Traffic source',
        style: '#75AFCD',
        route: ROUTES.detailTrafficSource,
    },
    phone_number_pools: {
        name: 'Number pool',
        style: '#878B8F',
        route: ROUTES.detailPool,
        key: true,
    },
    number_in_pool: {
        name: 'Number in pool',
        style: '#878B8F',
        route: ROUTES.detailPool,
        key: true,
    },
    ivrs: {
        name: 'Call Flow',
        style: '#3392D7',
        route: ROUTES.detailCallFlows,
    },
    voip_black_lists: {
        name: 'VoIP Shield',
        style: '#009479',
        route: ROUTES.detailVoipShield,
    },
    leads: {
        name: 'Leads',
        style: '#D26A2D',
        route: ROUTES.manageLeads,
    },
}
