<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.76165 2.89392H3.43636C3.11419 2.89392 2.85303 3.15509 2.85303 3.47725V5.76984C2.85303 6.092 3.11419 6.35317 3.43636 6.35317H5.76165C6.08382 6.35317 6.34499 6.09201 6.34499 5.76984V3.47725C6.34499 3.15509 6.08382 2.89392 5.76165 2.89392Z" stroke="currentColor" stroke-width="1.08333"/>
        <path d="M11.163 2.89392H8.83773C8.51556 2.89392 8.25439 3.15509 8.25439 3.47725V5.76984C8.25439 6.092 8.51556 6.35317 8.83773 6.35317H11.163C11.4852 6.35317 11.7464 6.09201 11.7464 5.76984V3.47725C11.7464 3.15509 11.4852 2.89392 11.163 2.89392Z" stroke="currentColor" stroke-width="1.08333"/>
        <path d="M16.5637 2.89392H14.2384C13.9162 2.89392 13.655 3.15509 13.655 3.47725V5.76984C13.655 6.092 13.9162 6.35317 14.2384 6.35317H16.5637C16.8858 6.35317 17.147 6.09201 17.147 5.76984V3.47725C17.147 3.15509 16.8858 2.89392 16.5637 2.89392Z" stroke="currentColor" stroke-width="1.08333"/>
        <path d="M5.76165 8.27148H3.43636C3.11419 8.27148 2.85303 8.53265 2.85303 8.85482V11.1474C2.85303 11.4696 3.11419 11.7307 3.43636 11.7307H5.76165C6.08382 11.7307 6.34499 11.4696 6.34499 11.1474V8.85482C6.34499 8.53265 6.08382 8.27148 5.76165 8.27148Z" stroke="currentColor" stroke-width="1.08333"/>
        <path d="M11.163 8.27148H8.83773C8.51556 8.27148 8.25439 8.53265 8.25439 8.85482V11.1474C8.25439 11.4696 8.51556 11.7307 8.83773 11.7307H11.163C11.4852 11.7307 11.7464 11.4696 11.7464 11.1474V8.85482C11.7464 8.53265 11.4852 8.27148 11.163 8.27148Z" stroke="currentColor" stroke-width="1.08333"/>
        <path d="M16.5637 8.27148H14.2384C13.9162 8.27148 13.655 8.53265 13.655 8.85482V11.1474C13.655 11.4696 13.9162 11.7307 14.2384 11.7307H16.5637C16.8858 11.7307 17.147 11.4696 17.147 11.1474V8.85482C17.147 8.53265 16.8858 8.27148 16.5637 8.27148Z" stroke="currentColor" stroke-width="1.08333"/>
        <path d="M5.76165 13.6492H3.43636C3.11419 13.6492 2.85303 13.9103 2.85303 14.2325V16.5251C2.85303 16.8473 3.11419 17.1084 3.43636 17.1084H5.76165C6.08382 17.1084 6.34499 16.8473 6.34499 16.5251V14.2325C6.34499 13.9103 6.08382 13.6492 5.76165 13.6492Z" stroke="currentColor" stroke-width="1.08333"/>
        <path d="M11.163 13.6492H8.83773C8.51556 13.6492 8.25439 13.9103 8.25439 14.2325V16.5251C8.25439 16.8473 8.51556 17.1084 8.83773 17.1084H11.163C11.4852 17.1084 11.7464 16.8473 11.7464 16.5251V14.2325C11.7464 13.9103 11.4852 13.6492 11.163 13.6492Z" stroke="currentColor" stroke-width="1.08333"/>
        <path d="M16.5637 13.6492H14.2384C13.9162 13.6492 13.655 13.9103 13.655 14.2325V16.5251C13.655 16.8473 13.9162 17.1084 14.2384 17.1084H16.5637C16.8858 17.1084 17.147 16.8473 17.147 16.5251V14.2325C17.147 13.9103 16.8858 13.6492 16.5637 13.6492Z" stroke="currentColor" stroke-width="1.08333"/>
    </svg>
</template>
