import { DESTINATION_TYPE, ENDPOINT_TYPE, OPTIONS_TYPE } from '@/constants/call-tracking/destination/destinationOptions'

const destinationTypes = [
    { name: 'Default', id: DESTINATION_TYPE.default },
    { name: 'Ping', id: DESTINATION_TYPE.ping, beta: true },
]

const options = [
    { name: 'Static', id: OPTIONS_TYPE.static },
    { name: 'Dynamic', id: OPTIONS_TYPE.dynamic },
]

const sipOptions = [
    { name: 'Number', id: ENDPOINT_TYPE.number },
    { name: 'SIP', id: ENDPOINT_TYPE.sip },
]

const defaultDestinationType = DESTINATION_TYPE.default
const defaultOption = OPTIONS_TYPE.dynamic
const defaultSipOption = ENDPOINT_TYPE.number

function isDestinationTypeEqualDefault(id) {
    return id === DESTINATION_TYPE.default
}

function isDestinationTypeEqualPing(id) {
    return id === DESTINATION_TYPE.ping
}

function isOptionTypeEqualStatic(id) {
    return id === OPTIONS_TYPE.static
}

function isSipOptionEqualNumber(id) {
    return id === ENDPOINT_TYPE.number
}

export {
    destinationTypes, options, sipOptions, defaultDestinationType, defaultOption, defaultSipOption, isDestinationTypeEqualDefault, isDestinationTypeEqualPing, isOptionTypeEqualStatic, isSipOptionEqualNumber,
}
