const SMALL_ZOOM_SIZE = 0.25
const MEDIUM_ZOOM_SIZE = 0.5
const LARGE_ZOOM_SIZE = 0.75
const XLARGE_ZOOM_SIZE = 1

const ZOOM_SIZE = {
    [SMALL_ZOOM_SIZE]: 350,
    [MEDIUM_ZOOM_SIZE]: 200,
    [LARGE_ZOOM_SIZE]: 150,
    [XLARGE_ZOOM_SIZE]: 100,
}

export {
    ZOOM_SIZE,
    SMALL_ZOOM_SIZE,
    MEDIUM_ZOOM_SIZE,
    LARGE_ZOOM_SIZE,
    XLARGE_ZOOM_SIZE,
}
