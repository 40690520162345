import { defineStore } from 'pinia'

import { checkUserAccess, checkWorkspaceAccess, getLogout } from '@/api/auth-routes'

import { localSort } from '@/helpers/app-helper'
import { removeAccessToken } from '@/helpers/auth-helper'

import { useDefaultStore } from '@/store/defaultStore'
import { usePermissionsStore } from '@/store/permissionsStore'

import { APP_ROLES } from '@/constants/appRoles'

export const useAuthorizationStore = defineStore('authorizationStore', {
    state: () => ({
        account: null,
        user: null,
        carrotHash: null,
        isManager: false,
        role: '',
        workspace: null,
        workspaces: [],
        numbersPermissions: [],
        pinEnabled: false,
        verificationProgress: [],
        availablePaymentMethods: [],
    }),

    getters: {
        isOwner(state) {
            return !!state.account
        },

        isReporter(state) {
            return state.role === APP_ROLES.reporter
        },

        isVendor(state) {
            return state.role === APP_ROLES.vendor
        },

        isBuyer(state) {
            return state.role === APP_ROLES.buyer
        },

        isWorkspaceOwner({ workspace }) {
            return workspace && workspace.role === APP_ROLES.owner
        },

        isAccountTrial(state) {
            return state.account && state.account.trial
        },

        isAccountReview(state) {
            return state.account && state.account.in_review
        },

        getWorkspaceTimezoneId(state) {
            if (!state.workspace && !state.workspace?.timezone_id) {
                return 46
            }

            return state.workspace.timezone_id
        },

        getWorkspaceSlug(state) {
            if (!state.workspace || !state.workspace.hasOwnProperty('slug')) {
                return '_'
            }

            return state.workspace.slug
        },

        getAccountSlug(state) {
            if (!state.account || !state.account.hasOwnProperty('slug')) {
                return '_'
            }

            return state.account.slug
        },

        getUserId(state) {
            if (!state.user || !state.user.hasOwnProperty('id')) {
                return
            }

            return state.user.id
        },
    },

    actions: {
        saveUserData({
            account, user, workspaces, carrot = null,
        }) {
            this.saveAccount(account)
            this.setVerificationProgress(account)
            this.updateUser(user)
            this.saveCarrotHash(carrot)
            this.saveWorkspaces(workspaces)

            if (Array.isArray(workspaces) && workspaces.length) {
                const workspacesSort = localSort(workspaces, 'number', 'last_visit', true)
                this.saveWorkspace(workspacesSort[0])
            }
        },

        setVerificationProgress(account) {
            if (account) {
                this.verificationProgress = JSON.parse(account.verification_progress)
            }
        },

        updateVerificationProgress(status, step) {
            const findItem = this.verificationProgress.find((item) => item.step === step)

            if (findItem) {
                findItem.status = status
            }
        },

        updateProgress({ verification_progress }) {
            this.verificationProgress = JSON.parse(verification_progress)
        },

        saveAccount(account) {
            this.account = account
        },

        updateAccount(payload) {
            this.account = {
                ...this.account,
                ...payload,
            }
        },

        updateUser(user) {
            this.user = user
        },

        saveCarrotHash(hash) {
            this.carrotHash = hash
        },

        saveWorkspaces(workspaces) {
            this.workspaces = workspaces
        },

        updateWorkspace(workspace) {
            const { slug } = workspace

            const updateWorkspaces = this.workspaces.map((el) => {
                if (el.slug === slug) {
                    return workspace
                }
                return el
            })

            this.workspace = workspace
            this.workspaces = updateWorkspaces
        },

        addWorkspace(workspace) {
            this.workspaces.push(workspace)
        },

        removeWorkspace(slug) {
            this.workspaces = this.workspaces.filter((v) => v.slug !== slug)

            if (this.workspace.slug === slug) {
                this.workspace = this.workspaces[0]
            }
        },

        saveWorkspace(workspace) {
            this.workspace = workspace
        },

        updateWorkspaceTimezoneId(id) {
            this.workspace.timezone_id = id
        },

        saveRole(role) {
            this.role = role
        },

        saveIsManager(isManager) {
            this.isManager = isManager
        },

        saveWorkSpaceSettings(payload) {
            const permissionsStore = usePermissionsStore()

            this.saveIsManager(payload.is_manager)
            this.saveRole(payload.role)

            permissionsStore.setPermissions(payload)

            this.numbersPermissions = payload.hasOwnProperty('numbers_permissions') ? payload.numbers_permissions : []
            this.availablePaymentMethods = payload.hasOwnProperty('payment_methods') ? payload.payment_methods : []
            this.setPinEnabled(payload.hasOwnProperty('pin_enabled') ? payload.pin_enabled : false)
        },

        setPinEnabled(enabled) {
            this.pinEnabled = enabled
        },

        async getCheckWorkspace(newSlug) {
            if (!this.workspaces.length) {
                return
            }

            const foundWorkspace = this.workspaces.find((w) => w.slug === newSlug)

            const slug = foundWorkspace ? newSlug : this.workspace.slug

            if (!slug) {
                this.saveRole('Owner')

                return
            }

            const { success, payload, message } = await checkWorkspaceAccess(slug)

            if (success) {
                if (foundWorkspace) {
                    this.saveWorkspace(foundWorkspace)
                }

                this.saveWorkSpaceSettings(payload)
            } else {
                const defaultStore = useDefaultStore()
                defaultStore.setErrorMessage({ message })
                this.$reset()
            }
        },

        async getCheckUserAccess() {
            const { success, payload, message } = await checkUserAccess()

            if (success) {
                this.saveUserData(payload)
            } else {
                this.$reset()
                removeAccessToken()
                const defaultStore = useDefaultStore()
                defaultStore.setErrorMessage({ message })
            }
        },

        async logout() {
            const defaultStore = useDefaultStore()

            const { success, message } = await getLogout()

            if (success) {
                defaultStore.setSuccessMessage({ message })
                this.$reset()
                removeAccessToken()
            } else {
                defaultStore.setErrorMessage({ message })
            }
        },
    },
})
