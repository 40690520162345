import axios from '@/plugins/axios'

import { useAuthorizationStore } from '@/store/authorizationStore'

const getWorkspaceId = () => useAuthorizationStore().getWorkspaceSlug

const getDestinationGroups = (params) => axios.get(`${getWorkspaceId()}/destination-groups/paginated`, { params })
const getAllGroupDestinations = () => axios.get(`${getWorkspaceId()}/destination-groups/all`)
const getDetailDestinationGroup = (slug) => axios.get(`${getWorkspaceId()}/destination-groups/${slug}`)
const getAvailableDestinations = () => axios.get(`${getWorkspaceId()}/destination-groups`)
const createDestinationGroup = (formData) => axios.post(`${getWorkspaceId()}/destination-groups`, formData)
const updateDestinationGroupsStatus = (formData) => axios.patch(`${getWorkspaceId()}/destination-groups/status`, formData)
const updateDestinationGroup = ({ formData, slug }) => axios.patch(`${getWorkspaceId()}/destination-groups/${slug}`, formData)
const deleteDestinationGroup = (data) => axios.delete(`${getWorkspaceId()}/destination-groups`, { data })
const attachDestinationInGroup = ({ formData, slug }) => axios.post(`${getWorkspaceId()}/destination-groups/${slug}/destinations`, formData)
const detachDestinationGroup = ({ slug, data }) => axios.delete(`${getWorkspaceId()}/destination-groups/${slug}/destinations`, { data })
const updateWeightDestination = ({ formData, slug, destination }) => axios.patch(`${getWorkspaceId()}/destination-groups/${slug}/destinations/${destination}`, formData)
const counterDestinationGroup = (formData) => axios.post(`${getWorkspaceId()}/destination-groups/counters`, formData)
const resetCapsDestinationGroup = (slug) => axios.delete(`${getWorkspaceId()}/destination-groups/counters/${slug}`)

const getDestinationGroupFiltersAndSorting = () => axios.get(`${getWorkspaceId()}/destination-groups/filters-and-sorting`)

export {
    getAvailableDestinations,
    getAllGroupDestinations,
    getDestinationGroups,
    getDetailDestinationGroup,
    createDestinationGroup,
    updateDestinationGroupsStatus,
    updateDestinationGroup,
    deleteDestinationGroup,
    attachDestinationInGroup,
    detachDestinationGroup,
    counterDestinationGroup,
    resetCapsDestinationGroup,
    getDestinationGroupFiltersAndSorting,
    updateWeightDestination,
}
