export const ROUTING_OPTIONS = {
    standard: {
        id: 1,
        name: 'Standard',
    },
    menu: {
        id: 2,
        name: 'Menu',
        tippyMessage: 'Menu option is unavailable when RTB is enabled',
    },
    ivr: {
        id: 3,
        name: 'Call Flow',
        beta: true,
        tippyMessage: 'Call Flow option is unavailable when RTB is enabled',
    },
    revenue: {
        id: 4,
        name: 'Revenue',
    },
}
