import { getDeviceName } from '@/helpers/device-helper'
import { getCurrentTheme } from '@/helpers/theme-local-storage-helper'

import { useAuthorizationStore } from '@/store/authorizationStore'

function addFeaturebaseScript() {
    const script = document.createElement('script')
    script.src = 'https://do.featurebase.app/js/sdk.js'
    script.id = 'featurebase-sdk'
    document.head.appendChild(script)
}

function initFeaturebase() {
    const win = window
    const authorizationStore = useAuthorizationStore()

    const isDark = getCurrentTheme() === 'dark'
    const { email } = authorizationStore.user

    if (typeof win.Featurebase !== 'function') {
        win.Featurebase = function () {
            (win.Featurebase.q = win.Featurebase.q || []).push(arguments)
        }
    }

    win.Featurebase('initialize_feedback_widget', {
        organization: 'Dialics',
        locale: 'en',
        metadata: null,
        theme: isDark ? 'dark' : 'light',
        email,
    })
}

function initFeaturebaseSurvey() {
    const win = window
    const authorizationStore = useAuthorizationStore()

    const isDark = getCurrentTheme() === 'dark'
    const { email, name, id } = authorizationStore.user

    if (typeof win.Featurebase !== 'function') {
        win.Featurebase = function () {
            (win.Featurebase.q = win.Featurebase.q || []).push(arguments)
        }
    }

    win.Featurebase('initialize_survey_widget', {
        organization: 'Dialics',
        theme: isDark ? 'dark' : 'light',
        placement: 'bottom-right',
        name,
        email,
        id,
        locale: 'en',
        metadata: {
            device_type: getDeviceName(),
        },
    })
}

function initFeaturebaseChangelog() {
    const win = window
    const authorizationStore = useAuthorizationStore()

    const isDark = getCurrentTheme() === 'dark'
    const { name } = authorizationStore.user

    if (typeof win.Featurebase !== 'function') {
        win.Featurebase = function () {
            (win.Featurebase.q = win.Featurebase.q || []).push(arguments)
        }
    }

    win.Featurebase('initialize_changelog_widget', {
        organization: 'Dialics',
        theme: isDark ? 'dark' : 'light',
        name,
        locale: 'en',
        fullscreenPopup: true,
    })
}

function openFeedbackWidget() {
    window.postMessage({
        target: 'FeaturebaseWidget',
        data: {
            action: 'openFeedbackWidget',
        },
    })
}

export {
    addFeaturebaseScript,
    initFeaturebase,
    initFeaturebaseSurvey,
    initFeaturebaseChangelog,
    openFeedbackWidget,
}
